import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeader } from 'components/molecules';

import { rebalancingAnalytics, rebalancingAnalyticsOptions } from 'appConstants';
import { Carousel } from 'components/atoms';
import { nbColumn, tableWithCarouselActive } from 'appConstants/carousel';

type Props = {
  metricsRows: RebalancingTableRow[];
  selected: boolean;
  onSelectRebalancing: (checkboxClass: string, value: string) => void;
};

const RebalancingTable = ({ metricsRows, selected, onSelectRebalancing }: Props) => {
  const { t } = useTranslation();

  const listHeaders = rebalancingAnalytics.slice(1);

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [isLeftCarouselDisable, setIsLeftCarouselDisable] = useState(true);
  const [isRightCarouselDisable, setIsRightCarouselDisable] = useState(false);
  const [isCarouselActive, setIsCarouselActive] = useState(true);
  const [tableWidth, setTableWidth] = useState(0);

  const indexMaxCarousel = listHeaders.length - nbColumn;

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setTableWidth((tableRef?.current as HTMLTableElement)?.clientWidth);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.clientWidth);
      setIsCarouselActive(tableWidth < tableWithCarouselActive);
    }
  }, [tableWidth, isCarouselActive]);

  useEffect(() => {
    if (carouselIndex <= 0) {
      setCarouselIndex(0);
    }
    setIsLeftCarouselDisable(!carouselIndex);

    if (carouselIndex > indexMaxCarousel) {
      setCarouselIndex(indexMaxCarousel);
    }
    setIsRightCarouselDisable(carouselIndex === indexMaxCarousel);
  }, [carouselIndex, indexMaxCarousel, listHeaders.length]);

  const renderCell = (row: RebalancingTableRow, header: KeyRebalancingTable, i: number) => {
    let valueToShow: string | number = '-';
    if (header === 'rebalancing') {
      valueToShow = row.rebalancing
        ? t('rebalancing.decision.with')
        : t('rebalancing.decision.without');
    } else if (row[header] !== null) {
      const unit = rebalancingAnalyticsOptions.find((el) => el.key === header)?.unit;
      valueToShow = `${row[header] ?? '-'} ${row[header] && unit ? unit : ''}`;
    }
    return (
      <td
        title={valueToShow.toString()}
        key={`${i}`}
        className={`list-cell column small-cell is-text-aligned ${
          header === 'rebalancing' ? 'rebalancing-cell' : ''
        }`}
      >
        {header === 'rebalancing' && (
          <input
            onChange={(e) => onSelectRebalancing(e.target.className, e.target.value)}
            type="radio"
            checked={(row[header] && !!selected) || (!row[header] && !selected)}
            name="selectRebalancing"
            className={`rebalancing-checkbox-${row[header] ? 'on' : 'off'}`}
          ></input>
        )}
        <div className={`${header === 'rebalancing' ? 'rebalancing-value' : ''}`}>
          {valueToShow}
        </div>
      </td>
    );
  };

  const slicedListHeaders = () => {
    return !isCarouselActive
      ? listHeaders
      : listHeaders.slice(carouselIndex, carouselIndex + nbColumn);
  };

  return (
    <table className="table-container rebalancing-table" ref={tableRef}>
      <thead className="table-header">
        <tr className="list-headers">
          <TableHeader
            useCase={'rebalancing'}
            key={'rebalancing'}
            name={'rebalancing'}
            classesContainer={`column small-cell p-0`}
            classeTh="justify-content"
          ></TableHeader>

          {isCarouselActive && (
            <th className="carousel-th carousel-th-width">
              <Carousel
                isDisabled={isLeftCarouselDisable}
                specialClass={'array-carousel'}
                onClick={() => setCarouselIndex((prev) => prev - 1)}
              ></Carousel>
            </th>
          )}

          {slicedListHeaders().map((value) => {
            return (
              <TableHeader
                useCase={'rebalancing'}
                key={value}
                name={value}
                classesContainer={`column small-cell p-0`}
                classeTh="justify-content"
              ></TableHeader>
            );
          })}
          {isCarouselActive && (
            <th className="carousel-th carousel-th-width">
              <Carousel
                isDirectionLeft={false}
                isDisabled={isRightCarouselDisable}
                specialClass={'array-carousel'}
                onClick={() => setCarouselIndex((prev) => prev + 1)}
              ></Carousel>
            </th>
          )}
        </tr>
      </thead>

      <tbody className="list-rows">
        {metricsRows?.length > 0 &&
          metricsRows.map((row: RebalancingTableRow, index) => (
            <tr key={`smartmeter-${index}`} className="list-row columns">
              {renderCell(row, 'rebalancing', 0)}

              {isCarouselActive && <td className={'carousel-th-width'}> </td>}

              {slicedListHeaders().map((header, i) => renderCell(row, header, i + 1))}
              {isCarouselActive && <td className={'carousel-th-width'}> </td>}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default RebalancingTable;
