import { ModeBarButton } from 'plotly.js';

/* Plotly accepts only svg paths as custom icons */
const iconsPaths = {
  close:
    // eslint-disable-next-line max-len
    'M14.364 3.05024L12.9497 1.63603L8 6.58577L3.05025 1.63603L1.63604 3.05024L6.58579 7.99999L1.63604 12.9497L3.05025 14.3639L8 9.4142L12.9497 14.3639L14.364 12.9497L9.41421 7.99999L14.364 3.05024Z',
  expand:
    // eslint-disable-next-line max-len
    'M20 10h-2V3.413l-5.828 5.83-1.415-1.415L16.585 2H10V0h10v10zM0 20V10h2v6.585l5.828-5.828 1.415 1.415L3.413 18H10v2H0z',
};

export const toggleGraphModalButton = function (
  buttonTitle: string,
  isModalOpen: boolean,
  setModalState: (state: boolean) => void
): ModeBarButton {
  return {
    title: buttonTitle,
    name: 'toggle-full-screen',
    icon: {
      width: 20,
      height: 20,
      path: isModalOpen ? iconsPaths.close : iconsPaths.expand,
    },
    click: () => setModalState(!isModalOpen),
  };
};
