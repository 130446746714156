export const maxSecSubLoadRateOption: SecondarySubAnalyticsOption = {
  translationKey: 'sort_options.max_sec_sub_load_rate',
  key: 'maxSecSubLoadRate',
  unit: '%',
  cssClasses: 'green-red-color-scale5',
  values: [0, 20, 40, 60, 80],
};

// See if we get the colorscale to stop at 100 and not +
export const timeRateSecSubUnderloadOption: SecondarySubAnalyticsOption = {
  values: [0, 20, 40, 60, 80],
  cssClasses: 'white-blue-color-scale5',
  translationKey: 'sort_options.time_rate_sec_sub_underload',
  key: 'timeRateSecSubUnderload',
  unit: '%',
};

export const nbSmVOutOfRangeOption: SecondarySubAnalyticsOption = {
  values: [0, 1, 5, 20],
  cssClasses: 'green-red-color-scale4',
  translationKey: 'sort_options.nb_sm_v_out_of_range',
  key: 'nbSmVOutOfRange',
};

export const nbVOutOfRangeOption: SecondarySubAnalyticsOption = {
  translationKey: 'sort_options.nb_v_out_of_range',
  key: 'nbVOutOfRange',
  cssClasses: 'green-red-color-scale4',
  values: [0, 1, 10, 30],
};

export const nbSmVImbalancedOption: SecondarySubAnalyticsOption = {
  values: [0, 1, 5, 20],
  cssClasses: 'green-red-color-scale4',
  translationKey: 'sort_options.nb_sm_v_imbalanced',
  key: 'nbSmVImbalanced',
};

export const maxSecSubLoadImbalanceOption: SecondarySubAnalyticsOption = {
  translationKey: 'sort_options.max_sec_sub_load_imbalance',
  key: 'maxSecSubLoadImbalance',
  cssClasses: 'green-red-color-scale3',
  values: [0, 5, 20],
  unit: '%',
};

/* If option value starts with -1 there is an extra color specific to 0 */
export const timeRateSecSubOverloadOption: SecondarySubAnalyticsOption = {
  values: [-1, 0, 30],
  cssClasses: 'green-red-color-scale3',
  translationKey: 'sort_options.time_rate_sec_sub_overload',
  key: 'timeRateSecSubOverload',
  unit: '%',
};

export const totalTimeFeedersOvercurrentOption: SecondarySubAnalyticsOption = {
  values: [0, 2, 48],
  cssClasses: 'green-red-color-scale3',
  unit: 'h',
  translationKey: 'sort_options.total_time_feeders_overcurrent',
  key: 'totalTimeFeedersOvercurrent',
};

export const maxTimeFeederOvercurrentOption: SecondarySubAnalyticsOption = {
  translationKey: 'sort_options.max_time_feeder_overcurrent',
  key: 'maxTimeFeederOvercurrent',
  cssClasses: 'green-red-color-scale4',
  unit: 'h',
  values: [-1, 0, 2, 48],
};

export const totalTimeSmVOutOfRangeOption: SecondarySubAnalyticsOption = {
  values: [-1, 0, 10, 100],
  cssClasses: 'green-red-color-scale4',
  translationKey: 'sort_options.total_time_sm_v_out_of_range',
  key: 'totalTimeSmVOutOfRange',
  unit: 'h',
};

export const meanSmVImbalanceOption: SecondarySubAnalyticsOption = {
  values: [2, 2.5, 3],
  cssClasses: 'red-color-scale3',
  unit: '%',
  translationKey: 'sort_options.mean_sm_v_imbalance',
  key: 'meanSmVImbalance',
};

export const minCapacityOption: SecondarySubAnalyticsOption = {
  values: [0, 1, 6],
  cssClasses: 'red-green-color-scale3',
  unit: 'kW',
  translationKey: 'sort_options.min_capacity',
  key: 'minCapacity',
};

export const overallInstalledCapacityOption: SecondarySubAnalyticsOption = {
  values: [0, 10, 100],
  cssClasses: 'green-red-color-scale3',
  unit: 'kW',
  translationKey: 'sort_options.overall_installed_capacity',
  key: 'overallInstalledCapacity',
};

export const overFixedMinCapacityNbOption: SecondarySubAnalyticsOption = {
  values: [0, 1, 3],
  cssClasses: 'red-green-color-scale3',
  translationKey: 'sort_options.over_fixed_min_capacity_nb',
  key: 'overFixedMinCapacityNb',
};

export const overallAvailableCapacityOption: SecondarySubAnalyticsOption = {
  values: [0, 6, 60],
  cssClasses: 'blue-color-scale3',
  unit: 'kW',
  translationKey: 'sort_options.overall_available_capacity',
  key: 'overallAvailableCapacity',
};

export const overallPlannedCapacityOption: SecondarySubAnalyticsOption = {
  values: [0, 10, 100],
  cssClasses: 'blue-color-scale3',
  unit: 'kW',
  translationKey: 'sort_options.overall_planned_capacity',
  key: 'overallPlannedCapacity',
};

export const installedCapacityToSubscribedPowerOption: SecondarySubAnalyticsOption = {
  values: [0, 10, 30],
  cssClasses: 'blue-color-scale3',
  unit: '%',
  translationKey: 'sort_options.installed_capacity_to_subscribed_power',
  key: 'installedCapacityToSubscribedPower',
};

export const imbalanceRateOption: SecondarySubAnalyticsOption = {
  values: [],
  cssClasses: '',
  unit: '%',
  translationKey: 'sort_options.imbalance_rate',
  key: 'imbalanceRate',
};

export const networkAnalyticsOptions: SecondarySubAnalyticsOption[] = [
  nbSmVImbalancedOption,
  nbSmVOutOfRangeOption,
  timeRateSecSubOverloadOption,
  timeRateSecSubUnderloadOption,
  totalTimeSmVOutOfRangeOption,
  maxTimeFeederOvercurrentOption,
  maxSecSubLoadImbalanceOption,
  maxSecSubLoadRateOption,
  totalTimeFeedersOvercurrentOption,
  minCapacityOption,
  overFixedMinCapacityNbOption,
  overallAvailableCapacityOption,
  overallPlannedCapacityOption,
  /* Hide some metrics for V1 - these metrics will come back in a future version
  installedCapacityToSubscribedPowerOption,
  overallInstalledCapacityOption,
  meanSmVImbalanceOption,
  nbVOutOfRangeOption,
  */
];

export const rebalancingAnalyticsOptions: SecondarySubAnalyticsOption[] = [
  imbalanceRateOption,
  maxSecSubLoadRateOption,
  maxTimeFeederOvercurrentOption,
  minCapacityOption,
  nbSmVImbalancedOption,
  nbSmVOutOfRangeOption,
  overFixedMinCapacityNbOption,
  totalTimeFeedersOvercurrentOption,
  totalTimeSmVOutOfRangeOption,
];

const voltageMetrics: string[] = ['v1', 'v2', 'v3'];
const loadMetrics: string[] = ['p1', 'p2', 'p3', 'p4'];

export const graphAnalytics = {
  voltageDurationCurve: { regex: 'v([0-3])', metrics: voltageMetrics },
  voltageDistributionBoxplot: { regex: 'v([0-3])', metrics: voltageMetrics },
  imbalanceCurrentRadar: { regex: 'i', metrics: [''] },
  loadDistributionBoxplot: { regex: 'p([0-4])', metrics: loadMetrics },
  loadDurationCurve: { regex: 'p([0-4])', metrics: loadMetrics },
};

export const networkAnalytics: (keyof SecondarySubstationAnalytics)[] = [
  'nbSmVOutOfRange',
  'nbSmVImbalanced',
  'meanSmVImbalance',
  'totalTimeSmVOutOfRange',
  'totalTimeFeedersOvercurrent',
  'timeRateSecSubOverload',
  'timeRateSecSubUnderload',
  'nbVOutOfRange',
  'maxTimeFeederOvercurrent',
  'maxSecSubLoadImbalance',
  'maxSecSubLoadRate',
  'overFixedMinCapacityNb',
  'minCapacity',
  'overallAvailableCapacity',
  'installedCapacityToSubscribedPower',
  'overFixedMinCapacityRate',
  'overallPlannedCapacity',
];

export const impactMetrics: (keyof SecSubPvMetricsApi)[] = [
  'overFixedMinCapacityNb',
  'minCapacity',
  'overallAvailableCapacity',
  'installedCapacityToSubscribedPower',
  'overFixedMinCapacityRate',
  'overallPlannedCapacity',
];

export const percentAnalytics = networkAnalyticsOptions
  .filter((option) => option.unit === '%')
  .map((option) => option.key);

export const hourAnalytics = networkAnalyticsOptions
  .filter((option) => option.unit === 'h')
  .map((option) => option.key);

export const rebalancingAnalytics: KeyRebalancingTable[] = [
  'rebalancing',
  'imbalanceRate',
  'maxSecSubLoadRate',
  'maxTimeFeederOvercurrent',
  'minCapacity',
  'nbSmVImbalanced',
  'nbSmVOutOfRange',
  'overFixedMinCapacityNb',
  'totalTimeFeedersOvercurrent',
  'totalTimeSmVOutOfRange',
];
