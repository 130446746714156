import variables from 'assets/styles/partials/exports/_variables.module.scss';

export const pxMinWidthTab = 245;
/*zoom 100%: 3 tabs on mdpi,  4 on 23"FHD and 2 on splitScreen 23"FHD*/
export const pxIconWidth = 24;

export const carouselButtonsWidth =
  parseFloat(getComputedStyle(document.documentElement).fontSize) *
  2 *
  parseFloat(variables.remCarouselButtonsWidth);

export const marginWidth =
  parseFloat(getComputedStyle(document.documentElement).fontSize) *
  parseFloat(variables.remPrimaryTabsLeftMargin) *
  2;

export const tabsPaddingIconAndCloseWidth =
  (parseFloat(getComputedStyle(document.documentElement).fontSize) *
    parseFloat(variables.remPrimaryTabsPadding) +
    pxIconWidth) *
  2;

export const nbColumn = 4;

/* carousel is not active at 80% on laptop but active in split screen on fHD monitor*/
export const tableWithCarouselActive = 850;
