import * as actionTypes from 'store/actions/dataContext';

export const dataContextInitialState: DataContext = {
  datasetId: '',
  networkAnalysisId: [],
  dsoId: '',
  integratorId: '',
  processingTopoId: [],
  useCasePermissions: [],
  deviceDetectionModelIds: [],
  pvLoadCapacityModelIds: [],
  rebalancingIds: [],
  pvPlannedLoadIds: [],
};

export const dataContextReducer = (
  state: DataContext = dataContextInitialState,
  action: DataContextAction
): DataContext => {
  switch (action.type) {
    case actionTypes.SET_DATA_CONTEXT:
      state = action.dataContext;
      return state;
    default:
      return state;
  }
};
