import React, { useRef, useState, useEffect, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconSprite } from 'assets/icons/icons.svg';
import { Icon } from 'components/atoms';
import { InputText, InputPassword } from 'components/molecules';
import { LeafletMap } from 'components/organisms';

import './design-system.scss';

const DesignSystem = () => {
  const { t } = useTranslation();
  const iconSprites = useRef(null);
  const [iconsList, setIconsList] = useState([]);

  useEffect(() => {
    iconSprites && getIcons();
  }, [iconSprites]);

  const getIcons = () => {
    if (iconSprites.current) {
      let list: SetStateAction<never[]> = Array.from(iconSprites.current['children']);
      /* Remove non-icon tags */
      list = list?.filter((c: Element) => c.tagName === 'svg');

      setIconsList(list);
    }
  };

  const getMarkerIconName = (iconName: string) => {
    return iconName.replace('icon-', '');
  };

  const inputChange = () => {
    /* Mock function for inputChange */
  };

  return (
    <div className="container design-system-container pt-4">
      <h1 className="label mb-4">Design system</h1>

      <div className="columns is-multiline has-background-body py-4 px-6">
        <h2 className="title column is-full mb-5">Atoms</h2>
        <div className="column is-one-third">
          <h3 className="title mb-4">Icons</h3>

          <div className="is-hidden">
            <IconSprite ref={iconSprites} />
          </div>

          <div className="columns is-multiline mt-4">
            {iconsList &&
              iconsList.map((icon: { id: string }, i: number) => (
                <div key={`${icon}${i}`} className="icon-card box column is-one-quarter m-2">
                  <Icon name={getMarkerIconName(icon.id)} />
                  <span className="is-block">{getMarkerIconName(icon.id)}</span>
                </div>
              ))}
          </div>
        </div>

        <div className="column is-one-third">
          <h3 className="title mb-4">Buttons</h3>

          <div>
            <button className="button is-primary is-rounded mr-2" type="button">
              Primary
            </button>
            <button className="button is-primary is-rounded mr-2 mb-2" type="button" disabled>
              Primary
            </button>
            <br />
            <button className="button is-link is-rounded mr-2" type="button">
              Link
            </button>
            <button className="button is-link is-rounded" type="button" disabled>
              Link
            </button>
          </div>

          <h3 className="title mt-5 mb-4">Text</h3>
          <h4 className="title has-text-primary is-size-4">Title</h4>
          <h4 className="subtitle">Subtitle</h4>
        </div>

        <div className="column is-one-third">
          <InputText
            name={t('login.username_input_name')}
            placeholder={t('login.username_placeholder')}
            classes={'login-input mb-2'}
            onChange={inputChange}
          />

          <InputPassword
            name={t('login.password_input_name')}
            placeholder={t('login.password_placeholder')}
            classes={'is-medium login-input'}
            onChange={inputChange}
          />
        </div>
      </div>

      <div className="columns is-multiline has-background-body py-4 px-6">
        <h2 className="title column is-full mb-5">Molecules</h2>

        <div className="map column is-4">
          <LeafletMap center={[45.18, 5.72]} />
        </div>
      </div>
    </div>
  );
};

export default DesignSystem;
