import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

/* Import mannually translation files */
import en_dic from './locales/en.json';
import fr_dic from './locales/fr.json';

export const resources = {
  en: { translation: en_dic },
  fr: { translation: fr_dic },
} as const;

i18n
  /* i18next-browser-languagedetector detects the language preferences from the user's browser */
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'fr',
    debug: false,
    detection: {
      order: ['localStorage', 'navigator', 'path', 'cookie', 'subdomain', 'queryString', 'htmlTag'],
      lookupFromPathIndex: 0,
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
