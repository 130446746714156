import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorLegend, TableHeader } from 'components/molecules';
import { CapacityCell } from 'components/organisms/ImpactTable/cells';
import { Icon } from 'components/atoms';

import { roundOneDigit, sortByKey } from 'helpers';
import { impactTableHeaders } from 'appConstants';

import './impact-table.scss';
import { floor, isString } from 'lodash';

type Props = {
  smartMeters: SmartMeter[];
  capacities: CapacitiesBySmartMeter;
  normalizedCapacities: NormalizedCapacitiesBySmartMeter;
  tableMaxLoad: number;
  addedValues: ImpactAddedCapacitiesMap;
  onAdditionCellClick: (row: ImpactTableRow) => void;
  selectedSmartmeterId: string;
  previousCapacities: CapacitiesBySmartMeter;
  setHoveredSmartmeterById: (smId: string) => void;
  hoveredSmartmeter?: string;
};

const ImpactTable = ({
  smartMeters,
  capacities,
  normalizedCapacities,
  addedValues,
  onAdditionCellClick,
  selectedSmartmeterId,
  previousCapacities,
  hoveredSmartmeter,
  setHoveredSmartmeterById,
}: Props) => {
  const { t } = useTranslation();
  const [sortingKey, setSortingKey] = useState<{ header: string; icon: string } | null>(null);
  const [tableRows, setTableRows] = useState<ImpactTableRow[]>([]);

  const [currentTableRows, setCurrentTableRows] = useState<ImpactTableRow[]>([]);

  const sortableHeaders = [
    'smartmeter',
    'feeder',
    'phase1',
    'addedLoad',
    'installedLoad',
    'plannedLoad',
    'residualCapacity',
    'delta',
  ];

  const headers: TableRowKey[] = impactTableHeaders;

  const getColumnSize = (i: number): string => {
    let size = 'small-cell';
    if (i === 0 || i === 1) size = 'medium-cell';
    if (i === 8) size = 'big-cell';
    return size;
  };

  const sortTableBy = (header: TableRowKey) => {
    const { sortedCollection, icon } = sortByKey<ImpactTableRow>(
      header,
      currentTableRows,
      tableRows
    );
    setSortingKey({ header, icon });
    setCurrentTableRows(sortedCollection);
  };

  const filterRows = useCallback(
    (header: TableRowKey, value: string | null) => {
      const updatedTableRows = value
        ? currentTableRows.filter((row) => {
            return row[header]?.toString().toLocaleLowerCase().includes(value?.toLocaleLowerCase());
          })
        : tableRows;

      setCurrentTableRows(updatedTableRows);
    },
    [currentTableRows, tableRows]
  );

  const buildTableRows = useMemo(() => {
    const rows: ImpactTableRow[] = Array.from(smartMeters, (sm: SmartMeter) => {
      const smCapacities = capacities.get(sm.identifier);
      let tableRow: ImpactTableRow = {} as ImpactTableRow;
      const newPhase = addedValues.get(sm.identifier)?.phase ?? sm.phase1;
      tableRow = {
        ...tableRow,
        smartmeter: sm.name,
        phase1:
          newPhase === '4' ? t('impact.table.three_phased') : t('impact.table.phase') + newPhase,
        feeder: sm.feeder,
        identifier: sm.identifier,
      };

      if (smCapacities?.maxRes) {
        const addedLoad = addedValues.get(sm.identifier)?.newValue ?? smCapacities.added;
        const residualCapacity = floor(
          smCapacities[
            `resPhase${
              addedValues.get(sm.identifier)?.phase ??
              smCapacities.plannedPhase ??
              smCapacities.phase
            }`
          ],
          1
        );
        const smPreviousCapacities = previousCapacities?.get(sm.identifier);
        const newPhase: Phase = addedValues.get(sm.identifier)?.phase ?? smCapacities.phase;
        tableRow = {
          ...tableRow,
          phase1:
            newPhase === '4' ? t('impact.table.three_phased') : t('impact.table.phase') + newPhase,
          delta: roundOneDigit(
            smPreviousCapacities
              ? residualCapacity -
                  smPreviousCapacities[
                    `resPhase${
                      addedValues.get(sm.identifier)?.phase ??
                      smCapacities.plannedPhase ??
                      smCapacities.phase
                    }`
                  ]
              : 0
          ),
          installedLoad: smCapacities.installed,
          plannedLoad: smCapacities.planned,
          addedLoad,
          plannedLoadPhase: smCapacities.plannedPhase
            ? smCapacities.plannedPhase === '4'
              ? t('impact.table.three_phased')
              : t('impact.table.phase') + smCapacities.plannedPhase
            : undefined,
          residualCapacity,
        };
      }
      return tableRow;
    });
    setTableRows(rows);
    setCurrentTableRows(rows);
  }, [addedValues, capacities, previousCapacities, smartMeters, t]);

  const onAdditionModalOpen = (row: ImpactTableRow) => {
    onAdditionCellClick(row);
    setHoveredSmartmeterById(row.identifier);
  };

  useEffect(() => {
    setSortingKey(null);
    smartMeters?.length && buildTableRows;
  }, [buildTableRows, smartMeters]);

  const renderCell = (row: ImpactTableRow, header: TableRowKey, i: number) => {
    // TODO : add data
    switch (header) {
      case 'capacitiesBar':
        return (
          <CapacityCell
            key={`cell-${row.identifier}${i}`}
            classes={getColumnSize(i)}
            capacities={normalizedCapacities.get(row.identifier)}
            stripped={true}
            cursor={true}
          />
        );
      case 'phase1':
        return (
          <td
            key={`cell-${row.identifier}${i}`}
            title={row.plannedLoadPhase ?? row[header]}
            className={`list-cell column ${getColumnSize(i)} ${'is-text-aligned'} ${
              addedValues.get(row.identifier) &&
              addedValues.get(row.identifier)?.phase !==
                previousCapacities.get(row.identifier)?.phase
                ? 'is-row-modified'
                : ''
            }`}
          >
            <div className="truncate with-icon">{row.plannedLoadPhase ?? row.phase1}</div>
            {((addedValues.get(row.identifier) &&
              addedValues.get(row.identifier)?.phase !== capacities.get(row.identifier)?.phase) ||
              (!!row.plannedLoadPhase && row.plannedLoadPhase !== row.phase1)) && (
              <div onClick={() => null} className={'with-icon warning'}>
                <Icon name="warning" classes="is-flex-shrink-0 warning" />
              </div>
            )}
          </td>
        );
      case 'delta':
        return (
          <td
            {...(isString(row[header]) && {
              title: row[header]?.toString(),
            })}
            key={`cell-${row.identifier}${i}`}
            className={`list-cell column ${getColumnSize(i)} ${
              !['smartmeter', 'feeder'].includes(header) ? 'is-text-aligned' : ''
            }  `}
          >
            {row[header] ? (
              <span className="truncate italic">
                <i>
                  {row[header] > 0 ? '+' : ''}
                  {row[header]}
                </i>
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        );

      default:
        return (
          <td
            {...(isString(row[header]) && {
              title: row[header]?.toString(),
            })}
            key={`cell-${row.identifier}${i}`}
            className={`list-cell column ${getColumnSize(i)} ${
              !['smartmeter', 'feeder'].includes(header) ? 'is-text-aligned' : ''
            } ${
              header === 'addedLoad' && addedValues.get(row.identifier) ? 'is-row-modified' : ''
            } `}
          >
            {row[header] ? <span className="truncate">{row[header]}</span> : <span>-</span>}
          </td>
        );
    }
  };

  return (
    <>
      <table className="table-container impact-table">
        <thead className="table-header">
          <tr className="list-headers">
            {headers.map((header, i) => (
              <TableHeader
                key={`column header-${i}-${header}`}
                name={header}
                useCase={'impact'}
                sortProps={{
                  isSortable: sortableHeaders.includes(header),
                  onSortBy: () => sortTableBy(header),
                  sortingKey,
                }}
                filterType={['smartmeter', 'feeder'].includes(header) ? 'input' : 'empty'}
                classesContainer={`${getColumnSize(i)} p-0`}
                onUpdateFilter={(v) => filterRows(header, v ?? null)}
                info={
                  header === 'capacitiesBar' ? (
                    <ColorLegend
                      prefixTranslationKey="impact.color_legend.pvs."
                      colorVariables={[
                        'installed',
                        'planned',
                        'added',
                        'residual',
                        'stripped_residualMax',
                        'line_cursor',
                      ]}
                    />
                  ) : undefined
                }
              />
            ))}
          </tr>
        </thead>
        <tbody className="list-rows">
          {currentTableRows?.length > 0 &&
            currentTableRows.map((row: ImpactTableRow) => (
              <tr
                key={`smartmeter-${row.identifier}`}
                onClick={() => onAdditionModalOpen(row)}
                className={`list-row columns ${
                  row.identifier === selectedSmartmeterId ? 'is-selected' : ''
                }
                ${hoveredSmartmeter === row.identifier && 'smartmeter-hovered'} 
                `}
                onMouseLeave={() =>
                  row.identifier !== selectedSmartmeterId && setHoveredSmartmeterById('')
                }
                onMouseEnter={() => setHoveredSmartmeterById(row.identifier)}
              >
                {headers.map((header, i) => renderCell(row, header, i))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ImpactTable;
