import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { clone } from 'lodash';
import { useTranslation } from 'react-i18next';

import { filterListByCheckboxValue, sortByKey } from 'helpers';
import { CheckboxStates } from 'appConstants';
import { Icon } from 'components/atoms';
import { TableHeader } from 'components/molecules';

import './equipment-list.scss';

type Equipment = SecondarySubstation | SmartMeter;
type EquipmentKey = keyof Equipment;

type Props = {
  equipments: Equipment[];
  activeEquipmentId?: string;
  selectedIdentifiers: string[];
  additionalColumn?: {
    text: string;
    key: keyof SecondarySubstation;
  };
  isCheckedChange?: (equipment: Equipment, isChecked: boolean) => void;
};

const EquipmentList = ({
  equipments,
  additionalColumn,
  isCheckedChange,
  selectedIdentifiers = [],
  activeEquipmentId,
}: Props) => {
  const { t } = useTranslation();
  const [equipmentFilters, setEquipmentFilters] = useState<
    Map<string, CheckboxStates | string | null>
  >(
    new Map([
      ['location', CheckboxStates.Indeterminate],
      ['checkbox', CheckboxStates.Indeterminate],
    ])
  );
  const [sortedEquipments, setSortedEquipments] = useState<Equipment[]>(clone(equipments));
  const [filteredEquipments, setFilteredEquipments] = useState<Equipment[]>(
    clone(sortedEquipments)
  );
  const [sortingKey, setSortingKey] = useState<{ header: EquipmentKey; icon: string } | null>(null);

  const updateEquipmentFilters = useCallback((k: string, v: string | CheckboxStates | null) => {
    if (v !== null) {
      setEquipmentFilters((equipmentFilters) => new Map(equipmentFilters.set(k, v)));
    } else {
      setEquipmentFilters((equipmentFilters) => {
        const updateMap = new Map(equipmentFilters);
        updateMap.delete(k);
        return updateMap;
      });
    }
  }, []);

  const getIsSubstation = useCallback((equipment: Equipment): equipment is SecondarySubstation => {
    /* Check if equipment is a SecondarySubstation */
    return equipment.dataType === 'SecondarySubstation';
  }, []);

  const isSubstationsList = useMemo(() => {
    return equipments?.length > 0 && getIsSubstation(equipments[0]);
  }, [equipments, getIsSubstation]);

  const containerClassName = useMemo(() => {
    return !isSubstationsList ? 'counters-list' : !additionalColumn ? 'has-three-columns' : '';
  }, [additionalColumn, isSubstationsList]);

  const headers = useMemo(() => {
    const tableHeaders = ['name', 'location'];

    isSubstationsList && tableHeaders.unshift('checkbox');

    additionalColumn && tableHeaders.push(additionalColumn.key);
    !isSubstationsList && tableHeaders.push('feeder', 'phase1');

    return tableHeaders;
  }, [isSubstationsList, additionalColumn]);

  const isHeaderCheckbox = (header: string) => {
    return header === 'checkbox';
  };

  const isChecked = useCallback(
    (equipment: Equipment) => {
      return selectedIdentifiers.includes(equipment.identifier);
    },
    [selectedIdentifiers]
  );

  const sortEquipmentsBy = (header: EquipmentKey) => {
    if (!isHeaderCheckbox(header)) {
      const { sortedCollection, icon } = sortByKey<Equipment>(header, sortedEquipments, equipments);
      setSortingKey({ header, icon });
      setSortedEquipments(sortedCollection);
    }
  };

  useEffect(() => {
    setSortedEquipments(equipments);
    setSortingKey(null);
  }, [equipments]);

  useEffect(() => {
    let updateFilteredEquimentList = [...sortedEquipments];
    equipmentFilters.forEach((value, column) => {
      switch (column) {
        case 'checkbox':
        case 'location':
          updateFilteredEquimentList = filterListByCheckboxValue(
            updateFilteredEquimentList,
            value as CheckboxStates,
            (value: Equipment) => (isHeaderCheckbox(column) ? isChecked(value) : !!value.location)
          );
          break;
        default:
          updateFilteredEquimentList = updateFilteredEquimentList.filter((equipment) => {
            return equipment[column as EquipmentKey]
              ?.toString()
              .toLocaleLowerCase()
              .includes(value?.toLocaleLowerCase());
          });
      }
    });
    setFilteredEquipments(() => updateFilteredEquimentList);
  }, [equipmentFilters, isChecked, sortedEquipments]);

  return (
    <table
      className={`equipment-list-container table-container is-full-height ${containerClassName}`}
    >
      <thead className="table-header">
        <tr className="list-headers">
          {headers.map((header, i) => (
            <TableHeader
              key={`header-${i}`}
              name={header}
              useCase="equipment"
              classesContainer={`${isHeaderCheckbox(header) ? 'is-checkbox' : ''}`}
              sortProps={{
                isSortable: true,
                onSortBy: () => sortEquipmentsBy(header as EquipmentKey),
                sortingKey,
              }}
              onUpdateFilter={(v) => updateEquipmentFilters(header, v ?? null)}
              filterType={['checkbox', 'location'].includes(header) ? 'checkbox' : 'input'}
            />
          ))}
        </tr>
      </thead>
      <tbody className="list-rows is-scrollable">
        {filteredEquipments &&
          filteredEquipments.map((equipment: Equipment, i) => (
            <tr
              key={`equipment-${i}`}
              className={`list-row ${isChecked(equipment) && 'is-active'} ${
                activeEquipmentId === equipment.identifier ? 'is-selected' : ''
              }`}
            >
              {isSubstationsList && (
                <td className="list-cell is-checkbox">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={isChecked(equipment)}
                      {...(isCheckedChange && {
                        onChange: () => isCheckedChange(equipment, isChecked(equipment)),
                      })}
                    />
                    <span className="checkbox-box">
                      <Icon name="check" />
                    </span>
                  </label>
                </td>
              )}

              <td className="list-cell" title={equipment.name}>
                {equipment.name}
              </td>
              <td className="list-cell has-text-success">
                {equipment.location?.coordinates && <Icon name="check" />}
              </td>

              {getIsSubstation(equipment) ? (
                <>
                  {additionalColumn && (
                    <td className="list-cell">{equipment[additionalColumn.key]}</td>
                  )}
                </>
              ) : (
                <>
                  <td
                    className="list-cell"
                    title={!getIsSubstation(equipment) ? equipment.feeder : undefined}
                  >
                    {!getIsSubstation(equipment) && equipment.feeder}
                  </td>
                  <td className="list-cell">
                    {!getIsSubstation(equipment) && equipment.phase1 === '4'
                      ? t('general.three_phased')
                      : equipment.phase1}
                  </td>
                </>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default EquipmentList;
