import { AxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';

import { apiService } from 'services';
import { deepCaseConverter } from 'helpers';
import store from 'store';
import { apiRetries, apiRetryDelay } from 'appConstants';

import { headersPostToGet } from './apiService';

const api = apiService.setApi();

api.interceptors.request.use((request: AxiosRequestConfig) => {
  /* Set auth token and snake-case format payload before each request */
  apiService.placeTokenInRequestUrl(request);
  request.data = !isEmpty(request.data) ? deepCaseConverter(request.data, false) : request.data;

  return request;
});

api.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  /* Retry if retry params are in config */
  if (!config || !config.params?.retry) {
    return Promise.reject(err);
  }
  /* retry if Server Error 
  /!\ error messages depends of navigator type and language - do not feet w all nav*/
  if (
    !(
      message.includes('Server Error') ||
      message.includes('code 500') ||
      message.includes('Network Error')
    )
  ) {
    return Promise.reject(err);
  }
  config.params.retry -= 1;
  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, config.params.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => api(config));
});

api.interceptors.response.use(
  (response) => {
    /* When the response is an array we are in the case of analytics */
    if (response.data.length) {
      const unparsedData = response.data
        .split(/\n/)
        .filter((value: any) => value !== '')
        .at(-1);
      const parsedData = JSON.parse(unparsedData);
      return { ...response, data: deepCaseConverter(parsedData, true) };
    } else {
      return { ...response, data: deepCaseConverter(response.data, true) };
    }
  },
  (error) => apiService.handleError(error)
);

export const getConstraint = (
  field: string,
  isRegex: boolean,
  regex?: string,
  values?: string[],
  operator?: string
) => {
  if (isRegex) return { regex, field, operator: '=' };
  else return { operator, field, values };
};

export const getDefaultParams = (withConstraints: boolean): ApiBaseParams => {
  return {
    atDate: new Date(),
    frontendOnly: true,
    resultFields: {},
    ...(withConstraints && { constraints: [] }),
  };
};

export const retryConfig = () => {
  return { retry: apiRetries, retryDelay: apiRetryDelay };
};

export const getDefaultConfig = (signal?: AbortSignal, retry?: boolean): AxiosRequestConfig => {
  return {
    signal,
    headers: headersPostToGet,
    ...(retry && { params: retryConfig() }),
  };
};

export const getIntegratorAndDso = () => {
  const { session } = store.getState();
  return { integratorId: session.dataContext.integratorId, dsoId: session.dataContext.dsoId };
};

/* Method has to be post() for most calls */
export const apiCall = {
  getPreferences: async (signal?: AbortSignal) => {
    const requestUrl = '/my/preferences';
    return await api.post(requestUrl, getDefaultParams(true), {
      signal,
      headers: headersPostToGet,
    });
  },
  /* TODO delete once we get that from authentication */
  getIntegrator: async (signal?: AbortSignal) => {
    const requestUrl = '/integrators/';
    return await api.post(requestUrl, getDefaultParams(true), getDefaultConfig(signal));
  },
  /* TODO delete once we get that from authentication */
  getDso: async (integratorId: string, signal?: AbortSignal) => {
    const requestUrl = `/integrators/${integratorId}/dsos/`;
    return await api.post(requestUrl, getDefaultParams(true), getDefaultConfig(signal));
  },
  getDatasets: async (
    integratorId: string,
    dsoId: string,
    datasetId = '',
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    /* Get all datasets w/wo params or get dataset by identifier */
    let requestUrl = `integrators/${integratorId}/dsos/${dsoId}/datasets/`;
    datasetId.length && (requestUrl += `${datasetId}`);
    return await api.post<{ data: Dataset }>(
      requestUrl,
      params ?? getDefaultParams(!datasetId.length),
      getDefaultConfig(signal, true)
    );
  },

  getTopologies: async (datasetId: string, params?: ApiBaseParams | null, signal?: AbortSignal) => {
    /* Get all topologies w/wo params or get topology row by identifier */
    const { integratorId, dsoId } = getIntegratorAndDso();
    const requestUrl = `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/topology/`;

    return await api.post(
      requestUrl,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getSensors: async (datasetId: string, params?: ApiBaseParams | null, signal?: AbortSignal) => {
    /* Get sensors w/wo params */
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/sensors/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getSensorTimeDurationCurve: async (
    datasetId: string,
    analyticId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}` +
        `/processings/${analyticId}/time-duration-curve-analyses/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getSensorAnalyticsQuantile: async (
    datasetId: string,
    analyticId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}` +
        `/datasets/${datasetId}/processings/${analyticId}/quantile-analyses/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getSecSubstationAnalytics: async (
    datasetId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/lv-networks-metrics/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getSecSubPvMetrics: async (
    datasetId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/pv-capacity-metrics/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getRadarAnalytics: async (
    datasetId: string,
    analyticId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/` +
        `${analyticId}/radar-analyses/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getPermissons: async () => {
    return await api.post(`my/permissions?only=FRONTEND`, getDefaultParams(true), {
      headers: headersPostToGet,
    });
  },
  getProcessings: async (
    integratorId: string,
    dsoId: string,
    datasetId: string,
    processing?: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    let requestUrl = `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/`;
    processing && (requestUrl += `${processing}`);
    return await api.post(
      requestUrl,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getProcessingGroup: async (
    integratorId: string,
    dsoId: string,
    datasetId: string,
    processing?: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    let requestUrl =
      `integrators/${integratorId}/dsos/${dsoId}/datasets/` + `${datasetId}/processing-groups/`;
    processing && (requestUrl += `${processing}`);
    return await api.post(
      requestUrl,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getLoadCapacityModels: async (
    datasetId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getLoadCapacity: async (
    datasetId: string,
    loadCapacityModel: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/` +
        `${loadCapacityModel}/load-capacity/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getPlannedLoad: async (
    datasetId: string,
    loadCapacityModel: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/` +
        `${loadCapacityModel}/planned-load/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getPvDetections: async (
    datasetId: string,
    deviceDetectionModelId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/` +
        `${deviceDetectionModelId}/pv-detections/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getRebalancingMetrics: async (
    datasetId: string,
    rebalancingId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post<{ data: RebalancingMetricsApi[] }>(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/` +
        `${rebalancingId}/rebalancing-metrics/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  getRebalancingPlan: async (
    datasetId: string,
    rebalancingId: string,
    params?: ApiBaseParams | null,
    signal?: AbortSignal
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return await api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/processings/` +
        `${rebalancingId}/rebalancing-plan/`,
      params ?? getDefaultParams(true),
      getDefaultConfig(signal, true)
    );
  },
  simulatePv: async (
    datasetId: string,
    secSubId: string,
    listPv: { smartmeterId: string; value: number; phase: Phase }[]
  ) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/jobs/`,
      {
        data: {
          sec_sub: secSubId,
          load_type: 'pv',
          analytics: 'LoadCapacityAnalytics',
          simulated_load: listPv.map((pv) => ({
            sensor_identifier: pv.smartmeterId,
            ...(pv.phase === '1' && { phase1: pv.value }),
            ...(pv.phase === '2' && { phase2: pv.value }),
            ...(pv.phase === '3' && { phase3: pv.value }),
            ...(pv.phase === '4' && { phase4: pv.value }),
          })),
        },
      },
      { params: { synchronous: true, ...retryConfig() } }
    );
  },
  validatePlannedLoad: async (datasetId: string, secSubId: string, processing: string) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/jobs/`,
      {
        data: {
          load_type: 'pv',
          sec_sub: secSubId,
          processing,
          analytics: 'ValidatePlannedLoadAnalytics',
        },
      },
      { params: { synchronous: true, ...retryConfig() } }
    );
  },
  cancelPlannedLoad: async (datasetId: string, secSubId: string, smartmeters: string[]) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    return api.post(
      `integrators/${integratorId}/dsos/${dsoId}/datasets/${datasetId}/jobs/`,
      {
        data: {
          sec_sub: secSubId,
          smartmeters,
          load_type: 'pv',
          analytics: 'CancelPlannedLoadAnalytics',
        },
      },
      { params: { synchronous: true, ...retryConfig() } }
    );
  },
};
