import React from 'react';

import './carousel.scss';

type Props = {
  isDirectionLeft?: boolean;
  isDisplayed?: boolean;
  isDisabled?: boolean;
  specialClass?: string;
  onClick: () => void;
};

const Carousel = ({
  isDirectionLeft = true,
  isDisplayed = true,
  isDisabled = false,
  specialClass = 'tab-carousel',
  onClick,
}: Props) => {
  const getClass = () => {
    let classReturned = specialClass;

    if (!isDisplayed) {
      classReturned += ' is-not-displayed';
    }
    if (!isDirectionLeft && specialClass === 'tab-carousel') {
      classReturned += ' is-diplayed-right';
    }

    return classReturned;
  };

  return (
    <button className={getClass()} onClick={onClick} disabled={isDisabled}>
      {isDirectionLeft ? '<' : '>'}
    </button>
  );
};
export default Carousel;
