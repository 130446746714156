import React, { ReactNode } from 'react';
import './splitcolumntemplate.scss';

type Props = {
  upperSlot: ReactNode;
  upperRatio: number;
  middleSlot: ReactNode;
  middleRatio: number;
  lowerSlot?: ReactNode;
  lowerRatio?: number;
};

const SplitColumnTemplate = ({
  upperRatio,
  middleRatio,
  lowerRatio,
  upperSlot,
  middleSlot,
  lowerSlot,
}: Props) => {
  const classes = {
    mainDiv: 'split-column is-full-height is-flex is-flex-direction-column',
    upperSlot: 'template-slot is-flex-centered',
    middleSlot: 'template-slot is-flex-centered has-top-separator',
    lowerSlot: 'template-slot is-flex-centered has-top-separator',
  };

  return (
    <div className={classes.mainDiv}>
      <div style={{ flexBasis: `${upperRatio}%` }} className={classes.upperSlot}>
        {upperSlot}
      </div>

      <div style={{ flexBasis: `${middleRatio}%` }} className={classes.middleSlot}>
        {middleSlot}
      </div>
      {lowerSlot && (
        <div style={{ flexBasis: `${lowerRatio}%` }} className={classes.lowerSlot}>
          {lowerSlot}
        </div>
      )}
    </div>
  );
};

export default SplitColumnTemplate;
