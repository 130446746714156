import { isEqual, orderBy } from 'lodash';

type sortingKeys = 'asc' | 'desc' | 'none' | 'noneAlreadyAsc' | 'notSortable' | 'noneAlreadyDesc';
type sortOption = {
  [key in sortingKeys]: {
    next: sortingKeys;
    icon: string;
  };
};

export const sortOptions: sortOption = {
  asc: {
    next: 'desc',
    icon: 'angle-up',
  },
  desc: {
    next: 'none',
    icon: 'angle-down',
  },
  none: {
    next: 'asc',
    icon: 'double-chevron',
  },
  noneAlreadyAsc: {
    next: 'desc',
    icon: 'angle-up',
  },
  noneAlreadyDesc: {
    next: 'asc',
    icon: 'angle-down',
  },
  notSortable: {
    next: 'none',
    icon: 'double-chevron',
  },
};

export const currentSortOrder = <T>(key: keyof T, collection: T[], initialCollection: T[]) => {
  const values = collection.map((e: T) => e[key]);
  /* If there is no data the chevron should not change */
  if (!values.filter((item) => item != null)?.length) {
    return 'notSortable';
  }
  const isNone = isEqual(
    values,
    initialCollection.map((e: T) => e[key])
  );
  const isAsc = values.slice(1).every((item, i) => values[i] <= item || item == null);
  const isDesc = values.slice(1).every((item, i) => values[i] >= item || values[i] == null);

  if (isNone) {
    return isAsc ? 'noneAlreadyAsc' : isDesc ? 'noneAlreadyDesc' : 'none';
  } else if (isAsc && !isDesc) {
    return 'asc';
  } else if (!isAsc && isDesc) {
    return 'desc';
  } else {
    return 'none';
  }
};

export const sortByKey = <T>(
  key: keyof T,
  collection: T[],
  initialCollection: T[]
): { sortedCollection: T[]; icon: string } => {
  const sortOrder = sortOptions[currentSortOrder(key, collection, initialCollection)].next;

  if (sortOrder === 'asc' || sortOrder === 'desc') {
    return {
      sortedCollection: orderBy(collection, key, sortOrder) as T[],
      icon: sortOptions[sortOrder].icon,
    };
  } else {
    return { sortedCollection: initialCollection, icon: sortOptions[sortOrder].icon };
  }
};
