import React from 'react';

import { Icon } from 'components/atoms';

type Props = {
  tagText: string;
  onClose?: () => void;
};

const Tag = ({ tagText, onClose }: Props) => {
  return (
    <div className="tag is-rounded filter-tag has-tooltip">
      <Icon classes="filter-icon" name="filter" />
      <div className="filter-input-text ">
        {tagText}
        <div className="tooltip-text">{tagText}</div>
      </div>
      {onClose && <button onClick={onClose} className="delete is-small cursor-pointer"></button>}
    </div>
  );
};

export default Tag;
