import React from 'react';
import iconsPath from 'assets/icons/icons.svg';

type Props = {
  name: string;
  viewBox?: string;
  classes?: string;
};

const Icon = ({ name, viewBox, classes = '' }: Props) => {
  return (
    <svg
      className={`custom-icon ${classes}`}
      viewBox={viewBox || '0 0 24 24'}
      fill="currentColor"
      preserveAspectRatio="xMidYMid meet"
    >
      <use href={`${iconsPath}#icon-${name}`} />
    </svg>
  );
};

export default Icon;
