import React from 'react';
import { getIsStripped } from 'helpers';

import './gauge.scss';
/**
 * @description [value (width as percentage), color hex code]
 */
type GaugeSegment = [number, string];
type Props = {
  segments: GaugeSegment[];
};

const Gauge = ({ segments }: Props) => {
  return (
    <div className="gauge-container is-flex-centered">
      {segments &&
        segments.map((segment, i) => (
          <div
            key={`gauge-${i}`}
            className="gauge-segment"
            style={{
              width: `${segment[0]}%`,
              background: `${segment.length && getIsStripped(segment[1])}`,
            }}
          ></div>
        ))}
    </div>
  );
};

export default Gauge;
