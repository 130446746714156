import React from 'react';
import { Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Login } from 'components/pages';

type Props = {
  children: JSX.Element;
  permission?: boolean;
  redirectPath?: string;
};
const PrivateRoute = ({ children, permission = true, redirectPath = '/mapping' }: Props) => {
  const { keycloak } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;
  return !permission ? <Navigate to={redirectPath} replace /> : isLoggedIn ? children : Login();
};

export default PrivateRoute;
