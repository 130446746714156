import React from 'react';

import { Icon } from 'components/atoms';

import variables from 'assets/styles/partials/exports/_variables.module.scss';
import './banner.scss';

type Props = {
  color: string;
  title: string;
  iconName?: string;
  buttons?: React.ReactNode;
  extraData?: React.ReactNode;
};

const Banner = ({ color, title, iconName, buttons, extraData }: Props) => {
  return (
    <div className="banner-container" style={{ background: `${variables[color]}` }}>
      {iconName && (
        <span className="mt-3 ml-3">
          <Icon name={iconName} />
        </span>
      )}
      <div className="banner-title">{title}</div>
      {extraData}
      {buttons}
    </div>
  );
};

export default Banner;
