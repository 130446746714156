import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy, head, snakeCase } from 'lodash';
import { useLocation } from 'react-router-dom';

import { SplitColumnTemplate, SplitScreenTemplate } from 'components/templates';
import {
  DecisionAccordion,
  EquipmentList,
  LeafletMap,
  PermutationTable,
  SubstationsTabs,
} from 'components/organisms';
import { Accordion, ActiveTab, Banner, SensorsCluster, Tabs } from 'components/molecules';
import { Icon, Loader } from 'components/atoms';

import {
  asTemplateString,
  convertPercent,
  convertRebalancingMetricsToTableRow,
  createStaticTabs,
  getListPermutations,
  getRebalancingMetrics,
} from 'helpers';
import { useAppSelector, useSensors, useSubstationsTabList, useTopology } from 'hooks';
import { rebalancingAnalytics, RebalancingBannerMode, RotationDirection } from 'appConstants';

import './rebalancing.scss';

const Rebalancing = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const smartMetersTabs = useMemo(() => createStaticTabs('smart_meter_plural'), [t]);
  const [activeSecondarySubstation, setActiveSecondarySubstation] = useState<SecondarySubstation>();
  const [hoveredSmartmeterId, setHoveredSmartmeterId] = useState<string>();

  const { topology } = useTopology();
  const { datasetId, rebalancingIds } = useAppSelector((state) => state.session.dataContext);
  const [metricsRows, setMetricsRows] = useState<RebalancingTableRow[]>([]);
  const [isLoadingRebalancing, setIsLoadingRebalancing] = useState<boolean>(false);
  const [selectedRebalancing, setSelectedRebalancing] = useState<boolean>();
  const [rotationDirection, setRotationDirection] = useState<RotationDirection>(
    RotationDirection.undefined
  );
  const [rebalancingProcessing, setRebalancingProcessing] = useState('');
  const [permutationsByFeeder, setPermutationsByFeeder] = useState<PermutationsByFeeder>();
  const [numberOfPermutations, setNumberOfPermutations] = useState<number>(0);
  const [bannerMode, setBannerMode] = useState<RebalancingBanner>();

  const {
    mapCenter,
    setMapCenter,
    currentSmartMeters,
    setCurrentSmartMeters,
    currentFeeders,
    setCurrentFeeders,
    isLoading,
    secondarySubstations,
    getSmartMetersBySecSubstation,
  } = useSensors();

  const { substationsTabList, addTab, removeTabs } = useSubstationsTabList({
    secondarySubstations: secondarySubstations ?? [],
    activeSecondarySubstation,
    setActiveSecondarySubstation,
  });

  const smartMetersWithLocation = useMemo(() => {
    return currentSmartMeters.filter((smartmeter: SmartMeter) => smartmeter.location);
  }, [currentSmartMeters]);

  const [smartMetersActiveTab, setSmartMetersActiveTab] = useState(
    head(smartMetersTabs)?.identifier
  );

  const smartMetersActiveTabIndex = useMemo(() => {
    return smartMetersTabs.findIndex(
      (s: { name: string; identifier: string }) => s?.identifier === smartMetersActiveTab
    );
  }, [smartMetersActiveTab, smartMetersTabs]);

  const smartMetersWithFeeder = useMemo(() => {
    return currentSmartMeters.map((smartMeter) => {
      const feeder = currentFeeders?.find((feeder) => feeder.identifier === smartMeter.feeder);
      return { ...smartMeter, feeder: feeder ? feeder.name : '' };
    });
  }, [currentFeeders, currentSmartMeters]);

  useEffect(() => {
    if (location.state) {
      const secondarySubFromNav = secondarySubstations?.find(
        (secondarySub: SecondarySubstation) => secondarySub.identifier === location.state
      );
      if (secondarySubFromNav) {
        setActiveSecondarySubstation(secondarySubFromNav);
      }
    }
  }, [location.state, secondarySubstations]);

  useEffect(() => {
    const getRebalancing = async (rebalancingId: string, secSub: SecondarySubstation) => {
      setIsLoadingRebalancing(true);
      const rebalancingMetrics = await getRebalancingMetrics(datasetId, rebalancingId);
      const rebalancingRow: RebalancingTableRow =
        convertRebalancingMetricsToTableRow(rebalancingMetrics);
      const noRebalancingRow: RebalancingTableRow = Object.assign(
        Object.fromEntries(
          Object.entries(secSub).filter(([k]) =>
            rebalancingAnalytics.includes(k as KeyRebalancingTable)
          )
        ) as RebalancingTableRow,
        {
          rebalancing: false,
          imbalanceRate: convertPercent(rebalancingMetrics.imbalanceRateBefore),
        }
      );
      setMetricsRows([noRebalancingRow, rebalancingRow]);
      setIsLoadingRebalancing(false);
    };
    if (activeSecondarySubstation) {
      setCurrentSmartMeters([]);
      setPermutationsByFeeder({});
      setNumberOfPermutations(0);
      setSelectedRebalancing(false);
      setRotationDirection(RotationDirection.undefined);
      getSmartMetersBySecSubstation(
        topology,
        activeSecondarySubstation.identifier,
        datasetId,
        false
      );
      const rebalancingProcess = rebalancingIds.find(
        (process: ProcessingSensor) => process.sensor === activeSecondarySubstation.identifier
      )?.processing;

      /* the bannerMode depends on the secondary substation, 
      other modes will have to be added with further use case such as: waiting for results */
      setBannerMode(
        /* rebalancingProcess ? RebalancingBannerMode.Initial : RebalancingBannerMode.NoData
        hide banner while unused */
        RebalancingBannerMode.Hidden
      );
      rebalancingProcess && setRebalancingProcessing(rebalancingProcess);
      rebalancingProcess && getRebalancing(rebalancingProcess, activeSecondarySubstation);
    }
  }, [
    activeSecondarySubstation,
    datasetId,
    getSmartMetersBySecSubstation,
    rebalancingIds,
    setCurrentFeeders,
    setCurrentSmartMeters,
    topology,
  ]);

  useEffect(() => {
    const getPermutations = async () => {
      const listePermutations = await getListPermutations(
        rebalancingProcessing,
        datasetId,
        currentSmartMeters,
        currentFeeders
      );
      const permutationByFeeder = groupBy(listePermutations, (perm) => perm.feeder);
      setPermutationsByFeeder(permutationByFeeder);
      setNumberOfPermutations(listePermutations?.length);
    };

    if (rebalancingProcessing !== '') {
      getPermutations();
    }
  }, [currentFeeders, currentSmartMeters, datasetId, rebalancingProcessing]);

  const onSelectRebalancing = (className: string, value: string) => {
    const isRebalancingOn =
      (className === 'rebalancing-checkbox-on' && value === 'on') ||
      (className === 'rebalancing-checkox-off' && value === 'off');
    setSelectedRebalancing(isRebalancingOn);
    !isRebalancingOn && setRotationDirection(RotationDirection.undefined);
  };

  const selectRotationDirection = (value: string) => {
    const rotation = value as RotationDirection;
    setRotationDirection(rotation);
  };

  return (
    <div className="is-family-primary is-body">
      <SplitScreenTemplate
        ratio={33}
        leftSlot={
          <SplitColumnTemplate
            upperRatio={55}
            middleRatio={45}
            upperSlot={
              secondarySubstations && (
                <SubstationsTabs
                  mapCenter={mapCenter}
                  setMapCenter={setMapCenter}
                  hasAnalytics={false}
                  activeSecondarySubstation={activeSecondarySubstation}
                  addTab={addTab}
                  removeTabs={removeTabs}
                  substationsTabList={substationsTabList}
                />
              )
            }
            middleSlot={
              <>
                <Tabs
                  openTab={setSmartMetersActiveTab}
                  activeTabIndex={smartMetersActiveTabIndex}
                  tabArray={smartMetersTabs}
                />
                <ActiveTab activeTabIndex={smartMetersActiveTabIndex}>
                  <>
                    {mapCenter && (
                      <LeafletMap
                        center={mapCenter}
                        mapSlot={
                          <>
                            <SensorsCluster
                              secondarySubstations={
                                activeSecondarySubstation ? [activeSecondarySubstation] : []
                              }
                              activeSecondarySubstation={activeSecondarySubstation ?? null}
                              smartMeters={smartMetersWithLocation}
                              permutationByFeeder={permutationsByFeeder}
                              isUsecaseRebalancing={true}
                              setHoveredSmartmeterId={setHoveredSmartmeterId}
                              hoveredSmartmeterId={hoveredSmartmeterId}
                            />

                            <div className="map-color-container">
                              <div className="is-flex is-align-items-center">
                                <div className="color-square legend-permuted"></div>
                                <div className="legend">{t('rebalancing.map.legend.required')}</div>
                              </div>
                              <div className="is-flex is-align-items-center">
                                <div className="color-square legend-unpermuted"></div>
                                <div className="legend">
                                  {t('rebalancing.map.legend.no_required')}
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      />
                    )}
                  </>
                  {isLoading.smartMeters ? (
                    <Loader />
                  ) : (
                    <EquipmentList equipments={smartMetersWithFeeder} selectedIdentifiers={[]} />
                  )}
                </ActiveTab>
              </>
            }
          />
        }
        rightSlot={
          <div className="container-rebalancing page-container">
            {isLoadingRebalancing && <Loader fullScreen={false} />}
            <Tabs
              leftIcon={
                <span className="icon is-small">
                  <Icon name="substation" />
                </span>
              }
              isScrollable={false}
              removeTabs={removeTabs}
              openTab={addTab}
              activeTabIndex={substationsTabList?.findIndex(
                (s) => s?.identifier === activeSecondarySubstation?.identifier
              )}
              tabArray={substationsTabList.map((secondarySub) => ({
                name: secondarySub?.name,
                identifier: secondarySub?.identifier,
              }))}
            />
            {activeSecondarySubstation && (
              <div className="secondary-substation-container">
                <div className="is-flex-centered">
                  {bannerMode && (
                    <Banner
                      color={`rebalancing${bannerMode}`}
                      title={t(asTemplateString(`rebalancing.banner.${snakeCase(bannerMode)}`))}
                      iconName="substation"
                    ></Banner>
                  )}
                </div>
                <div className={'accordion-container is-scrollable'}>
                  {rebalancingIds.find(
                    (process: ProcessingSensor) =>
                      process.sensor === activeSecondarySubstation.identifier
                  ) &&
                    !isLoadingRebalancing && (
                      <DecisionAccordion
                        metricsRows={metricsRows}
                        onSelectRebalancing={onSelectRebalancing}
                        selectedRebalancing={selectedRebalancing ?? false}
                        rotationDirection={rotationDirection}
                        selectRotationDirection={selectRotationDirection}
                        numberOfPermutations={numberOfPermutations}
                      />
                    )}
                  {rotationDirection !== RotationDirection.undefined && (
                    <Accordion
                      isPrevOpen={true}
                      titleIcon="plan"
                      classes="planification-accordion"
                      title={t('rebalancing.planification.title')}
                    >
                      <div className="card permutation-card">
                        <header className="card-header">
                          <p className="card-header-title">
                            {t('rebalancing.planification.intervention_plan')}
                          </p>
                        </header>
                        <div className="card-content">
                          <div className="content ">
                            <PermutationTable
                              permutationByFeeder={permutationsByFeeder}
                              rotationDirection={rotationDirection}
                              setHoveredSmartmeterId={setHoveredSmartmeterId}
                              hoveredSmartmeterId={hoveredSmartmeterId}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <button className="button is-rounded is-link is-outlined mr-2 mb-2 mt-4">
                        {t('rebalancing.planification.validate')}
                      </button> */}
                    </Accordion>
                  )}
                  {/* <Accordion titleIcon="results" title={t('rebalancing.results.title')}
                  ></Accordion> */}
                </div>
              </div>
            )}
            {!activeSecondarySubstation && (
              <div className="no-substation-selection is-full-height">
                {t('rebalancing.no_substation_selected')}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Rebalancing;
