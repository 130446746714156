import React from 'react';

import './loader.scss';

type Props = {
  fullScreen?: boolean /* if fullScreen is false, parent node position must be 'relative' */;
  hasOverlay?: boolean;
};

/**
 * @description if `fullScreen` is false, please place your parent node in :
 * `position: relative;`
 */
const Loader = ({ fullScreen = false, hasOverlay = true }: Props) => {
  return (
    <div
      className={`loader-container ${fullScreen && 'is-full-screen'} ${
        hasOverlay && 'has-overlay'
      }`}
    >
      <span className="loader is-loading"></span>
    </div>
  );
};

export default Loader;
