import React from 'react';
import { createRoot } from 'react-dom/client';
/* I18nextProvider */
import { I18nextProvider } from 'react-i18next';
/* Persist Store */
import { Provider } from 'react-redux';

import i18n from 'i18n/i18n';
import store from 'store';
import keycloak from 'keycloack';
import reportWebVitals from './reportWebVitals';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import 'assets/styles/app.scss';

import { Loader } from 'components/atoms';
import App from 'App';

const I18nApp = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ReactKeycloakProvider authClient={keycloak} LoadingComponent={<Loader />}>
          <App />
        </ReactKeycloakProvider>
      </Provider>
    </I18nextProvider>
  );
};

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(<I18nApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
