import { CheckboxStates } from 'appConstants';

export const filterListByCheckboxValue = <T>(
  initialList: Array<T>,
  valueFilterCheckbox: CheckboxStates,
  checkValueFunction: (value: T) => boolean
) => {
  return initialList.filter((element) => {
    switch (valueFilterCheckbox) {
      case CheckboxStates.Checked:
        return checkValueFunction(element);
      case CheckboxStates.Unchecked:
        return !checkValueFunction(element);
      default:
        return true;
    }
  });
};
