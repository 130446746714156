export enum CheckboxStates {
  Checked = 'Checked',
  Indeterminate = 'Indeterminate',
  Unchecked = 'Unchecked',
}
export enum TypeCloseTabs {
  All = 'all',
  Other = 'other',
  Inactive = 'inactive',
}

export enum ImpactCapacity {
  Installed = 'installed',
  Planned = 'planned',
  Added = 'added',
  ResMax = 'maxRes',
  ResPhase = 'resPhase',
}

export enum RebalancingBannerMode {
  Initial = 'Initial',
  NoData = 'NoData',
  Hidden = '' /* hide banner while unused*/,
}

export enum ImpactModalType {
  Add = 'add',
  WarningPlanned = 'warningPlanned',
  Planned = 'planned',
}

export enum StatusResponse {
  success = 'success',
  errors = 'errors',
}

/* Direct Rotation direction = 1-2-3 (anticlockwise)
Indirect Rotation direction = 3-2-1 (clockwise) */
export enum RotationDirection {
  undefined = 'undefined',
  direct = 'direct',
  indirect = 'indirect',
}
