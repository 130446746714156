import React from 'react';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';

import { Icon, SelectDropdown } from 'components/atoms';
import { Accordion, InputField } from 'components/molecules';
import { RebalancingTable } from 'components/organisms';

import { asTemplateString, setHoverDefinition } from 'helpers';
import { RotationDirection, criticalCriterias } from 'appConstants';

import './decision-accordion.scss';

type Props = {
  metricsRows: RebalancingTableRow[];
  selectedRebalancing: boolean;
  rotationDirection: RotationDirection;
  onSelectRebalancing: (checkboxClass: string, value: string) => void;
  selectRotationDirection: (direction: string) => void;
  numberOfPermutations: number;
};

const DecisionAccordion = ({
  metricsRows,
  selectedRebalancing,
  rotationDirection,
  onSelectRebalancing,
  selectRotationDirection,
  numberOfPermutations,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Accordion
      isPrevOpen={true}
      titleIcon="decision"
      classes="decision-accordion"
      title={t('rebalancing.decision.title')}
    >
      <InputField
        label={t('rebalancing.decision.number_smartmeter_changes')}
        value={numberOfPermutations}
        classes="is-horizontal no-background-color number-permutations"
      ></InputField>
      <div className="rules-critical-criterias">
        <span className="title-critical-criterias">
          <Icon name="info" classes="mr-2 is-medium" />
          {t('rebalancing.decision.critical_rules')}
        </span>

        {criticalCriterias.map((criteria) => {
          return (
            <div key={criteria.key} className={'criteria-box'}>
              <div
                className="criteria"
                dangerouslySetInnerHTML={{
                  __html: t(
                    asTemplateString(`table_headers.rebalancing.${snakeCase(criteria.key)}`)
                  ),
                }}
                title={setHoverDefinition(criteria)}
              />
              <div className="criteria-value">&#8805; {criteria.value}</div>
            </div>
          );
        })}
      </div>
      <RebalancingTable
        metricsRows={metricsRows}
        selected={selectedRebalancing}
        onSelectRebalancing={onSelectRebalancing}
      />
      {selectedRebalancing && (
        <InputField
          label={t('rebalancing.decision.selection_label')}
          value={t('rebalancing.decision.rebalancing')}
          classes="is-horizontal"
        ></InputField>
      )}
      {selectedRebalancing && (
        <>
          <div className="message">
            <div className="message-body">
              <Icon name="info" classes="mr-2" />
              {t('rebalancing.decision.info_rotation')}
            </div>
          </div>
          <SelectDropdown
            dropdownOptions={[
              {
                key: RotationDirection.undefined,
                translationKey: 'rebalancing.decision.unknown_rotation',
              },
              { key: RotationDirection.direct, translationKey: 'rebalancing.decision.direct' },
              { key: RotationDirection.indirect, translationKey: 'rebalancing.decision.indirect' },
            ]}
            selectValue={(value: string) => {
              return selectRotationDirection(value as RotationDirection);
            }}
            initialValue={rotationDirection}
            name={t('rebalancing.decision.rotation_angle_choice')}
            label={t('rebalancing.decision.rotation_angle_choice')}
          ></SelectDropdown>
        </>
      )}
    </Accordion>
  );
};

export default DecisionAccordion;
