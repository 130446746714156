import React from 'react';
import { useTranslation } from 'react-i18next';

import { asTemplateString, getIsStripped } from 'helpers';
import './color-legend.scss';

type Props = {
  prefixTranslationKey: string;
  colorVariables: string[];
};

const ColorLegend = ({ colorVariables, prefixTranslationKey }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {colorVariables.map((colorVariable) => {
        return (
          <div key={colorVariable} className="is-flex is-align-items-center">
            <div
              className={`${!colorVariable.includes('line') && 'color-square'}`}
              style={{ background: `${colorVariable.length && getIsStripped(colorVariable)}` }}
            ></div>
            <div>{t(asTemplateString(`${prefixTranslationKey}${colorVariable}`))}</div>
          </div>
        );
      })}
    </>
  );
};

export default ColorLegend;
