import React from 'react';
import { useTranslation } from 'react-i18next';

const Performances = () => {
  const { t } = useTranslation();

  return (
    <div className="is-flex-grow-1 is-family-primary">
      <div className="is-full-height is-flex is-justify-content-center is-align-items-center">
        {t('performances.dashboard')}
      </div>
    </div>
  );
};

export default Performances;
