import { asTemplateString } from 'helpers';
import { t } from 'i18next';
import { findLastIndex, mapValues } from 'lodash';

/* Find index of element of a list that is the closest superior element  */
export const getClosestValueIndex = (listNumber: number[], searchElement: number) => {
  if (searchElement === 0 && listNumber[0] === -1 && listNumber[1] === 0) {
    return 0;
  }
  return findLastIndex(listNumber, (value) => value <= searchElement);
};

export const convertWtoKw = <T>(keys: Array<keyof T>, obj: object) => {
  return mapValues(obj, (value, key) => {
    return keys.includes(key as keyof T) && typeof value === 'number' ? value / 1000 : value;
  });
};

export const setHoverDefinition = (option: SecondarySubAnalyticsOption) => {
  return (
    t(asTemplateString(option.translationKey)) +
    ' :\n' +
    t(asTemplateString('info_' + option.translationKey))
  );
};
