import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { Icon } from 'components/atoms';

type Props = {
  onSend: (e: string) => void;
  isOnMap: boolean;
};

const SearchBar = ({ onSend, isOnMap = false }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const map = useMap();

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      onSend(searchValue);
    }
  };
  const handleMouseEnter = () => {
    map.dragging.disable();
  };
  const handleMouseLeave = () => {
    map.dragging.enable();
  };

  return (
    <div className="field has-addons search-bar-control leaflet-control">
      <div className="control has-icons-left input-search">
        <input
          className="input is-rounded"
          type="text"
          autoComplete="off"
          placeholder={t('map.map_search_placeholder')}
          name="search"
          value={searchValue}
          onKeyDown={(e) => handleKeyDown(e.key)}
          onChange={(e) => setSearchValue(e.target.value)}
          {...(isOnMap && { onMouseEnter: () => handleMouseEnter() })}
          {...(isOnMap && { onMouseLeave: () => handleMouseLeave() })}
        />
        <span className="icon is-left">
          <Icon name="search" />
        </span>
      </div>
      <div className="control">
        <button type="submit" className="button is-rounded" onClick={() => onSend(searchValue)}>
          {t('map.search')}
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
