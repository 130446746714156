import { useMemo, useEffect } from 'react';

export const useAbortController = () => {
  const controller: AbortController = useMemo(() => {
    return new AbortController();
  }, []);

  useEffect(() => {
    return () => {
      /* Abort requests when component unmount */
      controller.abort();
    };
    /* ignore missing dependency - controller : not a changing dependency */
  }, [controller]);

  return {
    controller,
  };
};
