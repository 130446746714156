/* eslint-disable max-len */
import { BaseIconOptions, DivIcon, DivIconOptions, Icon } from 'leaflet';
import { clone, max } from 'lodash';
import {
  substationMarkerIcon,
  counterMarkerIcon,
  activeSubstationMarkerIcon,
  dynamicSubstationMarkerIcon,
  dynamicCounterMarkerIcon,
} from 'assets/icons';
import variables from 'assets/styles/partials/exports/_variables.module.scss';

type MarkerIconOptions = {
  default: string;
  dynamic?: string;
  active?: string;
};

type MarkerIcons = {
  substation: MarkerIconOptions;
  counter: MarkerIconOptions;
};

const markerIconOptions: MarkerIcons = {
  substation: {
    default: substationMarkerIcon,
    active: activeSubstationMarkerIcon,
    dynamic: dynamicSubstationMarkerIcon,
  },
  counter: {
    default: counterMarkerIcon,
    dynamic: dynamicCounterMarkerIcon,
  },
};

/* Default config for Marker component */
export const markerDefaultConfig: BaseIconOptions = {
  iconSize: [27, 34],
  iconAnchor: [13.5, 34],
  tooltipAnchor: [0, 0],
};

export const getDynamicMarkerConfig = (
  className: string,
  type: keyof MarkerIcons,
  iconId: string
): DivIconOptions => {
  return {
    ...markerDefaultConfig,
    html: `<svg
    class="${className}"
    fill="currentColor"
    preserveAspectRatio="xMidYMid meet"
    width="27" 
    height="34" 
    viewBox="0 0 28 40"
  >
    <use href="${markerIconOptions[type].dynamic}#icon-${iconId}" />
     </svg>`,
    className: 'dynamic-marker',
  };
};

const setCircle = (
  radiusMax: number,
  radius: number,
  color: string,
  strokeWidth?: number,
  dashed?: boolean
) => {
  const stroke =
    strokeWidth !== undefined
      ? `fill="transparent" stroke-width="${strokeWidth}"
  stroke="${color}" stroke-opacity="${variables.fillImpactOpacity}" 
  ${dashed && 'stroke-dasharray="3"'}`
      : `fill="${color}" fill-opacity="${variables.fillImpactOpacity}"`;

  return `<circle r="${radius}"
    cx="${radiusMax}"
    cy="${radiusMax}" 
    ${stroke} /> `;
};

export const getDynamicImpactMarker = (
  impactCapacities: NormalizedCapacities,
  selected: boolean,
  isHover?: boolean
): DivIconOptions => {
  const impacts = clone(impactCapacities);
  Object.keys(impacts).forEach((el) => {
    impacts[el as keyof NormalizedCapacities] /= 3;
  });
  const { installed, planned, added, residual, overflow, maxBeforeSimulation } = impacts;
  const radiusPlanned = installed + planned / 2;
  const radiusAdded = radiusPlanned + added / 2 + planned / 2;
  const radiusResidual = radiusAdded + residual / 2 + added / 2;
  const radiusOverflow = radiusResidual + overflow / 2 + residual / 2;

  const radiusMax = max([radiusOverflow + overflow / 2, maxBeforeSimulation + 2]) as number;

  return {
    ...markerDefaultConfig,
    html: `<svg height="${radiusMax * 2}" width="${radiusMax * 2}">
      ${setCircle(radiusMax, installed, variables.installed)}
      ${setCircle(radiusMax, radiusPlanned, variables.planned, planned)}
      ${setCircle(radiusMax, radiusAdded, variables.added, added)}
      ${setCircle(radiusMax, radiusOverflow, variables.overflow, overflow)}
      ${setCircle(radiusMax, radiusResidual, variables.residual, residual)}
      ${setCircle(radiusMax, maxBeforeSimulation, variables.greyTertiary, 1, isHover)}

    </svg>`,
    className: `dynamic-marker ${selected ? 'is-icon-selected' : ''}`,
    iconAnchor: [radiusMax, radiusMax],
  };
};

export const getMarkerIcon = (
  type: keyof MarkerIcons,
  isActive?: boolean,
  cssClasses?: string,
  impact?: { capacities: NormalizedCapacities; selected: boolean },
  isHover?: boolean
): Icon<BaseIconOptions> => {
  if (impact) {
    return new DivIcon(getDynamicImpactMarker(impact.capacities, impact.selected, isHover));
  } else if (cssClasses && !isActive) {
    return new DivIcon(getDynamicMarkerConfig(cssClasses, type, 'dynamic-' + type.toString()));
  } else {
    const iconOptionKey = isActive ? 'active' : 'default';
    const newIcon = new Icon(markerDefaultConfig);
    newIcon.options.iconUrl = markerIconOptions[`${type}`][iconOptionKey];
    return newIcon;
  }
};

export const markdownProps = {
  colorClass: { permuted: 'marker-permuted', unpermuted: 'marker-not-permuted' },
  /*A markdown with a highter zIndex will be less recovered by others and more visible*/
  zIndex: { permuted: '3', unpermuted: '-20' },
};
