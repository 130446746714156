import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import routes from 'router/routes';

type Props = {
  activeLinks?: { analysis: boolean; impact: boolean; rebalancing: boolean };
  selectedSecSubstationId?: string;
};
const UseCaseActions = ({
  activeLinks = { analysis: true, impact: true, rebalancing: true },
  selectedSecSubstationId = '',
}: Props) => {
  const { t } = useTranslation();
  const useCases: { caseName: 'analysis' | 'impact' | 'rebalancing'; path: string }[] = [
    { caseName: 'analysis', path: routes.analysis.path },
    { caseName: 'impact', path: routes.impact.path },
    { caseName: 'rebalancing', path: routes.rebalancing.path },
  ];

  const handleClick = (e: React.MouseEvent, isLinkActive: boolean) => {
    if (!isLinkActive) e.preventDefault();
  };

  return (
    <ul className="use-case-actions">
      {useCases.map((useCase) => {
        return (
          <li className="use-case-action" key={`${useCase.caseName}`}>
            <NavLink
              onClick={(e) => handleClick(e, activeLinks[useCase.caseName])}
              to={useCase.path}
              state={selectedSecSubstationId}
              className={`use-case-link is-flex is-align-items-center ${
                !activeLinks[useCase.caseName] && 'is-disabled-link'
              }`}
            >
              {t(`${useCase.caseName}.action_title`)}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default UseCaseActions;
