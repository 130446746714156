import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';

import { mapDefaultConfig } from 'appConstants';
import { CenterControl, FullscreenControl } from 'components/atoms';
import { ChangeCenter } from 'components/molecules/Map';

type Props = {
  minZoom?: number;
  maxZoom?: number;
  tile?: string;
  scrollWheelZoom?: boolean;
  mapSlot?: ReactNode;
  hasSearchBar?: boolean;
  onSearch?: (searchText: string) => void;
  center: LatLngTuple;
  initialZoom?: number;
};

const LeafletMap = ({
  center,
  tile = mapDefaultConfig.layers.raster,
  initialZoom = mapDefaultConfig.initialZoom,
  minZoom = mapDefaultConfig.minZoom,
  maxZoom = mapDefaultConfig.maxZoom,
  scrollWheelZoom = mapDefaultConfig.scrollWheelZoom,
  mapSlot,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="is-full-dim container-map">
      <MapContainer
        className="is-full-height is-full-width"
        center={center}
        zoom={initialZoom}
        zoomControl={false}
        minZoom={minZoom}
        maxZoom={maxZoom}
        scrollWheelZoom={scrollWheelZoom}
      >
        <TileLayer url={tile} />
        <ZoomControl
          zoomInTitle={t('map.zoom_in_title')}
          zoomOutTitle={t('map.zoom_out_title')}
          zoomInText=""
          zoomOutText=""
        />
        <CenterControl position="topleft" />
        <FullscreenControl position="topleft" />
        <ChangeCenter center={center} initialZoom={initialZoom} />
        {mapSlot}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
