import React, { ReactNode } from 'react';
import './splitscreentemplate.scss';

type Props = {
  ratio: number;
  leftSlot: ReactNode;
  rightSlot: ReactNode;
};

const SplitScreenTemplate = ({ ratio, leftSlot, rightSlot }: Props) => {
  return (
    <div className="split-screen is-full-height columns is-gapless">
      <div style={{ flexBasis: `${ratio}%` }} className="column is-flex-centered">
        {leftSlot}
      </div>
      <div
        style={{ flexBasis: `${100 - ratio}%` }}
        className="column has-left-separator is-scrollable"
      >
        {rightSlot}
      </div>
    </div>
  );
};

export default SplitScreenTemplate;
