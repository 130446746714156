import React, { ChangeEvent } from 'react';
import './input.scss';

type Props = {
  label: string;
  value: string | number;
  min?: number;
  max?: number;
  unit?: string;
  classes?: string;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputField = ({ label, value, min, max, unit, classes, onInputChange }: Props) => {
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (max && parseInt(e.target.value) > max) {
      e.target.value = max.toString();
    }
    if (min !== undefined && parseInt(e.target.value) < min) {
      e.target.value = min.toString();
    }
    onInputChange && onInputChange(e);
  };
  return (
    <div className={`field principal-field ${classes}`}>
      <div className="field-label is-normal">
        <label className="label label-input">{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <p className="control is-expanded">
            <input
              className="input"
              value={value}
              {...(onInputChange === undefined && { disabled: true })}
              onChange={(e) => onInputChange && inputChange(e)}
              type={min !== undefined ? 'number' : 'text'}
              min={min}
              max={max}
            />
          </p>
        </div>
        {unit && <div className="unit">{unit}</div>}
      </div>
    </div>
  );
};

export default InputField;
