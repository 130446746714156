import * as actionTypes from 'store/actions/session';
import { dataContextInitialState } from 'store/reducers/dataContext';

export const initialState: Session = {
  dataContext: dataContextInitialState,
  loggedIn: false,
};

export const sessionReducer = (
  state: Session = initialState,
  action: SessionAction | ContextAction
): Session => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return (action as SessionAction).session;
    case actionTypes.CHANGE_PV_CONTEXT:
      return {
        loggedIn: true,
        dataContext: {
          ...state.dataContext,
          pvPlannedLoadIds: state.dataContext.pvPlannedLoadIds.map((process) =>
            process.sensor === (action as ContextAction).pvPlanned.sensor
              ? {
                  processing: (action as ContextAction).pvPlanned.processing,
                  sensor: process.sensor,
                }
              : process
          ),
          pvLoadCapacityModelIds: state.dataContext.pvLoadCapacityModelIds.map((process) =>
            process.sensor === (action as ContextAction).pvCapacities.sensor
              ? {
                  processing: (action as ContextAction).pvCapacities.processing,
                  sensor: process.sensor,
                }
              : process
          ),
        },
      };
    case actionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
