import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isModalOpen: boolean;
  title?: string;
  footer?: ReactNode;
  modalSize?: { width?: string; height?: string; maxHeight?: string; maxWidth?: string };
  showCloseButton?: boolean;
  setIsModalOpen: (value: boolean) => void;
};

const Modal = ({
  children,
  isModalOpen,
  setIsModalOpen,
  title,
  footer,
  showCloseButton = false,
  modalSize = { width: '80%', height: '80%' },
}: Props) => {
  return (
    <>
      <div className={`modal ${isModalOpen && ' is-active'}`}>
        <div
          className="modal-background"
          onClick={() => {
            !showCloseButton && setIsModalOpen(false);
          }}
        ></div>
        <div
          className="modal-card"
          style={{
            maxHeight: `${modalSize.maxHeight}`,
            maxWidth: `${modalSize.maxWidth}`,
            height: `${modalSize.height}`,
            width: `${modalSize.width}`,
          }}
        >
          {(showCloseButton || title) && (
            <header className="modal-card-head">
              {title && <p className="modal-card-title">{title}</p>}
              {showCloseButton && (
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></button>
              )}
            </header>
          )}
          <div className={`modal-card-body ${!title && !showCloseButton && 'graph'}`}>
            {children}
          </div>
          {footer && <footer className="modal-card-foot">{footer}</footer>}
        </div>
      </div>
    </>
  );
};

export default Modal;
