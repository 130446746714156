import { clone, cloneDeep, max, merge, min } from 'lodash';

import { emptyLayout, graphDefaultLayout, radarComplementaryLayout } from 'appConstants';
import variables from 'assets/styles/partials/exports/_variables.module.scss';
import { roundOneDigit } from './general';

type axisRange = {
  min?: number;
  max?: number;
  delta?: number;
};

type Rect = {
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
};

interface ShapesAttributes {
  xRange?: axisRange;
  yRange?: axisRange;
  rect?: Rect | null;
}

export const generateLayout = (
  translationKey: string | null,
  isFeeder?: boolean,
  nbFeeder?: number
): Partial<Plotly.Layout> => {
  const layout: Partial<Plotly.Layout> = graphDefaultLayout;
  const shapesAttributes: ShapesAttributes = {};

  /* Specify limits to add and axis range restrictions for each graph */
  switch (translationKey) {
    case 'graphs.voltage_duration_curve':
      shapesAttributes.xRange = { delta: 10 };
      return {
        ...addShapes(layout, shapesAttributes),
        xaxis2: { overlaying: 'x', range: [0, 2], showticklabels: false, zeroline: false },
      };
    case 'graphs.voltage_distribution':
      shapesAttributes.rect = !isFeeder && nbFeeder ? { x0: -0.5, x1: 1 / nbFeeder + 0.2 } : null;
      return {
        ...addShapes(layout, shapesAttributes),
        boxmode: 'group',
        xaxis2: { overlaying: 'x', range: [0, 2], showticklabels: false, zeroline: false },
      };
    case 'graphs.imbalance_current_radar':
      return merge(cloneDeep(layout), cloneDeep(radarComplementaryLayout));
    case 'graphs.load_distribution':
      shapesAttributes.rect = !isFeeder && nbFeeder ? { x0: -0.5, x1: 1 / nbFeeder + 0.2 } : null;
      return {
        ...addShapes(layout, shapesAttributes),
        boxmode: 'group',
        xaxis2: {
          overlaying: 'x',
          range: [0, 2],
          showticklabels: false,
          zeroline: false,
        },
      };
    case 'graphs.load_duration_curve':
      return {
        ...addShapes(layout, shapesAttributes),
        xaxis2: { overlaying: 'x', range: [0, 2], showticklabels: false, zeroline: false },
      };
    case 'graphs.topology':
      return {
        ...emptyLayout,
        legend: { orientation: 'h', yanchor: 'bottom' },
      };
    default:
      return emptyLayout;
  }
};

const threePhaseLegend = ['graphs.phase_1', 'graphs.phase_2', 'graphs.phase_3'];
const phasesWithSumLegend = [...threePhaseLegend, 'graphs.sum'];
const phaseWithLimitsVoltage = [
  ...threePhaseLegend,
  'graphs.limit_voltage',
  'graphs.limit_medium_voltage',
];
const phaseWithLimitsLoad = [
  ...phasesWithSumLegend,
  'graphs.limit_load_third',
  'graphs.limit_load',
];

export const getGraphDataLegend = (graphKey: string) => {
  /* Specify limits to add and axis range restrictions for each graph */
  switch (graphKey) {
    case 'graphs.voltage_duration_curve':
    case 'graphs.voltage_distribution':
      return phaseWithLimitsVoltage;
    case 'graphs.load_distribution':
    case 'graphs.load_duration_curve':
      return phaseWithLimitsLoad;
    default:
      return [];
  }
};

export const addShapes = (
  layout: Partial<Plotly.Layout>,
  attributes: ShapesAttributes
): Partial<Plotly.Layout> => {
  const { xRange, yRange, rect } = attributes;
  const xRangeDefined = xRange?.min !== undefined && xRange?.max !== undefined;
  const yRangeDefined = yRange?.min !== undefined && yRange?.max !== undefined;
  const shapes: Partial<Plotly.Shape>[] = [];

  if (rect) {
    shapes.push({
      type: 'rect',
      xref: 'x',
      yref: 'paper',
      x0: rect.x0,
      y0: rect.y0 ?? 0,
      x1: rect.x1,
      y1: rect.y1 ?? 1,
      fillcolor: 'blue',
      opacity: 0.08,
      line: {
        width: 0,
      },
    });
  }

  return {
    ...cloneDeep(layout),
    shapes,
    ...(xRange && {
      xaxis: {
        ...graphDefaultLayout.xaxis,
        range: xRangeDefined ? [xRange.min, xRange.max] : [],
        dtick: xRange.delta,
        autorange: !xRangeDefined,
      },
    }),
    ...(yRange && {
      yaxis: {
        ...graphDefaultLayout.yaxis,
        range: yRangeDefined ? [yRange.min, yRange.max] : [],
        dtick: yRange.delta,
        autorange: !yRangeDefined,
      },
    }),
  };
};

export const filterBoxplotDataByAbcissName = (
  boxplotData: Plotly.Data[],
  name: string,
  graphDataKey?: string
) => {
  return boxplotData?.map((data) => {
    if (data.type === 'scatter' && graphDataKey === 'voltageDistributionBoxplot') return data;
    const newData = data as Partial<Plotly.BoxPlotData>;
    const indexesToSlice: number[] = [];
    newData.text = 'isFeeder';
    const xAxis = (newData.x as string[])?.filter((abcissName: string, index: number) => {
      if (abcissName === name) {
        indexesToSlice.push(index);
        return true;
      }
    });
    const yAxis = (newData.y as number[])?.filter((_data: number, index: number) => {
      if (indexesToSlice.includes(index)) {
        return true;
      }
    });
    newData.x = xAxis;
    newData.y = yAxis;
    return newData;
  });
};

export const getBoxplotAbcissLegend = (
  boxplotElements: QuantileElement[],
  secSub: { name: string; identifier: string },
  feeders?: SecondarySubstationFeeder[]
) => {
  return boxplotElements
    .map((el) => {
      if (el.sensor === secSub.identifier) return Array(6).fill(secSub.name);
      else return Array(6).fill(feeders?.find((fd) => fd.identifier === el.sensor)?.name);
    })
    .flat();
};

export const getBoxplotValues = (boxplotElements: QuantileElement[]) => {
  return boxplotElements
    .map((el) => {
      return [
        roundOneDigit(el.minimum),
        roundOneDigit(el.quartile1),
        roundOneDigit(el.median),
        roundOneDigit(el.median),
        roundOneDigit(el.quartile3),
        roundOneDigit(el.maximum),
      ];
    })
    .flat();
};

export const sortBoxplotBySensors = (boxplotElements: QuantileElement[], sensorsIds: string[]) => {
  const sortedBoxplotElements = clone(boxplotElements);
  sensorsIds.forEach((sensorId, index) => {
    const foundSensor = boxplotElements.find((el) => el.sensor === sensorId);
    if (foundSensor) {
      sortedBoxplotElements.splice(index === 0 ? 0 : index, 1, foundSensor);
    }
  });
  return sortedBoxplotElements;
};

export const updateTranslationLayout = (
  layout: Partial<Plotly.Layout>,
  title: string,
  xaxis: string,
  yaxis: string,
  noDataAnnotation: string | null,
  polarTickTextArr: string[] | null
): Partial<Plotly.Layout> => {
  const newLayout: Partial<Plotly.Layout> = {
    ...layout,
    title: {
      ...(layout.title as object),
      text: title,
    },
    xaxis: {
      ...(layout.xaxis as object),
      title: {
        ...(layout.xaxis?.title as object),
        text: xaxis,
      },
    },
    yaxis: {
      ...(layout.yaxis as object),
      title: {
        ...(layout.yaxis?.title as object),
        text: yaxis,
      },
    },
    annotations: noDataAnnotation
      ? [
          {
            text: noDataAnnotation,
            xref: 'paper',
            yref: 'paper',
            showarrow: false,
            font: {
              size: 20,
            },
          },
        ]
      : undefined,
    ...(polarTickTextArr && {
      polar: {
        ...layout.polar,
        angularaxis: {
          ...layout.polar?.angularaxis,
          ticktext: polarTickTextArr,
        },
      },
    }),
  };
  return newLayout;
};

export const formatPolygonsCoordinates = (value: MultiPolygon | GeometryCollection | Polygon) => {
  switch (value.type) {
    case 'MultiPolygon':
      return (value as MultiPolygon).coordinates;
    case 'GeometryCollection':
      return (value as GeometryCollection).geometries.map((el: Geometry) => {
        if (el.type === 'LineString') return [(el as LineString).coordinates];
        return (el as Polygon).coordinates;
      });
    default:
      /* it is a single Polygon */
      return [(value as Polygon).coordinates];
  }
};

/* Post process of the feeders coordinates: 
- separate the feeders according to the max shift to avoid overlapping
- add smartmeters that do not have 'parent' relations */
const processFeedersCoordinates = (
  nodesCoordinatesByFeeder: Map<string, Map<string, [number, number]>>,
  feeders: SecondarySubstationFeeder[],
  infoFeeders: Map<string, { minShiftLeft: number; minShiftRight: number; center: number }>,
  topology: SimplifiedTopologyRow[]
): [Map<string, Map<string, [number, number]>>, [number, number, string][]] => {
  const newNodesCoordinatesByFeeder = clone(nodesCoordinatesByFeeder);
  let nextXPosition = 0;
  const coordinatesFeeders: [number, number, string][] = [];
  const xPositionMinMaxFeeder: Map<string, [number, number]> = new Map();
  /* We look at the shifts for the different feeders and change x position 
  according to the max shift that happened to the feeder*/
  feeders.forEach((feeder) => {
    const center = infoFeeders.get(feeder.identifier)?.center as number;
    const minShiftLeft = infoFeeders.get(feeder.identifier)?.minShiftLeft as number;
    const minShiftRight = infoFeeders.get(feeder.identifier)?.minShiftRight as number;
    let newCenter = nextXPosition + minShiftLeft;
    const widthFeeder = minShiftLeft + minShiftRight;
    xPositionMinMaxFeeder.set(feeder.identifier, [
      nextXPosition - 0.75,
      nextXPosition + widthFeeder - 0.25,
    ]);
    nextXPosition += widthFeeder + 0.5;

    const feederElements = newNodesCoordinatesByFeeder.get(feeder.identifier);
    feederElements?.forEach((v, k) => {
      feederElements.set(k, [v[0] - center + newCenter, v[1]]);
    });
    const feederData = feederElements?.get(feeder.identifier + '_fd');
    if (feederData) coordinatesFeeders.push([...feederData, feeder.name]);
    const feederTopologyWithoutParent = topology.filter(
      (topoRow) => topoRow.feeder === feeder.identifier && !topoRow.parentSensor
    );

    /* Add smartmeters without 'parent' node at the end in a line */
    feederTopologyWithoutParent.forEach((topoEl) => {
      const feederInfos = infoFeeders.get(feeder.identifier);
      let distMax;
      if (!feederInfos) {
        const feederWidth = 1;
        newCenter = nextXPosition;
        xPositionMinMaxFeeder.set(feeder.identifier, [
          nextXPosition - 0.75,
          nextXPosition + widthFeeder - 0.25,
        ]);
        nextXPosition += feederWidth + 0.5;
        infoFeeders.set(feeder.identifier, {
          center: newCenter,
          minShiftRight: 0,
          minShiftLeft: 0,
        });
        newNodesCoordinatesByFeeder.set(feeder.identifier, new Map());
        newNodesCoordinatesByFeeder
          .get(feeder.identifier)
          ?.set(feeder.identifier, [newCenter, -0.5]);
        coordinatesFeeders.push([newCenter, -0.5, feeder.name]);
        distMax = -1;
      } else {
        /* Find dist max on the center branch of the feeder to add the smartmeters at the end */
        const arrayFeederElements = (feederElements && Array.from(feederElements.values())) ?? [];
        distMax =
          (min(
            arrayFeederElements.filter((el) => el[0] === newCenter).map((el) => el[1])
          ) as number) - 1;
      }
      feederElements?.set(topoEl.sensor, [newCenter, distMax]);
    });
  });
  return [newNodesCoordinatesByFeeder, coordinatesFeeders];
};

const addFeedersLine = (data: Plotly.Data[], coordFeeders: [number, number, string][]) => {
  const xFeeders: number[] = [];
  coordFeeders.forEach((el, index) => {
    const nextFeederIndex = index + 1;
    xFeeders.push(el[0]);
    if (nextFeederIndex < coordFeeders.length) {
      data.push(
        getPlotlyDataFeederLine(
          el[0],
          el[1],
          el[2],
          coordFeeders[nextFeederIndex][0],
          coordFeeders[nextFeederIndex][1]
        )
      );
    } else if (nextFeederIndex === coordFeeders.length || coordFeeders.length === 1) {
      data.push(getPlotlyDataFeederLine(el[0], el[1], el[2]));
    }
  });
};

const getPlotlyDataFeederLine = (
  x1: number,
  y1: number,
  name?: string,
  x2?: number,
  y2?: number
): Plotly.Data => ({
  mode: 'lines',
  type: 'scatter',
  showlegend: false,
  x: x2 ? [x1, x2] : [x1],
  y: y2 ? [y1, y2] : [y1],
  visible: true,
  line: { color: variables.feeder, width: 4 },
  name,
  hoverinfo: 'name',
  text: 'feeders',
});

/* Plot smartmeters as circle, the color correspond to its phase  */
const addMarkersTopologySmartmeters = (
  elementsCoordinates: Map<string, [number, number]>,
  smartmeters: SmartMeter[],
  data: Plotly.Data[],
  feederName: string
) => {
  elementsCoordinates.forEach((position, nodeIdentifier) => {
    const smartMeter = smartmeters.find((smartmeter) => smartmeter.identifier === nodeIdentifier);
    if (smartMeter) {
      /* TODO add in this function if three phases */
      data.push({
        mode: 'markers',
        type: 'scatter',
        x: [position[0]],
        y: [position[1]],
        name: smartMeter.phase1
          ? smartMeter.phase1 === '4'
            ? 'Phase 1-2-3'
            : 'Phase ' + smartMeter.phase1
          : '',
        text: feederName,
        showlegend: false,
        visible: true,
        marker: {
          color: variables[smartMeter.phase1 ? `phase${smartMeter.phase1}` : 'unknown'],
          size: 12,
        },
        hovertemplate: smartMeter.name,
      });
    }
  });
};

/* Plot lines between the different nodes elements for all feeders*/
const addPlotTopologyLines = (
  nodeLines: [string, string][],
  coordinatesByFeeder: Map<string, [number, number]>,
  data: Plotly.Data[],
  feederName: string
) => {
  let firstElement = true;
  nodeLines.forEach((line) => {
    const coordinatesFirstEl = coordinatesByFeeder.get(line[0]);
    const coordinatesSecondEl = coordinatesByFeeder.get(line[1]);
    if (coordinatesFirstEl && coordinatesSecondEl) {
      data.push({
        mode: 'lines',
        type: 'scatter',
        x: [coordinatesFirstEl[0], coordinatesSecondEl[0]],
        y: [coordinatesFirstEl[1], coordinatesSecondEl[1]],
        text: firstElement ? 'feeders' : feederName,
        visible: true,
        showlegend: false,
        hoverinfo: 'skip',
        line: { color: variables.feeder },
      });
      if (firstElement)
        data.push({
          mode: 'markers',
          type: 'scatter',
          x: [coordinatesSecondEl[0]],
          y: [coordinatesSecondEl[1]],
          visible: false,
          name: 'feeders',
          text: feederName,
          hoverinfo: 'skip',
          marker: { color: variables.feeder, symbol: 'triangle-down' },
        });
      firstElement = false;
    }
  });
};

/* Recursive function that looks at children of a node, a node can be a smartmeter or simply a
junction in the topology, the shifts are always to the same side for one feeder (when possible)  */
export const computeTreeNodeCoordinates = (
  feederNodeCoordinates: Map<string, [number, number]> | undefined,
  previousNode: string,
  distanceSecSub: number,
  xPositionFeeder: number,
  shift: number,
  side: -1 | 0 | 1 /* -1 is left, 0 is none and 1 is right*/,
  minShiftRight: number,
  minShiftLeft: number,
  nodesLines: [string, string][],
  feederId: string,
  topologyFeeder: SimplifiedTopologyRow[],
  maxDistance: number
): [number, number, number] => {
  const smartMeterChildren = topologyFeeder.filter(
    (topoRow) => topoRow.parentSensor === previousNode
  );
  const realMaxDistance = min([distanceSecSub, maxDistance]) as number;
  let actualNode: string;
  let newPreviousNode: string;
  let allShifts;
  const nbChildrenNodes = smartMeterChildren.length;
  const shifts = Array.from({ length: nbChildrenNodes }, (_, i) => i + shift);
  const yDistance = -1;

  /* Handles the shifts */
  smartMeterChildren.forEach((smartMeter, index) => {
    let nodeShift = shifts[index];
    if (nbChildrenNodes > 1) {
      if (nbChildrenNodes > 1) {
        if (side === 1 && nodeShift <= minShiftRight) {
          nodeShift = minShiftRight + 1;
        } else if (side === -1 && nodeShift <= minShiftLeft) {
          nodeShift = minShiftLeft + 1;
        }
      }
    }
    const xPositionShift = nodeShift * side;
    actualNode = smartMeter.sensor;
    feederNodeCoordinates?.set(actualNode, [
      xPositionFeeder + xPositionShift,
      distanceSecSub + yDistance,
    ]);
    nodesLines.push([previousNode, actualNode]);
    newPreviousNode = actualNode;

    [allShifts, minShiftRight, minShiftLeft] = computeTreeNodeCoordinates(
      feederNodeCoordinates,
      newPreviousNode,
      distanceSecSub + yDistance,
      xPositionFeeder,
      nodeShift,
      side,
      minShiftRight,
      minShiftLeft,
      nodesLines,
      feederId,
      topologyFeeder,
      realMaxDistance
    );
    if (nbChildrenNodes > 1) {
      if (side === 1) minShiftRight = max([allShifts, minShiftRight]) as number;
      else minShiftLeft = max([allShifts, minShiftLeft]) as number;
      if (shift === 0) side *= -1;
    }
  });
  /* We return the max shift for a feeder (to the left or right), it will be used during 
  preprocessing to space out the feeders*/
  return [shift, minShiftRight, minShiftLeft];
};

export const getTopologyTreeData = (
  feeders: SecondarySubstationFeeder[],
  infoFeeders: Map<string, { minShiftLeft: number; minShiftRight: number; center: number }>,
  nodesCoordinatesByFeeder: Map<string, Map<string, [number, number]>>,
  nodesLines: Map<string, [string, string][]>,
  smartmeters: SmartMeter[],
  topology: SimplifiedTopologyRow[]
) => {
  const linesAndMarkers: Plotly.Data[] = [];
  const [newNodesCoordinatesByFeeder, coordinatesFeeders] = processFeedersCoordinates(
    nodesCoordinatesByFeeder,
    feeders,
    infoFeeders,
    topology
  );
  addFeedersLine(linesAndMarkers, coordinatesFeeders);

  feeders.forEach((feeder) => {
    const feederNodeLines = nodesLines.get(feeder.identifier);
    const feederElementCoordinates = newNodesCoordinatesByFeeder.get(feeder.identifier);

    if (feederNodeLines && feederElementCoordinates) {
      addPlotTopologyLines(feederNodeLines, feederElementCoordinates, linesAndMarkers, feeder.name);
      addMarkersTopologySmartmeters(
        feederElementCoordinates,
        smartmeters,
        linesAndMarkers,
        feeder.name
      );
    }
  });
  return linesAndMarkers;
};

export const addGraphLimits = (
  graphDataKey: string,
  isFeeder: boolean | undefined,
  limitMax: number | null | undefined,
  data: Plotly.Data[] | null
) => {
  const limits: number[] = [];
  const meanVoltage = 230; // api limit +-10%
  if (
    ['loadDistributionBoxplot', 'loadDurationCurve'].includes(graphDataKey) &&
    !isFeeder &&
    limitMax
  ) {
    limits.push(...[limitMax / 3, limitMax]);
  } else if (['voltageDistributionBoxplot', 'voltageDurationCurve'].includes(graphDataKey)) {
    limits.push(...[meanVoltage + meanVoltage * 0.1, meanVoltage, meanVoltage - meanVoltage * 0.1]);
  }

  limits.forEach((limit, index) =>
    data?.push({
      mode: 'lines',
      type: 'scatter',
      marker: { color: 'red' },
      line: { dash: [0, 2].includes(index) ? 'dash' : 'solid' },
      showlegend: index === 2 ? false : true,
      x: [0, 2],
      xaxis: 'x2',
      y: [limit, limit],
      hovertemplate: `%{y:.1f}${
        ['voltageDistributionBoxplot', 'voltageDurationCurve'].includes(graphDataKey) ? 'V' : 'kW'
      }<extra></extra>`,
    })
  );
};
