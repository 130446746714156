import React, { ReactNode, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Carousel } from 'components/atoms';
import { TypeCloseTabs } from 'appConstants';
import {
  pxIconWidth,
  carouselButtonsWidth,
  marginWidth,
  tabsPaddingIconAndCloseWidth,
  pxMinWidthTab,
} from 'appConstants';

import './tabs.scss';

type Props = {
  tabClasses?: string;
  leftIcon?: ReactNode;
  activeTabIndex?: number;
  removeTabs?: ((identifiers: string[]) => void) | null;
  openTab?: (identifier: string) => void;
  tabArray: { name: string; identifier: string }[];
  isScrollable?: boolean;
};

const Tabs = ({
  tabClasses = 'tab-primary',
  openTab = undefined,
  removeTabs = null,
  activeTabIndex = 0,
  leftIcon = null,
  tabArray,
  isScrollable = true,
}: Props) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex);
  const [isVisible, setIsVisible] = useState(false);
  const [xContextMenu, setXContextMenu] = useState('0px');
  const [yContextMenu, setYContextMenu] = useState('0px');
  const [tabContextMenu, setTabContextMenu] = useState<string>();
  const [previousActive, setPreviousActive] = useState(activeTabIndex);

  const [splitScreenWidth, setSplitScreenWidth] = useState(0);
  const [nbTabDisplayedCar, setNbTabDisplayedCar] = useState(0);
  const [isCarouselActive, setIsCarouselActive] = useState(false);
  const [navigationIndexCar, setNavigationIndexCar] = useState(0);
  const [styleWidthTxt, setStyleWidthTxt] = useState('0px');

  const splitScreenRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const getActiveTab = useMemo(() => {
    return openTab ? activeTabIndex : activeTab;
  }, [activeTab, activeTabIndex, openTab]);

  const onOpenTab = (index: number, identifier: string) => {
    openTab ? openTab(identifier) : setActiveTab(index);
  };

  /* No use case actions for a secondarySubstation with no topology */
  const handleContextMenu = useCallback(
    (event: React.MouseEvent, tabIdentifier: string) => {
      event.preventDefault();
      if (removeTabs) {
        setIsVisible(true);
        setXContextMenu(`${event.pageX}px`);
        setYContextMenu(`${event.pageY}px`);
        setTabContextMenu(tabIdentifier);
      } else {
        setIsVisible(false);
      }
    },
    [removeTabs]
  );

  const closeContextMenu = useCallback(() => {
    setIsVisible(false);
    setTabContextMenu('');
  }, []);

  useEffect(() => {
    document.addEventListener('click', closeContextMenu);
    return () => {
      document.removeEventListener('click', closeContextMenu);
    };
  }, [closeContextMenu]);

  const removeTabContextMenu = useCallback(
    (closeType: TypeCloseTabs) => {
      if (removeTabs) {
        let arrayTabs = tabArray.map((secSub) => secSub?.identifier);
        if (closeType === TypeCloseTabs.Inactive) {
          arrayTabs = arrayTabs.filter((_, index) => index !== getActiveTab);
        } else if (closeType === TypeCloseTabs.Other) {
          arrayTabs = arrayTabs.filter((identifier) => identifier !== tabContextMenu);
        }
        removeTabs(arrayTabs);
      }
    },
    [getActiveTab, removeTabs, tabArray, tabContextMenu]
  );

  useEffect(() => {
    setActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  useEffect(() => {
    const handleResize = () => {
      setSplitScreenWidth((splitScreenRef?.current as HTMLDivElement)?.clientWidth);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (splitScreenRef.current) {
      setSplitScreenWidth(splitScreenRef.current.clientWidth);
      setNbTabDisplayedCar(
        Math.floor(
          (splitScreenRef.current.clientWidth - (carouselButtonsWidth + marginWidth)) /
            pxMinWidthTab
        )
      );
    }
  }, [splitScreenWidth, nbTabDisplayedCar, isCarouselActive]);

  useEffect(() => {
    setIsCarouselActive(!(isScrollable || tabArray.length <= nbTabDisplayedCar));
  }, [nbTabDisplayedCar, isScrollable, tabArray, isCarouselActive]);

  useEffect(() => {
    // index move to always show the active tabs
    if (isCarouselActive && previousActive !== activeTabIndex) {
      if (
        navigationIndexCar > activeTabIndex ||
        navigationIndexCar + nbTabDisplayedCar <= activeTabIndex
      ) {
        if (activeTabIndex + nbTabDisplayedCar > tabArray.length) {
          setNavigationIndexCar(tabArray.length - nbTabDisplayedCar);
        } else {
          setNavigationIndexCar(activeTabIndex);
        }
      }
      setPreviousActive(activeTabIndex);
    }
  }, [
    activeTabIndex,
    isCarouselActive,
    navigationIndexCar,
    nbTabDisplayedCar,
    previousActive,
    tabArray.length,
  ]);

  useEffect(() => {
    // index move back if it's too hight- foward if's too low
    if (isCarouselActive) {
      if (navigationIndexCar > tabArray.length - nbTabDisplayedCar) {
        setNavigationIndexCar(tabArray.length - nbTabDisplayedCar);
      }
      if (navigationIndexCar < 0) {
        setNavigationIndexCar(0);
      }
    }
  }, [isCarouselActive, navigationIndexCar, nbTabDisplayedCar, tabArray.length]);

  const handleClick = (isLeftArrow: boolean) => {
    isLeftArrow
      ? setNavigationIndexCar((prev) => prev - 1)
      : setNavigationIndexCar((prev) => prev + 1);
  };

  const getClassNotDisplayed = (indexTab: number) => {
    if (
      isCarouselActive &&
      (indexTab < navigationIndexCar || indexTab >= navigationIndexCar + nbTabDisplayedCar)
    ) {
      return 'is-not-displayed';
    }
    return '';
  };

  const getIsLeftCarouselDisable = () => {
    return navigationIndexCar === 0;
  };
  const getIsRightCarouselDisable = () => {
    return navigationIndexCar + nbTabDisplayedCar >= tabArray.length;
  };

  useEffect(() => {
    if (isScrollable) {
      setStyleWidthTxt('auto');
    } else if (isCarouselActive) {
      setStyleWidthTxt(
        Math.floor((splitScreenWidth - (marginWidth + carouselButtonsWidth)) / nbTabDisplayedCar) -
          tabsPaddingIconAndCloseWidth +
          'px'
      );
    } else {
      setStyleWidthTxt(
        Math.floor((splitScreenWidth - marginWidth) / nbTabDisplayedCar) -
          tabsPaddingIconAndCloseWidth +
          'px'
      );
    }
  }, [isCarouselActive, isScrollable, nbTabDisplayedCar, splitScreenWidth]);

  return (
    <div className={`${tabClasses}`} ref={splitScreenRef}>
      <div
        className={`tab-panel tabs ${isScrollable ? '' : 'is-not-scrollable'} `}
        style={{ marginBottom: 0 }}
      >
        <Carousel
          isDisplayed={isCarouselActive}
          isDisabled={getIsLeftCarouselDisable()}
          isDirectionLeft={true}
          onClick={() => {
            handleClick(true);
          }}
        />

        <ul
          className={`tab-list${isCarouselActive ? ' is-carousel-active' : ''}
              `}
          style={{ border: 'unset' }}
        >
          {tabArray.map((tab, index) => {
            const key = `${tab.name}-${index}`;
            const aClasses = `
            tab-button tab-title${getActiveTab === index ? ' is-active' : ''} ${
              removeTabs ? ' has-icon' : ''
            }}
                `;

            return (
              <li
                className={`mr-1bis ${getClassNotDisplayed(index)}`}
                key={key}
                onContextMenu={(e) => handleContextMenu(e, tab.identifier)}
              >
                <button className={aClasses} onClick={() => onOpenTab(index, tab.identifier)}>
                  <div className="tab-box">
                    {leftIcon}
                    <div className="is-flex-direction-column">
                      <div
                        className="tab-txt"
                        title={isScrollable ? '' : tab.name}
                        style={{ width: styleWidthTxt }}
                      >
                        {tab.name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`underline${getActiveTab === index ? ' underline-active' : ''}`}
                  ></div>

                  {removeTabs && (
                    <span
                      className="icon is-full-height clickable"
                      onClick={(event) => {
                        event.stopPropagation();
                        removeTabs([tab.identifier]);
                      }}
                    >
                      <span className="icon is-tiny">
                        <Icon name="close" viewBox={'0 0 ' + pxIconWidth + ' ' + pxIconWidth} />
                      </span>
                    </span>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
        <Carousel
          isDisplayed={isCarouselActive}
          isDisabled={getIsRightCarouselDisable()}
          isDirectionLeft={false}
          onClick={() => {
            handleClick(false);
          }}
        />
      </div>

      {removeTabs && (
        <div
          className={`context-menu ${isVisible && 'is-visible'}`}
          style={{ top: yContextMenu, left: xContextMenu }}
        >
          {[TypeCloseTabs.All, TypeCloseTabs.Inactive, TypeCloseTabs.Other].map((closeType) => {
            return (
              <div
                onClick={() => removeTabContextMenu(closeType)}
                className="context-menu-item"
                key={closeType}
              >
                {t(`tabs.close_${closeType}`)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Tabs;
