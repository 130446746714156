import { OpenStreetMapProvider } from 'leaflet-geosearch';

const provider = new OpenStreetMapProvider({
  params: {
    // TODO get this countrycodes from API when available
    countrycodes: 'fr',
  },
});

export const findLocation = async (nameSearch: string): Promise<[number, number] | undefined> => {
  const results = await provider.search({ query: nameSearch });
  if (results.length) {
    return [results[0].y, results[0].x];
  }
};

export const findItemBy = <T>(key: keyof T, collection: Array<T>, searchText: string) => {
  const result = collection.find((item) => {
    const value = item[key];
    return typeof value === 'string' ? value.toLowerCase() === searchText.toLowerCase() : false;
  });
  if (result) {
    return result;
  }
};
