import { LatLngTuple, setOptions } from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

type Props = {
  center: LatLngTuple;
  initialZoom?: number;
};
const ChangeCenter = ({ center, initialZoom }: Props) => {
  const map = useMap();

  /* useMap is required to change map view but cannot be used outside MapContainer */
  useEffect(() => {
    const currentZoom = map.getZoom() ? map.getZoom() : initialZoom;
    map.setView(center, currentZoom);
    setOptions(map, { center });
  }, [center, initialZoom, map]);
  return null;
};

export default ChangeCenter;
