import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { Icon, Logo } from 'components/atoms';
import { Navigation, Languages, Dropdown } from 'components/molecules';

import odtLogo from 'assets/img/corporate/logo-sagemcom.png';

import './header.scss';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const [username, setUsername] = useState<string>();

  const tagVersion =
    process.env.REACT_APP_ODT_PRODUCT_TAG ?? window.SERVER_DATA.REACT_APP_ODT_PRODUCT_TAG;

  useEffect(() => {
    keycloak.loadUserProfile().then((info) => {
      if (info && info.username) setUsername(info.username);
    });
  }, [keycloak.authenticated, keycloak]);

  const onLogOut = () => {
    keycloak.logout();
  };

  return (
    <nav
      className="header is-flex is-justify-content-space-between"
      role="navigation"
      aria-label="dropdown navigation"
    >
      <div className="is-flex is-justify-content-flex-start logo-container">
        <div className="is-flex-centered  ml-5 is-full-height">
          <Logo logo={odtLogo} />
        </div>
      </div>

      {keycloak.authenticated && <Navigation />}

      <div className="is-flex is-justify-content-center is-align-items-center">
        <Dropdown
          dropdownTrigger={
            <>
              <span className="is-flex ml-1 is-size-7 is-grey is-hidden-touch">
                {t('language', { lng: i18n.resolvedLanguage })}
              </span>
              <span className="is-flex ml-3">
                <Icon name={`flag-${i18n.resolvedLanguage}`} />
              </span>
            </>
          }
          dropdownMenu={
            <Languages
              linkClasses={'navbar-link language-items is-arrowless'}
              textClasses={'is-flex is-size-7 is-grey'}
              iconVisible={true}
              iconClasses={'is-flex'}
              inclusive={false}
            />
          }
        />

        {keycloak?.authenticated && (
          <Dropdown
            dropdownTrigger={
              <>
                <span className="is-flex ml-1 is-size-7 is-grey">{username}</span>
              </>
            }
            dropdownMenu={
              <>
                <Link
                  to="/"
                  className="navbar-link is-arrowless pl-2 is-flex ml-1 is-size-7 is-grey"
                  onClick={onLogOut}
                >
                  {t('log_out')}
                </Link>
                <div className="is-hovered pl-2 pr-2 is-flex ml-1 is-size-7  is-hovered">
                  Version:
                  <span className="tag-version">
                    {tagVersion ? <> {tagVersion}</> : <>{t('undefined_version')}</>}
                  </span>
                </div>
              </>
            }
          />
        )}
      </div>
    </nav>
  );
};

export default Header;
