import { latLngBounds } from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

type Props = {
  smartMeters: SmartMeter[];
};
const ChangeView = ({ smartMeters }: Props) => {
  const map = useMap();

  useEffect(() => {
    const markerBounds = latLngBounds([]);
    smartMeters.forEach((sm) => {
      markerBounds.extend([sm.location.coordinates]);
    });
    markerBounds.isValid() && map.fitBounds(markerBounds);
  }, [map, smartMeters]);

  return null;
};

export default ChangeView;
