import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import routes from 'router/routes';
import { useKeycloak } from '@react-keycloak/web';

import { asTemplateString } from 'helpers';
import { useAppSelector } from 'hooks';
import { useCases } from 'appConstants';

const Navigation = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { useCasePermissions } = useAppSelector((state) => state.session.dataContext);

  const classes = {
    ul: 'is-flex-centered is-family-primary has-text-weight-normal ml-6',
    li: 'is-flex-centered mx-2',
  };

  const isLinkEnabled = (link: UseCasePermissions) => {
    return useCasePermissions?.includes(link) ? true : false;
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, navName: UseCasePermissions) => {
    if (!isLinkEnabled(navName)) e.preventDefault();
  };

  const isActive = ({ isActive }: { isActive: boolean }) => {
    let classes = 'is-flex-centered p-2 px-2 nav-link is-size-6bis normal-line-height';
    classes += isActive ? ' button is-primary has-text-weight-bold is-hovered' : '';
    return classes;
  };

  return (
    <ul className={classes.ul}>
      {useCases.map((useCase) => (
        <li
          className={`${classes.li} ${isLinkEnabled(useCase) ? '' : 'has-tooltip'}`}
          key={useCase}
        >
          <NavLink
            to={routes[useCase].path}
            className={isActive}
            onClick={(e) => handleClick(e, useCase)}
          >
            {t(asTemplateString(`${useCase}.navbar`))}
          </NavLink>
          {!isLinkEnabled(useCase) && (
            <div className="tooltip-text bottom-tooltip">
              {keycloak.authenticated ? 'Use case not available' : 'Login required'}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
