/* Default config for Map component */
export const mapDefaultConfig = {
  initialZoom: 13,
  minZoom: 1,
  maxZoom: 18,
  layers: {
    light: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    raster: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png',
    rasterLabels:
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png',
  },
  scrollWheelZoom: false,
};
