import { flatten, orderBy } from 'lodash';
import chroma from 'chroma-js';

import {
  getBoxplotAbcissLegend,
  getBoxplotValues,
  sortBoxplotBySensors,
  convertWtoKw,
  formatPolygonsCoordinates,
  getTopologyTreeData,
  computeTreeNodeCoordinates,
  addGraphLimits,
} from 'helpers';
import { graphAnalytics } from 'appConstants';
import variables from 'assets/styles/partials/exports/_variables.module.scss';

export const formatBoxplotData = (
  quantiles: QuantileElement[],
  sensor: { identifier: string; name: string; power: number },
  feeders: SecondarySubstationFeeder[] | undefined,
  graphDataKey: SecSubGraphDataKey,
  isFeeder?: boolean
) => {
  const graphMetrics = graphAnalytics[graphDataKey].metrics;
  const hasData =
    quantiles.filter((data: QuantileElement) => graphMetrics.includes(data.metric)).length > 0;
  const xValues = [];

  const formattedQuantiles: Plotly.Data[] | null = hasData
    ? Array.from({ length: graphMetrics.length }, (_, index) => {
        let boxplotElements: QuantileElement[] = quantiles.filter(
          (data: QuantileElement) => data.metric === graphMetrics[index]
        );

        if (graphDataKey === 'loadDistributionBoxplot') {
          /* API returns W while we need kW */
          boxplotElements = boxplotElements.map((q: QuantileElement) =>
            convertWtoKw(['quartile1', 'quartile3', 'maximum', 'minimum', 'median'], q)
          ) as QuantileElement[];
        }

        const sortedBoxplotElements = feeders
          ? sortBoxplotBySensors(boxplotElements, [
              sensor.identifier,
              ...feeders.map((fd) => fd.identifier),
            ])
          : boxplotElements;

        const xBoxPlot = getBoxplotAbcissLegend(sortedBoxplotElements, sensor, feeders);
        xValues.push(xBoxPlot);
        return {
          type: 'box',
          visible: true,
          marker: { size: 2 },
          line: { width: 1 },
          boxpoints: false,
          text: (sensor.power / 1000).toString(),
          x: xBoxPlot,
          y: getBoxplotValues(sortedBoxplotElements),
        };
      })
    : null;

  addGraphLimits(graphDataKey, isFeeder, sensor.power / 1000, formattedQuantiles);

  return formattedQuantiles;
};

export const formatRadarData = (radarAnalytics: RadarAnalytic[]): Plotly.Data[] | null => {
  const hasData = radarAnalytics.length > 0;
  const formattedData: Plotly.Data[] | null = hasData
    ? flatten(
        orderBy(radarAnalytics, 'lowerBound').map((radarAnalytic: RadarAnalytic, i: number) =>
          Array.from(flatten(formatPolygonsCoordinates(radarAnalytic.contourValue)), (el) => {
            if (el.length) {
              return {
                type: 'scatterpolar',
                r: el?.map((point: CoordinateData) => point[1]),
                theta: el?.map((point: CoordinateData) => (point[0] * 180) / Math.PI),
                mode: 'none',
                fill: 'toself',
                opacity: 0.8,
                fillcolor: chroma
                  .mix(variables.redHeatMin, variables.redHeatMax, i / radarAnalytics.length, 'hsl')
                  .hex(),
                line: {
                  width: 0,
                },
              };
            } else {
              return {};
            }
          })
        )
      )
    : null;

  return formattedData ? formattedData.filter((el) => el.type) : null;
};

export const formatDurationCurveData = (
  durationCurves: DurationCurveElement[],
  graphDataKey: SecSubGraphDataKey,
  isFeeder?: boolean,
  limitMax?: number | null,
  power?: number
) => {
  const graphMetrics = graphAnalytics[graphDataKey].metrics;
  const hasData =
    durationCurves.filter((data: DurationCurveElement) => graphMetrics.includes(data.metric))
      .length > 0;
  const formattedCurves: Plotly.Data[] | null = hasData
    ? Array.from({ length: graphMetrics.length }, (_, index) => {
        const phaseDurationCurves = durationCurves.filter(
          (data: DurationCurveElement) => data.metric === graphMetrics[index]
        );
        return {
          mode: 'lines',
          type: 'scatter',
          text: graphDataKey === 'loadDurationCurve' && isFeeder ? 'isFeeder' : power?.toString(),
          x: phaseDurationCurves.map((value: DurationCurveElement) => value.percent * 100),
          y: phaseDurationCurves.map((value: DurationCurveElement) =>
            graphDataKey === 'loadDurationCurve' ? value.value / 1000 : value.value
          ),
        };
      })
    : null;

  addGraphLimits(graphDataKey, isFeeder, limitMax, formattedCurves);

  return formattedCurves;
};

export const formatTopologyTreeData = (
  topology: SimplifiedTopologyRow[],
  secondarySubId: string,
  feeders: SecondarySubstationFeeder[],
  smartmeters: SmartMeter[]
): Plotly.Data[] | null => {
  const filteredTopology = topology.filter((row) => !!row.parentSensor);
  const nbFeeders = feeders.length;
  const xAxisFeeder = Array.from({ length: nbFeeders }, (_, i) => i - (nbFeeders - 1) / 2);

  const nodesCoordinatesByFeeder: Map<string, Map<string, [number, number]>> = new Map();
  const nodesLines: Map<string, [string, string][]> = new Map();
  const infoFeeders: Map<string, { minShiftLeft: number; minShiftRight: number; center: number }> =
    new Map();

  feeders.forEach((feeder, feederIndex) => {
    nodesCoordinatesByFeeder.set(feeder.identifier, new Map());
    const feederTopology = filteredTopology.filter(
      (topoRow) => topoRow.feeder === feeder.identifier
    );
    const firstSmartMeter =
      feederTopology.find((topoRow) =>
        [feeder.identifier, secondarySubId].includes(topoRow.parentSensor)
      ) ?? feederTopology[0];
    let previousNode: string = feeder.identifier + '_fd';
    const distanceSecSub = -1;
    const xCurrentFeeder = xAxisFeeder[feederIndex];
    const actualNode: string = firstSmartMeter?.sensor;
    nodesCoordinatesByFeeder.get(feeder.identifier)?.set(previousNode, [xCurrentFeeder, 1]);
    nodesCoordinatesByFeeder
      .get(feeder.identifier)
      ?.set(actualNode, [xCurrentFeeder, distanceSecSub]);
    nodesLines.set(feeder.identifier, [[previousNode, actualNode]]);
    previousNode = actualNode;

    const [, minShiftRight, minShiftLeft] = computeTreeNodeCoordinates(
      nodesCoordinatesByFeeder.get(feeder.identifier),
      actualNode,
      distanceSecSub,
      xCurrentFeeder,
      0,
      1,
      0,
      0,
      nodesLines.get(feeder.identifier) ?? [],
      feeder.identifier,
      filteredTopology,
      distanceSecSub
    );
    infoFeeders.set(feeder.identifier, {
      minShiftLeft,
      minShiftRight,
      center: xCurrentFeeder,
    });
  });
  return getTopologyTreeData(
    feeders,
    infoFeeders,
    nodesCoordinatesByFeeder,
    nodesLines,
    smartmeters,
    topology
  );
};
