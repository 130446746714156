import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'bulma-toast';

import i18n from 'i18n/i18n';
import keycloak from 'keycloack';

// eslint-disable-next-line
const apiBaseUrl = process.env.REACT_APP_API_DATA_ENDPOINT ?? window.SERVER_DATA.REACT_APP_API_DATA_ENDPOINT;

export const headers = {
  'Content-Type': 'application/json',
  'X-Opaque-Data-Format': 'RECORDS',
};

export const headersPostToGet = { ...headers, 'X-HTTP-Method-Override': 'GET' };
// TODO : get token from API
const messages = i18n.options.resources;

export const apiService = {
  setApi: () => {
    return axios.create({
      baseURL: apiBaseUrl,
      headers,
    });
  },
  placeTokenInRequestUrl: (request: AxiosRequestConfig) => {
    const { token } = keycloak;
    if (!token) return;
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
    };
  },
  handleError: (error: AxiosError) => {
    const errorStatus = error?.response?.status ?? error?.message.replace(' ', '_').toLowerCase();
    apiService.displayToast(errorStatus);
    // TODO : redirect user on 401 error

    return Promise.reject(error);
  },
  displayToast: (errorStatus: string | number | undefined) => {
    if (messages && errorStatus !== undefined && errorStatus !== 'canceled') {
      const message = i18n.exists(`request_errors.${errorStatus}`)
        ? i18n.getResource(i18n.resolvedLanguage, 'translation', `request_errors.${errorStatus}`)
        : i18n.getResource(i18n.resolvedLanguage, 'translation', 'request_errors.default');

      toast({
        message,
        type: 'is-danger',
        dismissible: true,
        position: 'bottom-right',
        duration: 3000,
      });
    }
  },
};
