import variables from 'assets/styles/partials/exports/_variables.module.scss';

/* Default layout for Graph component */
export const graphDefaultLayout: Partial<Plotly.Layout> = {
  font: {
    color: variables.greyPrimary,
    family: variables.fontSecondary,
    size: 14,
  },
  title: {
    font: {
      size: 20,
    },
    x: 0.05,
    y: 0.95,
  },
  xaxis: {
    title: {
      font: {
        color: variables.greySecondary,
      },
    },
    tickfont: {
      color: variables.greySecondary,
    },
    autorange: true,
    showgrid: true,
    zeroline: false,
    tickcolor: variables.transparent,
  },
  yaxis: {
    title: {
      font: {
        color: variables.greySecondary,
      },
      standoff: 13,
    },
    tickfont: {
      color: variables.greySecondary,
    },
    autorange: true,
    showgrid: true,
    zeroline: false,
    tickcolor: variables.transparent,
  },
  autosize: true,
  margin: {
    l: 80,
    r: 0,
    b: 90,
    t: 80,
  },
  showlegend: true,
};

/* Default layout for displaying an empty Graph */
export const emptyLayout: Partial<Plotly.Layout> = {
  ...graphDefaultLayout,
  xaxis: { visible: false },
  yaxis: { visible: false },
  showlegend: false,
  margin: {
    l: 5,
    r: 5,
    b: 5,
    t: 50,
  },
};

/* Default layout for Current Radar Graphs */
export const radarComplementaryLayout: Partial<Plotly.Layout> = {
  polar: {
    angularaxis: {
      color: variables.greySecondary,
      gridcolor: variables.greySecondary,
      tickmode: 'array',
      tickvals: [0, (2 * 180) / 3, (4 * 180) / 3],
      tickcolor: variables.greySecondary,
    },
    radialaxis: {
      color: variables.greySecondary,
      gridcolor: variables.greySecondary,
      ticksuffix: ' A',
    },
  },
  showlegend: false,
  xaxis: {
    visible: false,
  },
  yaxis: {
    visible: false,
  },
  margin: {
    l: 30,
    r: 60,
    b: 40,
    t: 90,
  },
};

/* Default config for Graph component */
export const graphDefaultConfig: Partial<Plotly.Config> = {
  displayModeBar: true,
  modeBarButtons: [[]],
  displaylogo: false,
};

export const analyticGraphs: { translationKey: string; field: SecSubGraphDataKeyWithTopology }[] = [
  { translationKey: 'topology', field: 'topologyTree' },
  { translationKey: 'imbalance_current_radar', field: 'imbalanceCurrentRadar' },
  { translationKey: 'voltage_distribution', field: 'voltageDistributionBoxplot' },
  { translationKey: 'load_distribution', field: 'loadDistributionBoxplot' },
  { translationKey: 'voltage_duration_curve', field: 'voltageDurationCurve' },
  { translationKey: 'load_duration_curve', field: 'loadDurationCurve' },
];
