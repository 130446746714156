import { initialState } from 'store/reducers/session';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CHANGE_PV_CONTEXT = 'CHANGE_PV_CONTEXT';
export const LOGOUT = 'LOGOUT';

export const login = (session: Session): SessionAction => {
  return { type: LOGIN_SUCCESS, session };
};

export const changePvContext = (
  pvPlanned: ProcessingSensor,
  pvCapacities: ProcessingSensor
): ContextAction => {
  return { type: CHANGE_PV_CONTEXT, pvPlanned, pvCapacities };
};

export const logout = (): SessionAction => {
  return { type: LOGOUT, session: initialState };
};
