import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LatLngTuple } from 'leaflet';
import { cloneDeep, isEmpty } from 'lodash';

import { SplitColumnTemplate, SplitScreenTemplate } from 'components/templates';
import { SubstationsTabs, LeafletMap, ImpactTable, ImpactModal } from 'components/organisms';
import { ColorLegend, SensorsCluster, Tabs } from 'components/molecules';
import { Icon, Loader } from 'components/atoms';

import {
  useAbortController,
  useAppSelector,
  useSensors,
  useTopology,
  useSubstationsTabList,
  useImpactData,
} from 'hooks';
import { asTemplateString } from 'helpers';
import { initalImpactModalState } from 'appConstants';

import './impact.scss';

const Impact = () => {
  const { t } = useTranslation();
  const {
    mapCenter,
    setMapCenter,
    smMapCenter,
    setSmMapCenter,
    currentSmartMeters,
    currentFeeders,
    isLoading,
    secondarySubstations,
    getSecondarySubstations,
    getSmartMetersBySecSubstation,
  } = useSensors();
  const { datasetId } = useAppSelector((state) => state.session.dataContext);
  const [hoveredSmartmeter, setHoveredSmartmeter] = useState<string>();
  const location = useLocation();
  const { controller } = useAbortController();
  const { topology } = useTopology();
  const [activeSecondarySubstation, setActiveSecondarySubstation] = useState<SecondarySubstation>();

  const resetState = () => {
    setAddValueMap(new Map());
    setAdditionModalState(initalImpactModalState);
  };

  const {
    capacitiesMap,
    isLoadingImpact,
    savedPreviousMax,
    deletePlannedLoad,
    addValueMap,
    setAddValueMap,
    simulatePvImpact,
    setCapacitiesMap,
    normalizedCapacitiesMap,
    tableMaxLoad,
    validatePlannedLoad,
  } = useImpactData({
    activeSecondarySubstation,
    currentSmartMeters,
    resetState,
  });
  const { substationsTabList, setSubstationsTabList, addTab, removeTabs } = useSubstationsTabList({
    secondarySubstations: secondarySubstations ?? [],
    activeSecondarySubstation,
    setActiveSecondarySubstation,
  });

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [additionModalState, setAdditionModalState] = useState<ModalState>(initalImpactModalState);

  const setHoveredSmartmeterById = (smID: string) => {
    const newSM = currentSmartMeters.find((sm) => sm.identifier === smID);
    if (newSM) {
      setHoveredSmartmeter(smID);
    } else {
      setHoveredSmartmeter(undefined);
    }
  };

  useEffect(() => {
    if (location.state) {
      const secondarySubFromNav = secondarySubstations?.find(
        (secondarySub: SecondarySubstation) => secondarySub.identifier === location.state
      );
      if (secondarySubFromNav) {
        setActiveSecondarySubstation(secondarySubFromNav);
        setSubstationsTabList((previousTabs) =>
          !previousTabs.includes(secondarySubFromNav)
            ? [...previousTabs, secondarySubFromNav]
            : previousTabs
        );
      }
    }
  }, [location, secondarySubstations, setSubstationsTabList]);

  const activeButtons = useMemo((): (keyof ImpactButtons)[] | null => {
    return addValueMap.size ? ['waitlist', 'reset'] : null;
  }, [addValueMap.size]);

  const smartMetersWithLocation = useMemo(() => {
    return currentSmartMeters.filter((smartmeter: SmartMeter) => smartmeter.location);
  }, [currentSmartMeters]);

  const smartMetersWithFeeder: SmartMeter[] = useMemo(() => {
    return currentSmartMeters.map((smartMeter: SmartMeter) => {
      const feeder = currentFeeders?.find((feeder) => feeder.identifier === smartMeter.feeder);
      return { ...smartMeter, feeder: feeder ? feeder.name : '' };
    });
  }, [currentSmartMeters, currentFeeders]);

  // TODO: make this calls one element above so it is shared between used case
  useEffect(() => {
    datasetId &&
      !secondarySubstations &&
      !isEmpty(topology) &&
      getSecondarySubstations(datasetId, controller.signal);
  }, [controller.signal, datasetId, getSecondarySubstations, secondarySubstations, topology]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsFullScreen(false);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  }, []);

  /* Each time the active secondary substation is changed */
  useEffect(() => {
    if (activeSecondarySubstation) {
      setAddValueMap(new Map());
      setAdditionModalState(initalImpactModalState);
      getSmartMetersBySecSubstation(
        topology,
        activeSecondarySubstation.identifier,
        datasetId,
        false
      );
    }
  }, [
    activeSecondarySubstation,
    datasetId,
    getSmartMetersBySecSubstation,
    setAddValueMap,
    topology,
  ]);

  const onButtonClick = (buttonKey: keyof ImpactButtons) => {
    if (buttonKey === 'reset') {
      resetState();
      setCapacitiesMap(cloneDeep(savedPreviousMax));
    } else if (buttonKey === 'waitlist' && activeSecondarySubstation) {
      validatePlannedLoad();
    }
  };

  const addOneValueAddMap = (smAddedCapacities: ImpactAddedCapacities) => {
    setAddValueMap((valueMap) => {
      const newMap = new Map(valueMap);
      newMap.set(additionModalState.rowIdentifier, smAddedCapacities);
      return newMap;
    });
  };

  const onAdditionCellClick = (smartMeter: SmartMeter) => {
    smartMeter.location && setSmMapCenter(smartMeter.location.coordinates);
    setAdditionModalState({
      open: true,
      rowIdentifier: smartMeter.identifier,
      rowName: smartMeter.name,
    });
  };

  /* Updating the added value map when closing the modal */
  useEffect(() => {
    if (!additionModalState.open) {
      setAddValueMap((addValueMap) => {
        const newMap = new Map(addValueMap);
        newMap.forEach((impact, smartmeter) => {
          const capacities = capacitiesMap.get(smartmeter);
          if (
            impact.newValue === 0 &&
            (capacities?.plannedPhase ?? capacities?.phase) === impact.phase
          ) {
            newMap.delete(smartmeter);
          }
        });
        return newMap;
      });
    }
  }, [additionModalState.open, additionModalState.rowIdentifier, capacitiesMap, setAddValueMap]);

  return (
    <div className="is-family-primary is-body container-page">
      {(isLoadingImpact || isLoading.smartMeters) && <Loader fullScreen={false}></Loader>}
      <SplitScreenTemplate
        ratio={33}
        leftSlot={
          <SplitColumnTemplate
            upperRatio={55}
            middleRatio={45}
            upperSlot={
              secondarySubstations && (
                <SubstationsTabs
                  mapCenter={mapCenter}
                  setMapCenter={(center: LatLngTuple) => {
                    setSmMapCenter(center);
                    setMapCenter(center);
                  }}
                  hasAnalytics={false}
                  activeSecondarySubstation={activeSecondarySubstation}
                  addTab={addTab}
                  removeTabs={removeTabs}
                  substationsTabList={substationsTabList}
                />
              )
            }
            middleSlot={
              <>
                {smMapCenter && (
                  <LeafletMap
                    center={smMapCenter}
                    mapSlot={
                      <>
                        <SensorsCluster
                          secondarySubstations={
                            activeSecondarySubstation ? [activeSecondarySubstation] : []
                          }
                          activeSecondarySubstation={activeSecondarySubstation ?? null}
                          smartMeters={smartMetersWithLocation}
                          impact={{
                            capacities: capacitiesMap,
                            normalizedCapacities: normalizedCapacitiesMap,
                            addedValues: addValueMap,
                            selectedSmartMeter: additionModalState.rowIdentifier,
                          }}
                          selectSmartmeter={(smartmeter: SmartMeter) =>
                            onAdditionCellClick(smartmeter)
                          }
                          setHoveredSmartmeterId={setHoveredSmartmeterById}
                          hoveredSmartmeterId={hoveredSmartmeter}
                        />
                        <div className="map-color-container">
                          <ColorLegend
                            prefixTranslationKey="map.impact.color_legend.pvs."
                            colorVariables={['installed', 'planned', 'added', 'residual']}
                          />
                        </div>
                      </>
                    }
                  />
                )}
              </>
            }
          />
        }
        rightSlot={
          <div className="page-container container-impact">
            <Tabs
              isScrollable={false}
              leftIcon={
                <span className="icon is-small is turn">
                  <Icon name="substation" />
                </span>
              }
              removeTabs={removeTabs}
              openTab={addTab}
              activeTabIndex={substationsTabList?.findIndex(
                (s) => s?.identifier === activeSecondarySubstation?.identifier
              )}
              tabArray={substationsTabList.map((secondarySub) => ({
                name: secondarySub?.name,
                identifier: secondarySub?.identifier,
              }))}
            />
            {activeSecondarySubstation?.name && (
              <div className={`secondary-substation-container ${isFullScreen ? 'fullscreen' : ''}`}>
                <div className="secondary-substation-header">
                  <div className="title">
                    <span className="icon substation-icon is-medium">
                      <Icon name="substation" />
                    </span>
                    {activeSecondarySubstation?.name}
                  </div>
                  <div className="field">
                    {activeButtons?.map((buttonKey) => (
                      <button
                        className="button is-rounded is-link is-outlined mr-2 mb-2"
                        key={buttonKey}
                        onClick={() => onButtonClick(buttonKey)}
                      >
                        {t(asTemplateString(`impact.button.${buttonKey}`))}
                      </button>
                    ))}
                  </div>

                  <div
                    className="expand is-medium"
                    onClick={() => setIsFullScreen((fullscreen) => !fullscreen)}
                  >
                    <Icon name="expand" />
                  </div>
                </div>
                {isLoading.smartMeters && isLoading.feeders ? (
                  <Loader />
                ) : (
                  <ImpactTable
                    smartMeters={smartMetersWithFeeder}
                    capacities={capacitiesMap}
                    normalizedCapacities={normalizedCapacitiesMap}
                    addedValues={addValueMap}
                    tableMaxLoad={tableMaxLoad}
                    previousCapacities={savedPreviousMax}
                    selectedSmartmeterId={additionModalState.rowIdentifier}
                    onAdditionCellClick={(row: ImpactTableRow) => {
                      onAdditionCellClick(
                        currentSmartMeters.find(
                          (sm) => sm.identifier === row.identifier
                        ) as SmartMeter
                      );
                    }}
                    setHoveredSmartmeterById={setHoveredSmartmeterById}
                    hoveredSmartmeter={hoveredSmartmeter}
                  />
                )}
              </div>
            )}
            {!activeSecondarySubstation && (
              <div className="no-substation-selection is-full-height">
                {t('impact.no_substation_selected')}
              </div>
            )}
            {additionModalState.rowName &&
              capacitiesMap.get(additionModalState.rowIdentifier)?.added !== undefined && (
                <ImpactModal
                  additionModalState={additionModalState}
                  loadCapacities={
                    capacitiesMap.get(additionModalState.rowIdentifier) as LoadCapacities
                  }
                  normalizedCapacities={
                    normalizedCapacitiesMap.get(
                      additionModalState.rowIdentifier
                    ) as NormalizedCapacities
                  }
                  cursorMax={
                    (savedPreviousMax.get(additionModalState.rowIdentifier) as LoadCapacities)
                      ? (savedPreviousMax.get(additionModalState.rowIdentifier) as LoadCapacities)[
                          `resPhase${addValueMap.get(additionModalState.rowIdentifier)?.phase ?? 1}`
                        ]
                      : 0
                  }
                  deletePlannedLoad={(smartMeterId: string) =>
                    activeSecondarySubstation &&
                    deletePlannedLoad(activeSecondarySubstation.identifier, smartMeterId)
                  }
                  addValue={addValueMap.get(additionModalState.rowIdentifier)}
                  setAddValue={addOneValueAddMap}
                  saveModal={(smId: string) => {
                    setAdditionModalState({
                      open: false,
                      rowIdentifier: smId,
                      rowName: '',
                    });
                    simulatePvImpact(smId);
                  }}
                  hasSimulations={addValueMap.size > 1}
                  closeModal={(initialAddValue) => {
                    addOneValueAddMap(initialAddValue);
                    setAdditionModalState(initalImpactModalState);
                  }}
                />
              )}
          </div>
        }
      />
    </div>
  );
};

export default Impact;
