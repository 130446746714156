import { camelCase, snakeCase, isPlainObject, reduce, isArray } from 'lodash';
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const deepCaseConverter = (object: any, toCamelCase: boolean): any => {
  /* Convert deep nested object and arrays keys to camel case or snake case */
  if (!isPlainObject(object) && !isArray(object)) {
    return object;
  } else if (isArray(object)) {
    return object.map((v: any) => deepCaseConverter(v, toCamelCase));
  }
  return reduce(
    object,
    (r: any, v: any, k: string) => {
      return {
        ...r,
        [toCamelCase
          ? camelCase(k)
          : ['phase1', 'phase2', 'phase3', 'phase4'].includes(k)
          ? k
          : snakeCase(k)]: deepCaseConverter(v, toCamelCase),
      };
    },
    {}
  );
};
/* eslint-enable  @typescript-eslint/no-explicit-any */
