import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import {
  getCapacitiesFromApi,
  getGaugeCapacities,
  toastRequestResponse,
  updateSmartmeterLoadCapacities,
} from 'helpers';
import { StatusResponse } from 'appConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { apiCall, getIntegratorAndDso } from 'services';
import { changePvContext } from 'store/actions';

type Props = {
  activeSecondarySubstation: SecondarySubstation | undefined;
  currentSmartMeters: SmartMeter[];
  resetState: () => void;
};

export const useImpactData = ({
  activeSecondarySubstation,
  currentSmartMeters,
  resetState,
}: Props) => {
  const { datasetId, pvLoadCapacityModelIds, deviceDetectionModelIds, pvPlannedLoadIds } =
    useAppSelector((state) => state.session.dataContext);
  const dispatch = useAppDispatch();

  const [capacitiesMap, setCapacitiesMap] = useState<CapacitiesBySmartMeter>(new Map());
  const [isLoadingImpact, setIsLoadingImpact] = useState(false);
  const [savedPreviousMax, setSavedPreviousMax] = useState<Map<string, LoadCapacities>>(new Map());
  const [addValueMap, setAddValueMap] = useState<ImpactAddedCapacitiesMap>(new Map());
  const [normalizedCapacitiesMap, setNormalizedCapacitiesMap] =
    useState<NormalizedCapacitiesBySmartMeter>(new Map());
  const [tableMaxLoad, setTableMaxLoad] = useState(0);
  const [loadProcessing, setLoadProcessing] = useState('');

  useEffect(() => {
    const getCapacities = async (secSubId: string) => {
      const capacity = await getCapacitiesFromApi(
        datasetId,
        secSubId,
        currentSmartMeters,
        pvLoadCapacityModelIds,
        deviceDetectionModelIds,
        pvPlannedLoadIds
      );
      setCapacitiesMap(capacity);
      setSavedPreviousMax(cloneDeep(capacity));
      setIsLoadingImpact(false);
    };
    if (
      currentSmartMeters.length &&
      pvLoadCapacityModelIds.length &&
      deviceDetectionModelIds.length &&
      activeSecondarySubstation?.identifier
    ) {
      getCapacities(activeSecondarySubstation.identifier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSmartMeters, datasetId, deviceDetectionModelIds, pvLoadCapacityModelIds]);

  useEffect(() => {
    /* Max load and normalized capacities can change according to what was added in the modal
    When the capacities map, or the addValueMap changes we recalculate
     */
    let maxLoad = 0;
    capacitiesMap.forEach((capacities, idSmartmeter) => {
      const previousMax = savedPreviousMax.get(idSmartmeter) as LoadCapacities;
      const maxPreviousSm =
        previousMax[`resPhase${capacities.phase}`] + previousMax.installed + previousMax.planned;
      const totalCapacities =
        capacities.installed + capacities.planned + capacities.maxRes + capacities.added;
      if (totalCapacities > maxLoad) {
        maxLoad = totalCapacities;
      }
      if (maxPreviousSm > maxLoad) {
        maxLoad = maxPreviousSm;
      }
    });
    const normalizedCapacities: NormalizedCapacitiesBySmartMeter = new Map();
    cloneDeep(capacitiesMap).forEach((capacities, idSmarmeter) => {
      const previousMax = savedPreviousMax.get(idSmarmeter) as LoadCapacities;
      const addedValueSm = addValueMap.get(idSmarmeter);
      const maxPreviousSm =
        previousMax[`resPhase${previousMax.phase}`] + previousMax.installed + previousMax.planned;
      const gaugeCapacities = getGaugeCapacities(
        capacities,
        maxLoad,
        addedValueSm?.phase ?? capacities.phase,
        addedValueSm?.newValue ?? 0,
        maxPreviousSm
      );
      normalizedCapacities.set(idSmarmeter, gaugeCapacities);
    });
    setNormalizedCapacitiesMap(normalizedCapacities);
    setTableMaxLoad(maxLoad);
  }, [addValueMap, capacitiesMap, savedPreviousMax]);

  const deletePlannedLoad = async (secSubId: string, smartMeterId: string) => {
    setIsLoadingImpact(true);
    resetState();
    const data = await apiCall
      .cancelPlannedLoad(datasetId, secSubId, [smartMeterId])
      .catch(() => onSimulateLoadFailure(smartMeterId));
    const resultDeleteLoad = data?.data.data;
    const statusResponse =
      resultDeleteLoad?.status === 'SUCCESS' ? StatusResponse.success : StatusResponse.errors;
    toastRequestResponse(statusResponse, 'delete_load_analytic');
    // TODO get new load capacity once returned by the analytic
    const processingGroup = resultDeleteLoad?.result;
    if (processingGroup) {
      processingGroup.plannedLoad && setLoadProcessing(processingGroup.plannedLoad);
      const { integratorId, dsoId } = getIntegratorAndDso();
      const {
        data: { data: processing },
      } = await apiCall.getProcessings(integratorId, dsoId, datasetId, processingGroup.plannedLoad);
      if (processing.error !== null) {
        onSimulateLoadFailure(smartMeterId);
      } else if (processingGroup.pvCapacity) {
        dispatch(
          changePvContext(
            { processing: processingGroup.plannedLoad, sensor: secSubId },
            { processing: processingGroup.pvCapacity, sensor: secSubId }
          )
        );
      }
    }
    setIsLoadingImpact(false);
  };

  const validatePlannedLoad = () => {
    if (activeSecondarySubstation?.identifier) {
      setIsLoadingImpact(true);
      apiCall
        .validatePlannedLoad(datasetId, activeSecondarySubstation.identifier, loadProcessing)
        .then((result) => {
          setIsLoadingImpact(false);
          const statusResponse =
            result.data.data.status === 'SUCCESS' ? StatusResponse.success : StatusResponse.errors;
          if (statusResponse === StatusResponse.success) {
            const resultProcessing = result.data.data.result;
            dispatch(
              changePvContext(
                {
                  processing: resultProcessing.plannedLoad,
                  sensor: activeSecondarySubstation.identifier,
                },
                {
                  processing: resultProcessing.pvCapacity,
                  sensor: activeSecondarySubstation.identifier,
                }
              )
            );
            setAddValueMap(new Map());
          }
          toastRequestResponse(statusResponse, 'planned_load_analytic');
        })
        .catch(() => {
          setIsLoadingImpact(false);
        });
    }
  };

  const simulatePvImpact = async (smId: string) => {
    const { integratorId, dsoId } = getIntegratorAndDso();
    if (activeSecondarySubstation) {
      setIsLoadingImpact(true);
      const pvsList: { smartmeterId: string; value: number; phase: Phase }[] = [];
      addValueMap.forEach((value, key) =>
        pvsList.push({ smartmeterId: key, value: value.newValue, phase: value.phase })
      );
      const value = await apiCall
        .simulatePv(datasetId, activeSecondarySubstation.identifier, pvsList)
        .catch(() => {
          onSimulateLoadFailure(smId);
        });
      const processingGroup = value?.data.data.result;
      if (processingGroup) {
        processingGroup.plannedLoad && setLoadProcessing(processingGroup.plannedLoad);
        const {
          data: { data: processing },
        } = await apiCall.getProcessings(
          integratorId,
          dsoId,
          datasetId,
          processingGroup.plannedLoad
        );
        if (processing.error !== null) {
          onSimulateLoadFailure(smId);
        } else if (processingGroup.pvCapacity) {
          const {
            data: { data: loadCapacities },
          } = await apiCall.getLoadCapacity(datasetId, processingGroup.pvCapacity);
          const capacity: CapacitiesBySmartMeter = new Map();
          currentSmartMeters.forEach((sm) => {
            const smCapacities = capacitiesMap.get(sm.identifier) as LoadCapacities;
            const loadCapacity = loadCapacities.find(
              (load: LoadCapacity) => load.sensor === sm.identifier
            );
            /* Find previously simulated PVs to add them to the capacity */
            const addedPvs = pvsList.find((el) => el.smartmeterId === sm.identifier)?.value;
            const updateCapacities = updateSmartmeterLoadCapacities(
              smCapacities,
              loadCapacity,
              addedPvs
            );
            updateCapacities && capacity.set(sm.identifier, updateCapacities);
          });
          setCapacitiesMap(capacity);
        }
      } else {
        onSimulateLoadFailure(smId);
      }
      setIsLoadingImpact(false);
    }
  };

  const onSimulateLoadFailure = (smartmeterId: string) => {
    /* If there is a problem with the simulation delete what was added  */
    setAddValueMap((addValueMap) => {
      const newMap = new Map(addValueMap);
      newMap.delete(smartmeterId);
      return newMap;
    });
    toastRequestResponse(StatusResponse.errors, 'simulate_load_analytic');
    setIsLoadingImpact(false);
  };

  return {
    capacitiesMap,
    setCapacitiesMap,
    addValueMap,
    setAddValueMap,
    isLoadingImpact,
    savedPreviousMax,
    normalizedCapacitiesMap,
    tableMaxLoad,
    deletePlannedLoad,
    validatePlannedLoad,
    simulatePvImpact,
  };
};
