import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';
import { cloneDeep } from 'lodash';

import { graphDefaultConfig } from 'appConstants';
import { toggleGraphModalButton } from './graphFunctions';
import { Modal } from 'components/molecules';
import { Loader } from 'components/atoms';

import { generateLayout, getGraphDataLegend, updateTranslationLayout } from 'helpers';

import './graph.scss';

type Props = {
  translationKey: string;
  data: Plotly.Data[] | null;
  layout?: Partial<Plotly.Layout>;
  config?: Partial<Plotly.Config> | undefined;
  loading?: { isGraphLoading: boolean; showLoading: boolean };
};

const Graph = ({
  translationKey,
  data,
  config = graphDefaultConfig,
  loading = { isGraphLoading: false, showLoading: false },
}: Props) => {
  const { i18n } = useTranslation();
  const locale = i18n.resolvedLanguage;

  const updateLayout = useCallback(
    (layout: Partial<Plotly.Layout>, annotation: boolean): Partial<Plotly.Layout> =>
      updateTranslationLayout(
        layout,
        i18n.t(`${translationKey}.title`),
        i18n.t(`${translationKey}.x_axis`),
        i18n.t(`${translationKey}.y_axis`),
        annotation ? i18n.t(`${translationKey}.no_data`) : null,
        translationKey === 'graphs.imbalance_current_radar'
          ? [
              i18n.t(`${translationKey}.first_axis`),
              i18n.t(`${translationKey}.second_axis`),
              i18n.t(`${translationKey}.third_axis`),
            ]
          : null
      ),
    [i18n, translationKey]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [graphData, setGraphData] = useState<Plotly.Data[] | null>(
    data
      ? data.map((data, index) => {
          if (translationKey === 'graphs.topology') return data;
          return { ...data, name: i18n.t(getGraphDataLegend(translationKey)[index]) };
        })
      : null
  );
  const [graphLayout, setGraphLayout] = useState<Partial<Plotly.Layout>>(() => {
    const hasData = !!data && data.length > 0;

    return updateLayout(
      generateLayout(
        hasData ? translationKey : null,
        hasData && data[0].text === 'isFeeder',
        hasData ? data.length : undefined
      ),
      data === null && loading.showLoading && !loading.isGraphLoading
    );
  });

  const graphConfig = useMemo<Partial<Plotly.Config> | undefined>(() => {
    return {
      ...config,
      modeBarButtons: [
        [
          toggleGraphModalButton(
            i18n.t(`graphs.${isModalOpen ? 'close' : 'expand'}_button`),
            isModalOpen,
            setIsModalOpen
          ),
        ],
      ],
      locale,
    };
  }, [isModalOpen, config, i18n, locale]);

  const updateDataAndTranslation = useCallback(
    (data: Plotly.Data[] | null) => {
      let dataWithTranslation: Plotly.Data[] | null = [];

      if (data) {
        dataWithTranslation = cloneDeep(data)?.map((d, index) => {
          if (translationKey === 'graphs.topology') return d;
          return { ...d, name: i18n.t(getGraphDataLegend(translationKey)[index]) };
        });
      } else if (data === null) {
        dataWithTranslation = null;
      }
      setGraphData(dataWithTranslation);
      let isFeeder, nbFeeder;
      let transKey = null;
      if (dataWithTranslation && dataWithTranslation.length) {
        isFeeder = dataWithTranslation[0].text === 'isFeeder';
        nbFeeder = dataWithTranslation.length;
        transKey = translationKey;
      }
      setGraphLayout(
        updateLayout(
          generateLayout(transKey, isFeeder, nbFeeder),
          dataWithTranslation === null && loading.showLoading && !loading.isGraphLoading
        )
      );
    },
    [updateLayout, translationKey, loading.showLoading, loading.isGraphLoading, i18n]
  );

  useEffect(() => {
    /* Update each time props data changes*/
    updateDataAndTranslation(data);
  }, [data, updateDataAndTranslation]);

  useEffect(() => {
    const setLanguage = () => {
      updateDataAndTranslation(graphData);
    };
    i18n.on('languageChanged', setLanguage);
    return () => i18n.off('languageChanged', setLanguage);
  });

  const renderGraph = () => {
    return (
      <>
        <Plot
          data={graphData ?? []}
          className="graph-loader"
          layout={graphLayout}
          config={graphConfig}
          useResizeHandler
          style={{ width: '100%', height: '100%' }}
        ></Plot>
        {loading.showLoading && loading.isGraphLoading && <Loader fullScreen={false} />}
      </>
    );
  };

  return (
    <>
      <div className="graph is-flex-centered">
        {renderGraph()}
        <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
          {isModalOpen && renderGraph()}
        </Modal>
      </div>
    </>
  );
};

export default Graph;
