import variables from 'assets/styles/partials/exports/_variables.module.scss';
import { cloneDeep, max } from 'lodash';
import { asTemplateString, roundOneDigit } from './general';
import { StatusResponse, listPhases } from 'appConstants';
import { toast } from 'bulma-toast';
import { t } from 'i18next';
import { apiCall } from 'services';

export const getGaugeCapacities = (
  capacities: LoadCapacities,
  maxCapacitySm: number,
  phase: Phase,
  addedValue: number,
  maxPreviousSm: number,
  multiply?: number
): NormalizedCapacities => {
  const overflow = 0;
  const residual = capacities[`resPhase${phase}`];

  const resPhase = residual - addedValue + capacities.added;
  const residualMax = capacities.maxRes - residual - capacities.added;

  return {
    installed: normalize(capacities.installed, maxCapacitySm, multiply),
    planned: normalize(capacities.planned, maxCapacitySm, multiply),
    added: normalize(addedValue !== 0 ? addedValue : capacities.added, maxCapacitySm, multiply),
    residual: normalize(resPhase < 0 ? 0 : resPhase, maxCapacitySm, multiply),
    overflow: normalize(overflow, maxCapacitySm, multiply),
    residualMax: normalize(residualMax, maxCapacitySm, multiply),
    maxBeforeSimulation: normalize(maxPreviousSm, maxCapacitySm, multiply),
  };
};

export const normalize = (value: number, max: number, percent?: number) => {
  return value ? (value / max) * (percent ? percent : 100) : 0;
};

export const getIsStripped = (colorVariable: string) => {
  if (colorVariable?.includes('stripped')) {
    const seg = colorVariable.split('_');
    return `repeating-linear-gradient(-45deg, 
      ${variables[seg[1]]} 0px, ${variables[seg[1]]} 4px, #fff 4px, #fff 8px)`;
  } else {
    return variables[colorVariable];
  }
};

export const getSmartmeterCapacities = (
  loadCapacity: LoadCapacity,
  pvDetection: PvDetection,
  pvPlanned: PvPlannedLoad,
  phase: Phase
) => {
  const maximumLoad = max([
    loadCapacity.phase1Capacity?.magnitude,
    loadCapacity.phase2Capacity?.magnitude,
    loadCapacity.phase3Capacity?.magnitude,
    loadCapacity.phase4Capacity?.magnitude,
  ]);
  if (loadCapacity && maximumLoad) {
    const installed = pvDetection[`phase${phase}Kwmax`]?.magnitude ?? 0;
    const planned =
      max([
        pvPlanned?.phase1Planned?.magnitude,
        pvPlanned?.phase2Planned?.magnitude,
        pvPlanned?.phase3Planned?.magnitude,
        pvPlanned?.phase4Planned?.magnitude,
      ]) ?? 0;
    const phasePlanned = listPhases.find(
      (phase) => pvPlanned && !!pvPlanned[`phase${phase as Phase}Planned`]?.magnitude
    );
    const smCapacities: LoadCapacities = {
      added: 0,
      installed: roundOneDigit(installed),
      maxRes: roundOneDigit(maximumLoad),
      planned,
      resPhase1: roundOneDigit(loadCapacity.phase1Capacity?.magnitude),
      resPhase2: roundOneDigit(loadCapacity.phase2Capacity?.magnitude),
      resPhase3: roundOneDigit(loadCapacity.phase3Capacity?.magnitude),
      resPhase4: roundOneDigit(loadCapacity.phase4Capacity?.magnitude),
      plannedPhase: planned ? phasePlanned : undefined,
      phase: phase as Phase,
    };
    return smCapacities;
  }
  return null;
};

export const updateSmartmeterLoadCapacities = (
  capacities: LoadCapacities,
  loadCapacity: LoadCapacity,
  pvs?: number
) => {
  const maximumLoad = max([
    loadCapacity.phase1Capacity?.magnitude,
    loadCapacity.phase2Capacity?.magnitude,
    loadCapacity.phase3Capacity?.magnitude,
    loadCapacity.phase4Capacity?.magnitude,
  ]);
  if (loadCapacity && maximumLoad) {
    let updateCapacities = cloneDeep(capacities);
    updateCapacities = {
      ...updateCapacities,
      added: pvs ?? 0,
      resPhase1: roundOneDigit(loadCapacity.phase1Capacity?.magnitude),
      resPhase2: roundOneDigit(loadCapacity.phase2Capacity?.magnitude),
      resPhase3: roundOneDigit(loadCapacity.phase3Capacity?.magnitude),
      resPhase4: roundOneDigit(loadCapacity.phase4Capacity?.magnitude),
      maxRes: roundOneDigit(maximumLoad),
    };
    return updateCapacities;
  }
  return null;
};

export const toastRequestResponse = (messageType: StatusResponse, messageKey: string) => {
  toast({
    message: t(asTemplateString(`request_${messageType}.${messageKey}_${messageType}`)),
    type: messageType === 'success' ? 'is-success' : 'is-danger',
    dismissible: true,
    position: 'bottom-right',
    duration: 8000,
  });
};

export const getCapacitiesFromApi = async (
  datasetId: string,
  secSubId: string,
  smartmeters: SmartMeter[],
  pvLoadCapacityModelIds: ProcessingSensor[],
  deviceDetectionModelIds: ProcessingSensor[],
  pvPlannedLoadIds: ProcessingSensor[]
) => {
  const loadId = pvLoadCapacityModelIds.find(
    (el: ProcessingSensor) => el.sensor === secSubId
  )?.processing;
  const deviceDetectionId = deviceDetectionModelIds.find(
    (el: ProcessingSensor) => el.sensor === secSubId
  )?.processing;
  const plannedLoadId = pvPlannedLoadIds.find(
    (el: ProcessingSensor) => el.sensor === secSubId
  )?.processing;
  const {
    data: { data: loadCapacities },
  } = await apiCall.getLoadCapacity(datasetId, loadId ?? '');
  const {
    data: { data: pvDetections },
  } = await apiCall.getPvDetections(datasetId, deviceDetectionId ?? '');
  const {
    data: { data: pvsPlanned },
  } = await apiCall.getPlannedLoad(datasetId, plannedLoadId ?? '');

  const capacity: CapacitiesBySmartMeter = new Map();
  smartmeters.forEach((sm) => {
    const loadCapacity: LoadCapacity = loadCapacities.find(
      (load: LoadCapacityApi) => load.sensor === sm.identifier
    );
    const pvDetection: PvDetection = pvDetections.find(
      (detection: PvDetectionApi) => detection.sensor === sm.identifier
    );
    const pvPlanned: PvPlannedLoad = pvsPlanned.find(
      (detection: PvDetectionApi) => detection.sensor === sm.identifier
    );
    if (pvDetection && loadCapacity && sm.phase1) {
      const smCapacities = getSmartmeterCapacities(loadCapacity, pvDetection, pvPlanned, sm.phase1);
      smCapacities && capacity.set(sm.identifier, smCapacities);
    }
  });
  return capacity;
};
