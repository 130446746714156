import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { useKeycloak } from '@react-keycloak/web';

import './login-form.scss';

const LoginForm = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const currentYear = useMemo(() => dayjs().year(), []);

  return (
    <div className="login-form">
      <div className="is-family-primary has-text-weight-bold login-subtitle">
        {t('login.subtitle')}
      </div>
      <div className="is-family-primary mt-6">{t('login.welcome_phrase')}</div>

      <div>
        <div className="mt-6bis is-flex-centered">
          <button
            type="submit"
            className="button is-primary is-rounded login-button has-text-weight-medium"
            onClick={() => keycloak.login()}
          >
            {'Go to Login'}
          </button>
        </div>

        <div className="mt-6 is-flex-centered has-text-weight-medium login-copyright">
          © {currentYear}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
