import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { snakeCase } from 'lodash';

import { asTemplateString, setHoverDefinition } from 'helpers';
import { CheckboxStates, networkAnalyticsOptions, rebalancingAnalyticsOptions } from 'appConstants';

import { Icon, Checkbox, Tag } from 'components/atoms';

type Filter = string | CheckboxStates | null | undefined;

type Props = {
  name: string;
  sortProps?: {
    isSortable: boolean;
    onSortBy: () => void;
    sortingKey?: {
      header: string;
      icon: string;
    } | null;
  };

  useCase: string;
  filterType?: 'input' | 'checkbox' | 'empty' | null;
  classesContainer?: string;
  classeTh?: string;

  onFilterInputChanged?: (v: string) => void;
  onUpdateFilter?: (v: Filter) => void;
  info?: JSX.Element;
  positionInfo?: { width: string; left: string; right: string };
};

const TableHeader = ({
  name,
  sortProps = {
    isSortable: false,
    onSortBy: () => null,
    sortingKey: null,
  },

  filterType = null,
  useCase,
  classesContainer = '',
  classeTh = '',
  onUpdateFilter,
  info,
  positionInfo = { width: 'fit-content', left: 'auto', right: '1rem' },
}: Props) => {
  const { t } = useTranslation();
  const [currentFilter, setCurrentFilter] = useState<string | null>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [isEditingFilter, setIsEditingFilter] = useState<boolean | undefined>();
  const mainHeaderClasses = `${sortProps.isSortable ? 'cursor-pointer' : ''} ${
    name === 'checkbox' ? 'is-checkbox' : name
  }`;

  const onSubmitFilter = (value: Filter) => {
    setIsEditingFilter(false);
    onUpdateFilter && onUpdateFilter(value);
  };

  const isAnalyticsOptions = () =>
    (useCase === 'rebalancing' ? rebalancingAnalyticsOptions : networkAnalyticsOptions).find(
      (option) => option.key === name
    );

  const getTitle = () => {
    const option = isAnalyticsOptions();
    if (option) {
      const analyticsDefinition = setHoverDefinition(option);
      return analyticsDefinition.replace(/<\/?sub>/g, '');
    }
    return t(asTemplateString(`table_headers.${useCase}.${snakeCase(name)}`));
  };

  return (
    <>
      <th className={`list-header-container ${classesContainer} ${info ? 'info-header' : ''}`}>
        <div
          onClick={() => sortProps.isSortable && sortProps.onSortBy()}
          className={`list-header ${mainHeaderClasses} ${classeTh}`}
        >
          {name === 'checkbox' ? (
            <Icon name="tune" />
          ) : (
            <>
              {/* Using dangerouslySetInnerHTML as <sub> are used in translation files
              for some of the header names */}
              <div
                dangerouslySetInnerHTML={{
                  __html: t(asTemplateString(`table_headers.${useCase}.${snakeCase(name)}`)),
                }}
                {...{
                  title: getTitle(),
                }}
                className={`mr-1 is-clipped is-ellipsis ${
                  isAnalyticsOptions() && 'is-nowrap cursor-help'
                }`}
              />
              {info && (
                <div
                  className="info"
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                >
                  <Icon name="info" classes="is-flex-shrink-0" />
                </div>
              )}

              {sortProps.isSortable && (
                <Icon
                  name={
                    name === sortProps.sortingKey?.header
                      ? sortProps.sortingKey.icon
                      : 'double-chevron'
                  }
                  classes="is-flex-shrink-0"
                />
              )}
            </>
          )}
        </div>
        {showInfo && (
          <div
            className="info-container"
            style={{
              width: positionInfo.width,
              left: positionInfo.left,
              right: positionInfo.right,
            }}
          >
            {info}
          </div>
        )}

        {filterType && (
          <div className={`list-header filters ${name === 'checkbox' ? 'is-checkbox' : name}`}>
            {filterType === 'checkbox' && (
              <Checkbox
                label=""
                value={currentFilter ?? CheckboxStates.Indeterminate}
                onChange={(v) => {
                  setCurrentFilter(v);
                  onUpdateFilter && onUpdateFilter(v);
                }}
              ></Checkbox>
            )}
            {filterType === 'input' && (
              <>
                {isEditingFilter || !currentFilter ? (
                  <div className="field has-addons">
                    <div className="control has-icons-left">
                      <input
                        className="input filter-input"
                        type="search"
                        placeholder={t('table_headers.filter')}
                        onBlur={() => onSubmitFilter(currentFilter)}
                        onKeyDown={(e) => {
                          e.key === 'Enter' && onSubmitFilter(currentFilter);
                        }}
                        onChange={(e) => setCurrentFilter(e.target.value)}
                        onClick={() => setIsEditingFilter(true)}
                      ></input>
                      <span className="icon is-left">
                        <Icon name="filter-2" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <Tag
                    tagText={currentFilter ?? ''}
                    onClose={() => {
                      setCurrentFilter(null);
                      onSubmitFilter(null);
                    }}
                  />
                )}
              </>
            )}
          </div>
        )}
      </th>
    </>
  );
};

export default TableHeader;
