import React, { useState, ChangeEvent } from 'react';
import { Icon } from 'components/atoms';

type Props = {
  name: string;
  placeholder: string;
  classes?: string;
  value?: string | number | readonly string[] | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputPassword = ({ name, placeholder, onChange, classes, value }: Props) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => {
    setVisible(!visible);
  };
  return (
    <div className="control has-icons-right">
      <input
        className={`input family-secondary has-text-weight-normal ${classes && classes}`}
        type={visible ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        autoComplete="current-password"
      />
      <span className="icon is-small is-right is-clickable noselect" onClick={toggleVisibility}>
        <Icon name={visible ? 'visibility-off' : 'visibility-on'} />
      </span>
    </div>
  );
};

export default InputPassword;
