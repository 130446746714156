import { findLast } from 'lodash';
import React, { useCallback, useState } from 'react';

type Props = {
  activeSecondarySubstation: SecondarySubstation | undefined;
  secondarySubstations: SecondarySubstation[] | [];
  setActiveSecondarySubstation: (
    value: React.SetStateAction<SecondarySubstation | undefined>
  ) => void;
};

export const useSubstationsTabList = ({
  secondarySubstations,
  activeSecondarySubstation,
  setActiveSecondarySubstation,
}: Props) => {
  const [substationsTabList, setSubstationsTabList] = useState<Array<SecondarySubstation>>([]);
  /* Add a Tab from the primary Tab Object */
  const addTab = useCallback(
    (identifier: string) => {
      const valueToAdd = secondarySubstations.find(
        (secSub: SecondarySubstation) => secSub.identifier === identifier
      );
      if (valueToAdd && activeSecondarySubstation?.identifier !== valueToAdd?.identifier) {
        setActiveSecondarySubstation(valueToAdd);
        setSubstationsTabList((previousTabs) =>
          !previousTabs.includes(valueToAdd) ? [...previousTabs, valueToAdd] : previousTabs
        );
      }
    },
    [activeSecondarySubstation?.identifier, secondarySubstations, setActiveSecondarySubstation]
  );

  /* Remove a Tab from the primary Tab Object */
  const removeTabs = (identifiers: string[]) => {
    const tabsIndexToRemove = identifiers
      .map((identifier) => substationsTabList.findIndex((s) => s.identifier === identifier))
      .filter((index) => index > -1);

    if (tabsIndexToRemove?.length > 0) {
      const tabsList = [...substationsTabList];
      setSubstationsTabList(tabsList.filter((s) => !identifiers.includes(s.identifier)));
      const activeTabIndex = identifiers.findIndex(
        (identifier) => activeSecondarySubstation?.identifier === identifier
      );
      if (activeTabIndex > -1) {
        const indexActif = tabsIndexToRemove[activeTabIndex];
        const newsActiveSecSub =
          findLast(
            tabsList,
            (_, index) => index < indexActif && !tabsIndexToRemove.includes(index)
          ) ??
          tabsList.find((_, index) => index > indexActif && !tabsIndexToRemove.includes(index));
        setActiveSecondarySubstation(newsActiveSecSub);
      }
    }
  };

  return {
    substationsTabList,
    addTab,
    removeTabs,
    setSubstationsTabList,
  };
};
