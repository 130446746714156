import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';

import { asTemplateString } from 'helpers';

type Props = {
  dropdownOptions: {
    key: string | number;
    translationKey: string;
    optionalTranslationKeys?: string[];
  }[];
  selectValue: (value: string, key: string) => void;
  name: string;
  disabled?: boolean;
  label?: string;
  initialValue?: string | number;
  children?: JSX.Element;
  getDefinition?: (value: string, key: string) => void;
  isInfofocus?: boolean;
  isCompacted?: boolean;
};
const SelectDropdown = ({
  dropdownOptions,
  selectValue,
  disabled = false,
  label,
  initialValue,
  children,
  name,
  getDefinition,
  isInfofocus = false,
  isCompacted = false,
}: Props) => {
  const { t } = useTranslation();

  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (selectRef && selectRef.current && isInfofocus) {
      selectRef.current.blur();
    }
  }, [isInfofocus]);

  const onchange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    selectValue(
      e.target.value,
      e.target.options[e.target.options.selectedIndex].getAttribute('data-id') as string
    );

    getDefinition &&
      getDefinition(
        e.target.value,
        e.target.options[e.target.options.selectedIndex].getAttribute('data-id') as string
      );
  };

  return (
    <div
      className={`  is-grouped is-grouped-right select-dropdown is-align-items-center ${
        isCompacted ? 'is-flex' : 'field'
      }`}
    >
      {label && <div className={`field-label ${isCompacted && 'is-small'}`}>{label}</div>}
      <div className="field-body">
        <div className="select is-flex is-align-items-center">
          <select
            onChange={(e) => onchange(e)}
            {...(initialValue !== null && { value: initialValue })}
            aria-label={name}
            ref={selectRef}
            disabled={disabled}
          >
            {dropdownOptions.map((value) => {
              return (
                <option key={value.key} value={value.key} data-id={value.translationKey}>
                  {t(asTemplateString(value.translationKey))}
                  {value.optionalTranslationKeys
                    ? value.optionalTranslationKeys.map((translationKey) => {
                        return t(asTemplateString(translationKey));
                      })
                    : ''}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {children}
    </div>
  );
};

export default SelectDropdown;
