import React from 'react';

type Props = {
  currentAnalyticsOption: SecondarySubAnalyticsOption;
};
const LegendAnalytics = ({ currentAnalyticsOption }: Props) => {
  const displayItemValue = (value: number, index: number) => {
    let displayValue = value.toString();
    if (index === currentAnalyticsOption.values?.length - 1) {
      displayValue += `+ ${currentAnalyticsOption.unit ? `(${currentAnalyticsOption.unit})` : ''}`;
    }
    if (displayValue === '-1') {
      return '';
    }
    return displayValue;
  };

  return (
    <div className="leaflet-right leaflet-bottom">
      <div className="colorbar leaflet-control  columns">
        {currentAnalyticsOption.values.map((value, index) => {
          return (
            <div key={`option-item-${index}`}>
              <div
                className={`element-colorbar ${currentAnalyticsOption.cssClasses}-${index} control`}
              ></div>
              <div className=" control">{displayItemValue(value, index)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default LegendAnalytics;
