import React, { ReactNode } from 'react';
type Props = {
  dropdownTrigger: ReactNode;
  dropdownMenu: ReactNode;
};

const Dropdown = ({ dropdownTrigger, dropdownMenu }: Props) => {
  return (
    <>
      <div className="dropdown is-right is-hoverable is-dropdown-fixed-height">
        <a className="dropdown-trigger navbar-link">{dropdownTrigger}</a>

        <div className="dropdown-menu dropdown-content"> {dropdownMenu}</div>
      </div>
    </>
  );
};

export default Dropdown;
