import React from 'react';

import './active-tab.scss';

type Props = {
  contentClasses?: string;
  activeTabIndex: number;
  children: JSX.Element[];
};

const ActiveTab = ({
  contentClasses = 'tab-content p-2 has-background-white',
  activeTabIndex = 0,
  children,
}: Props) => {
  return (
    <div className={contentClasses}>
      {children.map((child, index) => {
        if (index === activeTabIndex) {
          return child;
        }
      })}
    </div>
  );
};

export default ActiveTab;
