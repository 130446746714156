import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  logo: string;
};

const Logo = ({ logo }: Props) => {
  const { t } = useTranslation();

  return (
    <img
      className="logo"
      src={logo}
      alt={`${t('corporate.company_name')} ${t('corporate.logo')}`}
    />
  );
};

export default Logo;
