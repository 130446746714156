import { createControlComponent } from '@react-leaflet/core';
import { Control, DomUtil, DomEvent, Map } from 'leaflet';
import i18n from 'i18n/i18n';

const CenterButtonControl = Control.extend({
  button: null as unknown as HTMLAnchorElement,
  centerMap(e: Event, map: Map) {
    DomEvent.preventDefault(e);
    const { center, zoom } = map.options;
    center && map.setView(center, zoom);
  },
  onAdd(map: Map) {
    this.button = DomUtil.create('a', 'leaflet-control-center', map.getContainer());
    this.button.href = '#';
    this.button.title = i18n.t('map.center_button');
    DomEvent.disableClickPropagation(this.button);
    DomEvent.on(this.button, 'click', (e: Event) => this.centerMap(e, map));
    return this.button;
  },
  onRemove(map: Map) {
    /* Remove event listeners and button element on component removal */
    DomEvent.off(this.button, 'click', (e: Event) => this.centerMap(e, map));
    DomUtil.remove(this.button);
  },
});

export const CenterControl = createControlComponent((props) => new CenterButtonControl(props));
