import React from 'react';
import { useTranslation } from 'react-i18next';

import label from 'assets/img/labels/solar-impulse-label.png';
import label2x from 'assets/img/labels/solar-impulse-label@2x.png';

const Label = () => {
  const { t } = useTranslation();

  return (
    <img
      src={label}
      srcSet={`${label} 1x, ${label2x} 2x`}
      alt={`${t('corporate.company_name')} ${t('corporate.logo')}`}
    />
  );
};

export default Label;
