import React, { ChangeEvent } from 'react';

type Props = {
  name: string;
  placeholder: string;
  classes?: string;
  value?: string | number | readonly string[] | undefined;
  maxLength?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const InputText = ({ name, placeholder, onChange, classes, value, maxLength }: Props) => {
  return (
    <input
      className={`input family-secondary has-text-weight-normal ${classes && classes}`}
      type="text"
      placeholder={placeholder}
      name={name}
      value={value}
      maxLength={maxLength}
      onChange={(e) => {
        onChange(e);
      }}
      autoComplete={name}
    />
  );
};

export default InputText;
