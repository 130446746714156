import { createStore, combineReducers, compose, Reducer } from 'redux';

import { sessionReducer, sensorsDataReducer } from 'store/reducers';

const combinedReducers = combineReducers({
  session: sessionReducer,
  sensorsData: sensorsDataReducer,
});

interface ServerData {
  REACT_APP_API_DATA_ENDPOINT?: string;
  REACT_APP_AUTHENTICATION_ENDPOINT?: string;
  REACT_APP_KEYCLOAK_REALM?: string;
  REACT_APP_KEYCLOAK_CLIENT?: string;
  REACT_APP_TILE_SERVER_URL?: string;
  REACT_APP_ODT_PRODUCT_NAME?: string;
  REACT_APP_ODT_PRODUCT_TAG?: string;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    SERVER_DATA: ServerData;
  }
}
/* Enable Redux DevTools */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combinedReducers as Reducer, composeEnhancers());

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof combinedReducers>;
export type AppDispatch = typeof store.dispatch;

export default store;
