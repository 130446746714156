import { cloneDeep } from 'lodash';
import { useState } from 'react';
import Plotly from 'plotly.js';

import { useAppDispatch, useAppSelector, useTopology } from 'hooks';
import { apiCall, getDefaultParams, getConstraint } from 'services';
import { setAnalysisGraphData } from 'store/actions';
import {
  filterBoxplotDataByAbcissName,
  formatRadarData,
  formatBoxplotData,
  formatDurationCurveData,
  getDataFromPromiseResponse,
  formatTopologyTreeData,
} from 'helpers';
import { graphAnalytics } from 'appConstants';

type AnalyticsApiCall =
  | 'getSensorTimeDurationCurve'
  | 'getSensorAnalyticsQuantile'
  | 'getRadarAnalytics';

export const useGraphData = () => {
  const dispatch = useAppDispatch();
  const [currentGraphData, setCurrentGraphData] = useState<AnalysisGraphDataBySecSubstation>({});
  const [isLoadingGraph, setIsLoadingGraph] = useState({
    voltageDurationCurve: false,
    voltageDistributionBoxplot: false,
    imbalanceCurrentRadar: false,
    loadDistributionBoxplot: false,
    loadDurationCurve: false,
    topologyTree: false,
  });
  const { analysisGraphData } = useAppSelector((state) => state.sensorsData);
  const { networkAnalysisId } = useAppSelector((state) => state.session.dataContext);
  const { topology } = useTopology();

  const fetchGraphData = async (
    datasetId: string,
    sensor: { identifier: string; name: string; power: number },
    graphDataKey: SecSubGraphDataKey,
    apiCallKey: AnalyticsApiCall,
    signal?: AbortSignal,
    feederId?: string,
    feeders?: SecondarySubstationFeeder[]
  ) => {
    setIsLoadingGraph((prev) => ({ ...prev, [graphDataKey]: true }));
    let sensorsId;
    if (graphDataKey.includes('Boxplot')) {
      sensorsId = feeders
        ? [sensor.identifier, ...feeders.map((feeder) => feeder.identifier)]
        : [sensor.identifier];
    } else {
      sensorsId = [feederId ?? sensor.identifier];
    }

    const constraints: Constraint[] = [
      getConstraint('sensor', false, undefined, sensorsId, 'in'),
      getConstraint('metric', true, graphAnalytics[graphDataKey].regex),
    ];

    const params: ApiBaseParams = { ...getDefaultParams(true), constraints };
    const networkAnalysis = networkAnalysisId.find(
      (el: ProcessingSensor) => el.sensor === sensor.identifier
    )?.processing as string;
    const {
      data: { data: graphData },
    } = await apiCall[apiCallKey](datasetId, networkAnalysis, params, signal);
    let formattedData: Plotly.Data[] | null = [];
    switch (graphDataKey) {
      case 'loadDurationCurve':
        formattedData = formatDurationCurveData(
          graphData,
          graphDataKey,
          !!feederId,
          sensor.power / 1000
        );
        break;
      case 'voltageDurationCurve':
        formattedData = formatDurationCurveData(graphData, graphDataKey);
        break;
      case 'voltageDistributionBoxplot':
      case 'loadDistributionBoxplot':
        formattedData = formatBoxplotData(graphData, sensor, feeders, graphDataKey);
        break;
      case 'imbalanceCurrentRadar':
        formattedData = formatRadarData(graphData);
        break;
      default:
        formattedData = graphData;
        break;
    }
    updateGraphData(graphDataKey, sensor.identifier, formattedData);
    setIsLoadingGraph((prev) => ({ ...prev, [graphDataKey]: false }));
    return formattedData;
  };

  const getFeederDistributionBoxPlot = async (
    secSub: { identifier: string; power: number; name: string },
    feederName: string,
    analysisGraphData: AnalysisGraphDataBySecSubstation,
    graphDataKey: SecSubGraphDataKey,
    datasetId: string,
    feeders: SecondarySubstationFeeder[],
    signal: AbortSignal
  ) => {
    const secSubGraphData = cloneDeep(analysisGraphData[secSub.identifier]);
    let graphData;
    if (!secSubGraphData || !secSubGraphData[graphDataKey]) {
      graphData = await fetchGraphData(
        datasetId,
        secSub,
        graphDataKey,
        'getSensorAnalyticsQuantile',
        signal,
        '',
        feeders
      );
    } else {
      graphData = secSubGraphData[graphDataKey];
    }
    const distributionData: Plotly.Data[] | null = graphData?.length
      ? filterBoxplotDataByAbcissName(graphData, feederName, graphDataKey)
      : null;
    updateGraphData(graphDataKey, secSub.identifier, distributionData);
    setIsLoadingGraph((prev) => ({ ...prev, [graphDataKey]: false }));
    return distributionData;
  };

  const getFeederTopology = (
    secSubId: string,
    feederName: string,
    feeders: SecondarySubstationFeeder[] | null,
    smartMeters: SmartMeter[]
  ) => {
    const secSubGraphData = cloneDeep(analysisGraphData[secSubId]);
    let topologyData = secSubGraphData?.topologyTree;
    if (!topologyData && feeders) {
      topologyData = formatTopologyTreeData(topology[secSubId], secSubId, feeders, smartMeters);
    }
    topologyData =
      topologyData &&
      topologyData
        .filter(
          (data: Plotly.Data) =>
            data.type === 'scatter' &&
            (data.text === 'feeders' ||
              (data.text !== feederName && data.name === 'feeders') ||
              (data.text === feederName && data.name !== 'feeders'))
        )
        .map((data: Plotly.Data) => {
          if (data.type === 'scatter') data.visible = true;
          return data;
        });
    updateGraphData('topologyTree', secSubId, topologyData);
    setIsLoadingGraph((prev) => ({ ...prev, topologyTree: false }));
  };

  const updateGraphData = (
    graphName: SecSubGraphDataKeyWithTopology,
    secSubId: string,
    graphData: Plotly.Data[] | null
  ) => {
    setCurrentGraphData((prevState) => {
      const updatedGraphData: AnalysisGraphDataBySecSubstation = {};
      updatedGraphData[secSubId] = {
        ...cloneDeep(prevState)[secSubId],
      };

      updatedGraphData[secSubId][graphName] = graphData?.length ? graphData : null;
      return updatedGraphData;
    });
  };

  const getAnalysisDataByFeeder = async (
    secondarySub: { identifier: string; name: string; power: number },
    feeders: SecondarySubstationFeeder[],
    smartMeters: SmartMeter[] | null,
    feeder: SecondarySubstationFeeder,
    datasetId: string,
    signal: AbortSignal
  ) => {
    const feederAnalysisGraphData = cloneDeep(analysisGraphData[feeder.identifier]);
    setIsLoadingGraph({
      voltageDurationCurve: true,
      voltageDistributionBoxplot: true,
      imbalanceCurrentRadar: true,
      loadDistributionBoxplot: true,
      loadDurationCurve: true,
      topologyTree: true,
    });
    updateGraphData('voltageDurationCurve', secondarySub.identifier, null);
    updateGraphData('loadDurationCurve', secondarySub.identifier, null);
    updateGraphData('imbalanceCurrentRadar', secondarySub.identifier, null);
    updateGraphData('loadDistributionBoxplot', secondarySub.identifier, null);
    updateGraphData('voltageDistributionBoxplot', secondarySub.identifier, null);

    if (feeders && smartMeters) {
      getFeederTopology(secondarySub.identifier, feeder.name, feeders, smartMeters);
    }

    getFeederDistributionBoxPlot(
      secondarySub,
      feeder.name,
      analysisGraphData,
      'voltageDistributionBoxplot',
      datasetId,
      feeders,
      signal
    );

    getFeederDistributionBoxPlot(
      secondarySub,
      feeder.name,
      analysisGraphData,
      'loadDistributionBoxplot',
      datasetId,
      feeders,
      signal
    );

    if (feederAnalysisGraphData?.loadDurationCurve) {
      updateGraphData(
        'voltageDurationCurve',
        secondarySub.identifier,
        feederAnalysisGraphData['voltageDurationCurve']
      );
      updateGraphData(
        'imbalanceCurrentRadar',
        secondarySub.identifier,
        feederAnalysisGraphData['imbalanceCurrentRadar']
      );
      updateGraphData(
        'loadDurationCurve',
        secondarySub.identifier,
        feederAnalysisGraphData['loadDurationCurve']
      );
    } else {
      const [voltageDurationCurveResp, loadDurationCurveResp, imbalanceCurrentRadarResp] =
        await Promise.all([
          fetchGraphData(
            datasetId,
            secondarySub,
            'voltageDurationCurve',
            'getSensorTimeDurationCurve',
            signal,
            feeder.identifier
          ),
          fetchGraphData(
            datasetId,
            secondarySub,
            'loadDurationCurve',
            'getSensorTimeDurationCurve',
            signal,
            feeder.identifier
          ),
          fetchGraphData(
            datasetId,
            secondarySub,
            'imbalanceCurrentRadar',
            'getRadarAnalytics',
            signal,
            feeder.identifier
          ),
        ]);
      dispatch(
        setAnalysisGraphData({
          ...analysisGraphData,
          [feeder.identifier]: {
            voltageDurationCurve: voltageDurationCurveResp,
            loadDurationCurve: loadDurationCurveResp,
            imbalanceCurrentRadar: imbalanceCurrentRadarResp,
          },
        } as AnalysisGraphDataBySecSubstation)
      );
    }

    setIsLoadingGraph({
      voltageDurationCurve: false,
      voltageDistributionBoxplot: false,
      imbalanceCurrentRadar: false,
      loadDistributionBoxplot: false,
      loadDurationCurve: false,
      topologyTree: false,
    });
  };

  const replaceEmptyArrayByNull = <T>(data: Array<T> | null) => {
    return data && data?.length > 0 ? data : null;
  };

  /* When we first arrive on a secondry Substation we store the graph data in the store
  If the data is null we store it as an empty array
  When arrive a second time on the secondary substation we look in the store to get the data
  If the data was already loaded and empty we know it with the empty array
  The empty array is converted to null for the plot */
  const getAnalysisDataBySecondarySub = async (
    datasetId: string,
    secSub: { identifier: string; name: string; power: number },
    feeders: SecondarySubstationFeeder[],
    smartMeters: SmartMeter[],
    signal?: AbortSignal
  ) => {
    if (!networkAnalysisId) return;

    setIsLoadingGraph({
      voltageDurationCurve: true,
      voltageDistributionBoxplot: true,
      imbalanceCurrentRadar: true,
      loadDistributionBoxplot: true,
      loadDurationCurve: true,
      topologyTree: true,
    });
    const secSubAnalysisGraphData = cloneDeep(analysisGraphData[secSub.identifier]);
    if (secSubAnalysisGraphData?.loadDurationCurve) {
      setCurrentGraphData(() => ({
        [secSub.identifier]: {
          voltageDurationCurve: replaceEmptyArrayByNull(
            secSubAnalysisGraphData.voltageDurationCurve
          ),
          loadDistributionBoxplot: replaceEmptyArrayByNull(
            secSubAnalysisGraphData.loadDistributionBoxplot
          ),
          imbalanceCurrentRadar: replaceEmptyArrayByNull(
            secSubAnalysisGraphData.imbalanceCurrentRadar
          ),
          voltageDistributionBoxplot: replaceEmptyArrayByNull(
            secSubAnalysisGraphData.voltageDistributionBoxplot
          ),
          topologyTree: replaceEmptyArrayByNull(secSubAnalysisGraphData.topologyTree),
          loadDurationCurve: replaceEmptyArrayByNull(secSubAnalysisGraphData.loadDurationCurve),
        },
      }));
    } else {
      const topologyTree = formatTopologyTreeData(
        topology[secSub?.identifier],
        secSub.identifier,
        feeders,
        smartMeters
      );
      updateGraphData('topologyTree', secSub.identifier, topologyTree ?? null);
      setIsLoadingGraph((prev) => ({ ...prev, topologyTree: false }));
      const [
        voltageDurationCurveResp,
        loadDurationCurveResp,
        imbalanceCurrentRadarResp,
        voltageDistributionBoxplotResp,
        loadDistributionBoxplotResp,
      ] = await Promise.allSettled([
        fetchGraphData(
          datasetId,
          secSub,
          'voltageDurationCurve',
          'getSensorTimeDurationCurve',
          signal
        ),
        fetchGraphData(
          datasetId,
          secSub,
          'loadDurationCurve',
          'getSensorTimeDurationCurve',
          signal
        ),
        fetchGraphData(datasetId, secSub, 'imbalanceCurrentRadar', 'getRadarAnalytics', signal),
        fetchGraphData(
          datasetId,
          secSub,
          'voltageDistributionBoxplot',
          'getSensorAnalyticsQuantile',
          signal,
          '',
          feeders
        ),
        fetchGraphData(
          datasetId,
          secSub,
          'loadDistributionBoxplot',
          'getSensorAnalyticsQuantile',
          signal,
          '',
          feeders
        ),
      ]);
      dispatch(
        setAnalysisGraphData({
          ...analysisGraphData,
          [secSub.identifier]: {
            voltageDurationCurve: getDataFromPromiseResponse(voltageDurationCurveResp),
            loadDurationCurve: getDataFromPromiseResponse(loadDurationCurveResp),
            voltageDistributionBoxplot: getDataFromPromiseResponse(voltageDistributionBoxplotResp),
            loadDistributionBoxplot: getDataFromPromiseResponse(loadDistributionBoxplotResp),
            imbalanceCurrentRadar: getDataFromPromiseResponse(imbalanceCurrentRadarResp),
            topologyTree,
          },
        })
      );
    }

    setIsLoadingGraph({
      voltageDurationCurve: false,
      voltageDistributionBoxplot: false,
      imbalanceCurrentRadar: false,
      loadDistributionBoxplot: false,
      loadDurationCurve: false,
      topologyTree: false,
    });
  };

  return {
    getAnalysisDataBySecondarySub,
    currentGraphData,
    getAnalysisDataByFeeder,
    isLoadingGraph,
  };
};
