import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/atoms';
import store from 'store';

type Props = {
  linkClasses?: string;
  textClasses?: ((language: string) => string) | string;
  iconVisible?: boolean;
  iconClasses?: string;
  languageCode?: boolean;
  inclusive?: boolean;
};

const Languages = ({
  linkClasses,
  textClasses,
  iconVisible = false,
  iconClasses,
  languageCode = false,
  inclusive = true,
}: Props) => {
  const { t, i18n } = useTranslation();

  const lngArray: ReadonlyArray<string> = i18n.options.resources
    ? Object.keys(i18n.options.resources)
    : [];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    store.dispatch({ type: 'SET_LANGUAGE', lngCode: lng });
  };

  return (
    <>
      {lngArray.map((lng) => {
        if (inclusive || lng !== i18n.resolvedLanguage) {
          return (
            <a
              className={linkClasses}
              key={`link-to-language-${lng}`}
              onClick={() => changeLanguage(lng)}
            >
              <div className={textClasses instanceof Function ? textClasses(lng) : textClasses}>
                {languageCode ? lng : t('language', { lng })}
              </div>
              {iconVisible ? (
                <div className={iconClasses}>
                  <Icon name={`flag-${lng}`} />
                </div>
              ) : (
                <></>
              )}
            </a>
          );
        }
      })}
    </>
  );
};

export default Languages;
