import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { Header } from 'components/organisms';
import {
  DesignSystem,
  Mapping,
  Analysis,
  Impact,
  Rebalancing,
  Performances,
} from 'components/pages';
import { PrivateRoute } from 'components/molecules';

import routes from 'router/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { apiCall } from 'services';
import { login } from 'store/actions';

const ProtectedRoutes = () => {
  const { useCasePermissions } = useAppSelector((state) => state.session.dataContext);

  return (
    <>
      <Header />
      <Routes>
        <Route path={routes.home.path} element={<Navigate to={routes.mapping.path} replace />} />
        <Route
          path={routes.mapping.path}
          element={
            <PrivateRoute>
              <Mapping />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.analysis.path}
          element={
            <PrivateRoute permission={useCasePermissions?.includes('analysis')}>
              <Analysis />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.impact.path}
          element={
            <PrivateRoute permission={useCasePermissions?.includes('impact')}>
              <Impact />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.rebalancing.path}
          element={
            <PrivateRoute permission={useCasePermissions?.includes('rebalancing')}>
              <Rebalancing />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.performances.path}
          element={
            <PrivateRoute>
              <Performances />
            </PrivateRoute>
          }
        />
        <Route
          path={routes.designSystem.path}
          element={
            <PrivateRoute>
              <DesignSystem />
            </PrivateRoute>
          }
        />
        {/* <Route path={routes.accountSettings.path} element={<AccountSettings />} /> */}
        {/* <Route path='*' element={<NotFound />} /> */}
      </Routes>
    </>
  );
};

const App = () => {
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const [prevToken, setPrevToken] = useState('');

  const convertProcessGroup = (
    processGroup: ProcessingGroup[],
    processName: string
  ): ProcessingSensor[] => {
    return processGroup
      .filter((el: ProcessingGroup) => {
        return el.parent === processName;
      })
      .map((el: ProcessingGroup) => ({ processing: el.processing, sensor: el.sensor }));
  };

  useEffect(() => {
    if (keycloak.token && '' === prevToken) {
      setPrevToken(keycloak.token);
      const loadData = async () => {
        const { data: prefs } = await apiCall.getPreferences();
        if (prefs) {
          const { integrator, dso, dataset } = prefs;
          const {
            data: { permissions: perms },
          } = await apiCall.getPermissons();
          const {
            data: { data: datasetObj },
          } = await apiCall.getDatasets(integrator, dso, dataset);
          const {
            data: { data: processGroup },
          } = await apiCall.getProcessingGroup(integrator, dso, dataset);
          const dataContext: DataContext = {
            datasetId: dataset,
            networkAnalysisId: convertProcessGroup(processGroup, datasetObj.networkAnalysis),
            integratorId: integrator,
            dsoId: dso,
            rebalancingIds: convertProcessGroup(processGroup, datasetObj.rebalancing),
            deviceDetectionModelIds: convertProcessGroup(processGroup, datasetObj.pvDetection),
            pvLoadCapacityModelIds: convertProcessGroup(processGroup, datasetObj.pvCapacity),
            pvPlannedLoadIds: convertProcessGroup(processGroup, datasetObj.plannedLoad),
            useCasePermissions: perms.map((perm: Permission) => perm.useCase as UseCasePermissions),
            processingTopoId: processGroup
              .filter((el: ProcessingGroup) => {
                return el.parent === datasetObj.topology2;
              })
              .map((el: ProcessingGroup) => el.processing),
          };
          dispatch(login({ loggedIn: true, dataContext }));
        }
      };
      loadData();
    }
  }, [dispatch, keycloak.token, prevToken]);

  return (
    <BrowserRouter>
      <main className="app-container">
        <>
          <Routes>
            <Route path="*" element={<ProtectedRoutes />} />
          </Routes>
        </>
      </main>
    </BrowserRouter>
  );
};

export default App;
