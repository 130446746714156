import React, { useEffect, useRef } from 'react';

import { CheckboxStates } from 'appConstants';
import { findNextValueCheckbox } from 'helpers';

type Props = {
  label: string;
  value: string;
  onChange?: (v: CheckboxStates) => void;
};

const Checkbox = ({ label, value, onChange }: Props) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      if (value === CheckboxStates.Checked) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (value === CheckboxStates.Unchecked) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (value === CheckboxStates.Indeterminate) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }
  }, [value]);

  const handleChange = () => {
    const currentValueCheckbox = value;
    const valueFilterCheckbox =
      currentValueCheckbox !== undefined
        ? findNextValueCheckbox(currentValueCheckbox as CheckboxStates)
        : undefined;
    if (valueFilterCheckbox && onChange) {
      onChange(valueFilterCheckbox);
    }
  };

  return (
    <label className="checkbox">
      <input ref={checkboxRef} type="checkbox" onChange={handleChange} />
      {label}
    </label>
  );
};

export default Checkbox;
