import { createControlComponent } from '@react-leaflet/core';
import { Control, DomUtil, DomEvent, Map } from 'leaflet';
import fscreen from 'fscreen';
import i18n from 'i18n/i18n';

const FullscreenButtonControl = Control.extend({
  button: null as unknown as HTMLAnchorElement,
  toggleFullscreen(e: Event, map: Map) {
    DomEvent.preventDefault(e);
    fscreen.fullscreenElement === null
      ? map.getContainer().requestFullscreen()
      : fscreen.exitFullscreen();
  },
  onAdd(map: Map) {
    this.button = DomUtil.create('a', 'leaflet-control-fullscreen', map.getContainer());
    this.button.href = '#';
    this.button.title = i18n.t('map.enter_fullscreen_title');
    DomEvent.disableClickPropagation(this.button);
    DomEvent.on(this.button, 'click', (e: Event) => this.toggleFullscreen(e, map));
    map.getContainer().addEventListener('fullscreenchange', () => this.toggleButtonTooltip());
    return this.button;
  },
  onRemove(map: Map) {
    /* Remove event listeners and button element on component removal */
    DomEvent.off(this.button, 'click', (e: Event) => this.toggleFullscreen(e, map));
    DomUtil.remove(this.button);
    map.getContainer().removeEventListener('fullscreenchange', () => this.toggleButtonTooltip());
  },
  toggleButtonTooltip() {
    this.button.setAttribute(
      'title',
      i18n.t(`map.${fscreen.fullscreenElement === null ? 'enter' : 'exit'}_fullscreen_title`)
    );
  },
});

export const FullscreenControl = createControlComponent(
  (props) => new FullscreenButtonControl(props)
);
