export const SET_SEC_SUBSTATIONS = 'SET_SEC_SUBSTATIONS';
export const SET_SMARTMETERS = 'SET_SMARTMETERS';
export const SET_FEEDERS = 'SET_FEEDERS';
export const SET_TOPOLOGY = 'SET_TOPOLOGY';
export const SET_ANALYSIS_GRAPH_DATA = 'SET_ANALYSIS_GRAPH_DATA';

export const setSecondarySubstations = (payload: SecondarySubstation[]) => {
  return { type: SET_SEC_SUBSTATIONS, payload };
};

export const setSmartMeters = (payload: SmartMetersBySecSubstation) => {
  return { type: SET_SMARTMETERS, payload };
};

export const setFeeders = (payload: FeedersBySecSubstation) => {
  return { type: SET_FEEDERS, payload };
};

export const setAnalysisGraphData = (payload: AnalysisGraphDataBySecSubstation) => {
  return { type: SET_ANALYSIS_GRAPH_DATA, payload };
};

export const setTopology = (payload: TopologyBySecSubstation) => {
  return { type: SET_TOPOLOGY, payload };
};
