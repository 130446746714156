import React from 'react';
import './analytics-definition.scss';
import { asTemplateString } from 'helpers';
import { useTranslation } from 'react-i18next';
import { networkAnalyticsOptions } from 'appConstants';
import { snakeCase } from 'lodash';

type Props = {
  analyticsKey: string;
};
const AnalyticsDefinition = ({ analyticsKey }: Props) => {
  const { t } = useTranslation();

  const analytics = networkAnalyticsOptions.find((option) => option.key === analyticsKey);

  return (
    <>
      {analytics && (
        <>
          <div className="head-def">
            {t(asTemplateString(analytics.translationKey))}

            <span
              className="short-name-def"
              dangerouslySetInnerHTML={{
                __html:
                  '   (' +
                  t(asTemplateString('table_headers.equipment.' + snakeCase(analytics.key))) +
                  ')',
              }}
            />
          </div>
          <div className="body-def">{t(asTemplateString('info_' + analytics.translationKey))}</div>
        </>
      )}
    </>
  );
};

export default AnalyticsDefinition;
