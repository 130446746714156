import React, { ReactNode } from 'react';
import { Icon } from 'components/atoms';
import './side-bar.scss';

type Props = {
  titleSlot: ReactNode;
  bodySlot: ReactNode;
  footerSlot?: ReactNode;
  closeSidebar: () => void;
};

const SideBar = ({ titleSlot, bodySlot, footerSlot, closeSidebar }: Props) => {
  return (
    <div className="side-bar">
      <div className="side-bar-header has-text-primary p-1 pl-5">
        {titleSlot}
        <button
          type="button"
          className="close-button button is-text"
          onClick={closeSidebar}
          aria-label="close"
        >
          <Icon name="close" />
        </button>
      </div>

      <div className="side-bar-content is-scrollable p-5">{bodySlot}</div>

      {footerSlot && <div className="side-bar-footer p-5">{footerSlot}</div>}
    </div>
  );
};

export default SideBar;
