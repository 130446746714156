export const routes = {
  home: {
    path: '/',
  },
  designSystem: {
    path: '/design-system',
  },
  mapping: {
    path: '/mapping',
  },
  analysis: {
    path: '/analysis',
  },
  impact: {
    path: '/impact',
  },
  rebalancing: {
    path: '/rebalancing',
  },
  performances: {
    path: '/performances',
  },
};

export default routes;
