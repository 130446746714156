import * as actionTypes from 'store/actions/sensorsData';

export const sensorsDataInitialState: SensorsData = {
  secondarySubstations: null,
  smartMeters: {},
  feeders: {},
  topology: {},
  analysisGraphData: {},
};

export const sensorsDataReducer = (
  state: SensorsData = sensorsDataInitialState,
  action: SensorsDataAction
): SensorsData => {
  switch (action.type) {
    case actionTypes.SET_SEC_SUBSTATIONS:
      return {
        ...state,
        secondarySubstations: action.payload as SecondarySubstation[],
      };
    case actionTypes.SET_SMARTMETERS:
      return {
        ...state,
        smartMeters: action.payload as SmartMetersBySecSubstation,
      };
    case actionTypes.SET_FEEDERS:
      return {
        ...state,
        feeders: action.payload as FeedersBySecSubstation,
      };

    case actionTypes.SET_TOPOLOGY:
      return {
        ...state,
        topology: action.payload as TopologyBySecSubstation,
      };
    case actionTypes.SET_ANALYSIS_GRAPH_DATA:
      return {
        ...state,
        analysisGraphData: action.payload as AnalysisGraphDataBySecSubstation,
      };
    default:
      return state;
  }
};
