import { Icon } from 'components/atoms';
import React, { useState } from 'react';

import './accordion.scss';

type Props = {
  isPrevOpen?: boolean;
  titleIcon?: string;
  classes?: string;
  title: string;
  children?: React.ReactNode;
};

const Accordion = ({ isPrevOpen = false, titleIcon, classes, title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(isPrevOpen);
  return (
    <div className={`accordion ${classes}`}>
      <div className="accordion-title" onClick={() => setIsOpen((prev) => !prev)}>
        <div className="title-container">
          {titleIcon && <Icon name={titleIcon} classes="title-icon" />}
          <h3>{title}</h3>
        </div>
        <Icon name={isOpen ? 'angle-up' : 'angle-down'} classes="icon-chevron" />
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export default Accordion;
