import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorLegend, InputField, InputFieldGroup, Modal } from 'components/molecules';
import { Icon, SelectDropdown } from 'components/atoms';

import { ImpactModalType, listPhases } from 'appConstants';

import './impact-modal.scss';
import { CapacityCell } from '../ImpactTable/cells';
import { asTemplateString } from 'helpers';

type Props = {
  additionModalState: {
    open: boolean;
    rowIdentifier: string;
    rowName: string;
  };
  cursorMax: number;
  loadCapacities: LoadCapacities;
  normalizedCapacities: NormalizedCapacities;
  closeModal: (initialAddValue: ImpactAddedCapacities) => void;
  saveModal: (smartMeterId: string) => void;
  addValue?: ImpactAddedCapacities | undefined;
  setAddValue: (smAddValue: ImpactAddedCapacities) => void;
  deletePlannedLoad: (smartMeterId: string) => void;
  hasSimulations: boolean;
};

const ImpactModal = ({
  additionModalState,
  loadCapacities,
  normalizedCapacities,
  closeModal,
  saveModal,
  addValue,
  setAddValue,
  deletePlannedLoad,
  hasSimulations,
}: Props) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const [initialAddValue] = useState<{ newValue: number; phase: Phase }>(
    addValue ?? {
      newValue: loadCapacities.added,
      phase: loadCapacities.plannedPhase ?? loadCapacities.phase,
    }
  );
  const [tempAddValue, setTempAddValue] = useState<{ newValue: number; phase: Phase }>(
    addValue ?? {
      newValue: loadCapacities.added,
      phase: loadCapacities.plannedPhase ?? loadCapacities.phase,
    }
  );
  const [modalType, setModalType] = useState<ImpactModalType>(
    loadCapacities.planned ? ImpactModalType.Planned : ImpactModalType.Add
  );

  const getElementFromCapacitiesMap = useCallback(
    (key: keyof LoadCapacities) => {
      return loadCapacities[key] as number;
    },
    [loadCapacities]
  );

  const onImpactAddChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTempAddValue((temp) => ({ newValue: Number(event.target.value), phase: temp.phase }));
  };

  const getResidualCapacity = useCallback(
    (phase: Phase, addedValue?: number) => {
      return (
        getElementFromCapacitiesMap(`resPhase${phase}`) -
        (addedValue ?? getElementFromCapacitiesMap('added')) +
        getElementFromCapacitiesMap('added')
      );
    },
    [getElementFromCapacitiesMap]
  );

  const onDeletePlannedLoad = (smartmeterId: string, impactType: ImpactModalType) => {
    setModalType(hasSimulations ? ImpactModalType.WarningPlanned : ImpactModalType.Planned);
    ((!hasSimulations && impactType === ImpactModalType.Planned) ||
      impactType === ImpactModalType.WarningPlanned) &&
      deletePlannedLoad(smartmeterId);
  };

  useEffect(() => {
    setAddValue(tempAddValue);
  }, [tempAddValue]);
  const renderButtons = (modalType: ImpactModalType) => {
    switch (modalType) {
      case ImpactModalType.Add:
        return (
          <button
            className="button is-link is-rounded mr-2"
            disabled={
              (addValue && addValue.newValue > getResidualCapacity(addValue.phase)) ||
              addValue === initialAddValue ||
              (initialAddValue.newValue === 0 && tempAddValue.newValue === 0)
            }
            onClick={() => saveModal(additionModalState.rowIdentifier)}
          >
            {t('impact.addition_modal.button.apply')}
          </button>
        );
      case ImpactModalType.WarningPlanned:
        return (
          <>
            <button
              className="button is-link is-rounded mr-2"
              onClick={() => onDeletePlannedLoad(additionModalState.rowIdentifier, modalType)}
            >
              {t('impact.addition_modal.button.delete_planned')}
            </button>
          </>
        );
      case ImpactModalType.Planned:
        return (
          <>
            <button
              className="button is-link is-rounded mr-2"
              onClick={() => onDeletePlannedLoad(additionModalState.rowIdentifier, modalType)}
            >
              {t('impact.addition_modal.button.delete_planned')}
            </button>
            <button
              className="button is-link is-rounded mr-2"
              onClick={() => setModalType(ImpactModalType.Add)}
            >
              {t('impact.addition_modal.button.add_pvs')}
            </button>
          </>
        );
    }
  };
  return (
    <>
      <Modal
        isModalOpen={additionModalState.open}
        setIsModalOpen={() => closeModal(initialAddValue)}
        showCloseButton={true}
        modalSize={{ maxWidth: '50%', maxHeight: '90%' }}
        title={t('impact.addition_modal.title', {
          smartmeterName: additionModalState.rowName,
        })}
        footer={
          <div className="buttons">
            {renderButtons(modalType)}
            <button className="button is-rounded" onClick={() => closeModal(initialAddValue)}>
              {t('impact.addition_modal.button.cancel')}
            </button>
          </div>
        }
      >
        {modalType === ImpactModalType.Add && additionModalState.open && loadCapacities && (
          <>
            <InputField
              classes="is-horizontal"
              value={
                loadCapacities.phase === '4'
                  ? t('impact.table.three_phased')
                  : t('impact.table.phase') + loadCapacities.phase
              }
              label={`${t('impact.addition_modal.conso_phase')}:`}
            ></InputField>
            <InputFieldGroup
              inputs={[
                {
                  value: getElementFromCapacitiesMap('installed'),
                  label: `${t('impact.addition_modal.pvs.installed')}:`,
                },
                {
                  value: getElementFromCapacitiesMap('planned'),
                  label: `${t('impact.addition_modal.pvs.planned')}:`,
                },
              ]}
            />
            <div className="field modal-phase-choice">
              <label className="label">
                {t('impact.addition_modal.residual_capacity_by_phase')}:
              </label>
              <InputFieldGroup
                inputs={listPhases.map((phase) => ({
                  value: getResidualCapacity(phase),
                  label: `${t(`impact.addition_modal.pvs.residual_phase_${phase}`)}:`,
                  classes: `${
                    (addValue?.phase ?? loadCapacities.plannedPhase ?? loadCapacities.phase) ===
                      phase && 'selected-phase'
                  }`,
                }))}
              />
              <SelectDropdown
                disabled={!!loadCapacities.planned}
                label={`${t('impact.addition_modal.phase_choice')}:`}
                name={t('impact.addition_modal.phase_choice')}
                initialValue={tempAddValue.phase}
                selectValue={(value) => {
                  setTempAddValue((temp) => ({
                    newValue: temp.newValue,
                    phase: value as unknown as Phase,
                  }));
                }}
                dropdownOptions={listPhases.map((phase) => {
                  return {
                    key: phase,
                    translationKey: `impact.addition_modal.pvs.residual_phase_${phase}`,
                    optionalTranslationKeys:
                      phase === loadCapacities.phase.toString()
                        ? [' -  ', 'impact.addition_modal.pvs.initial_phase']
                        : undefined,
                  };
                })}
              >
                {addValue && addValue.phase !== loadCapacities.phase ? (
                  <div className={'with-icon warning'}>
                    <Icon
                      name="warning"
                      classes="is-flex-shrink-0 icon-warning"
                      viewBox="0 0 2 2"
                    />
                    <div className="text-warning">{t('impact.addition_modal.warning_phase')}</div>
                  </div>
                ) : undefined}
              </SelectDropdown>
            </div>
            <div className="field ">
              <div className="field-label is-flex">
                <label className="label mr-4">{t('impact.addition_modal.pvs.capacities')}: </label>
                <div
                  className="info"
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                >
                  <Icon name="info" classes="is-flex-shrink-0" />
                </div>
                {showInfo && (
                  <div
                    className="info-container notification"
                    style={{ width: 'fit-content', left: 'auto', right: 'auto' }}
                  >
                    <ColorLegend
                      prefixTranslationKey="impact.color_legend.pvs."
                      colorVariables={[
                        'installed',
                        'planned',
                        'added',
                        'residual',
                        'stripped_residualMax',
                      ]}
                    />
                  </div>
                )}
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <CapacityCell
                      classes="is-full-dim is-height-2 no-padding-left"
                      capacities={normalizedCapacities}
                      stripped={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <InputField
              onInputChange={(e: ChangeEvent<HTMLInputElement>) => onImpactAddChange(e)}
              value={
                addValue === undefined ? getElementFromCapacitiesMap('added') : addValue.newValue
              }
              label={`${t('impact.addition_modal.pvs.added')}:`}
              min={0}
              max={getResidualCapacity(addValue?.phase ?? loadCapacities.phase)}
              unit={t('units.kwp')}
            />
          </>
        )}
        {modalType !== ImpactModalType.Add && (
          <div>
            {t(
              asTemplateString(
                `impact.addition_modal.${
                  modalType === 'planned' ? 'already_planned_warning' : 'has_simulations_warning'
                }`
              )
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ImpactModal;
