import {
  nbSmVOutOfRangeOption,
  maxSecSubLoadRateOption,
  nbSmVImbalancedOption,
  totalTimeFeedersOvercurrentOption,
} from 'appConstants';

export const criticalCriterias = [
  { ...nbSmVOutOfRangeOption, value: '1' },
  { ...nbSmVImbalancedOption, value: '1' },
  { ...maxSecSubLoadRateOption, value: '80%' },
  { ...totalTimeFeedersOvercurrentOption, value: '2:00' },
];
