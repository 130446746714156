import React, { useEffect } from 'react';

import { Graph } from 'components/molecules';
import { useGraphData, useSensors, useAppSelector, useTopology } from 'hooks';
import { analyticGraphs } from 'appConstants';

type Props = {
  activeSecondarySubstation: SecondarySubstation;
  activeFeeder: SecondarySubstationFeeder | null | undefined;
  currentFeeders: SecondarySubstationFeeder[] | null;
  currentSmartmeters: SmartMeter[];
};

const DataVisualizer = ({
  activeSecondarySubstation,
  activeFeeder,
  currentFeeders,
  currentSmartmeters,
}: Props) => {
  const {
    currentGraphData,
    isLoadingGraph,
    getAnalysisDataBySecondarySub,
    getAnalysisDataByFeeder,
  } = useGraphData();
  const { isLoading } = useSensors();
  const { topology } = useTopology();
  const { datasetId } = useAppSelector((state) => state.session.dataContext);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (
      activeSecondarySubstation &&
      activeFeeder &&
      currentFeeders?.length &&
      currentSmartmeters.length
    ) {
      getAnalysisDataByFeeder(
        {
          identifier: activeSecondarySubstation.identifier,
          name: activeSecondarySubstation.name,
          power: activeSecondarySubstation.sNominal?.magnitude,
        },
        currentFeeders,
        currentSmartmeters,
        activeFeeder,
        datasetId,
        signal
      );
    } else if (
      activeSecondarySubstation &&
      currentSmartmeters.length &&
      currentFeeders?.length &&
      topology[activeSecondarySubstation.identifier].findIndex(
        (row) => currentSmartmeters[0].identifier === row.sensor
      ) !== -1
    ) {
      getAnalysisDataBySecondarySub(
        datasetId,
        {
          identifier: activeSecondarySubstation.identifier,
          name: activeSecondarySubstation.name,
          power: activeSecondarySubstation.sNominal?.magnitude,
        },
        currentFeeders ?? [],
        currentSmartmeters,
        signal
      );
    }
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSmartmeters, currentFeeders, activeFeeder, datasetId]);

  return (
    <div
      className="columns graphs-container is-multiline p-3
    is-scrollable has-background-white m-0"
    >
      {/* Graph area */}
      {analyticGraphs.map((el) => (
        <div className="column is-6" key={el.field}>
          <Graph
            loading={{
              isGraphLoading: isLoadingGraph[el.field],
              showLoading: !isLoading.feeders && !isLoading.smartMeters,
            }}
            translationKey={`graphs.${el.translationKey}`}
            data={
              currentGraphData[activeSecondarySubstation.identifier] !== undefined
                ? currentGraphData[activeSecondarySubstation.identifier][el.field]
                : []
            }
          />
        </div>
      ))}
    </div>
  );
};
export default DataVisualizer;
