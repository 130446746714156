import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/atoms';
import { TableHeader } from 'components/molecules';
import { RotationDirection } from 'appConstants';

type Props = {
  permutationByFeeder: PermutationsByFeeder | undefined;
  setHoveredSmartmeterId: (smId: string) => void;
  rotationDirection: RotationDirection;
  hoveredSmartmeterId?: string;
};

const PermutationTable = ({
  permutationByFeeder,
  hoveredSmartmeterId,
  rotationDirection,
  setHoveredSmartmeterId,
}: Props) => {
  const { t } = useTranslation();
  const listHeaders: KeyPermutationTable[] = ['smartmeter', 'permutation', 'phase', 'finalPhase'];

  const renderCellPermut = (row: Permutation, header: KeyPermutationTable, i: number) => {
    let render = row[header];
    /* If the rotation direction is indirect (3/2/1) the permutation is different than the 
    "computed" permutation from the algorithm that uses the direct direction (1/2/3) */
    if (header === 'permutation' && rotationDirection === RotationDirection.indirect) {
      render = row[header] === 1 ? 2 : 1;
    }

    return (
      <td
        title={render?.toString()}
        key={`permutation-${i}`}
        className={`list-cell cell-25 column is-text-aligned ${header}`}
      >
        <div>
          {header === 'permutation' ? '+' : ''}
          {render}
        </div>
      </td>
    );
  };

  return (
    <table className="rebalancing-plan-table table-container">
      <thead className="table-header">
        <tr className="list-headers columns">
          {listHeaders.map((value, index) => {
            return (
              <TableHeader
                useCase={'rebalancing'}
                key={`${value}-${index}`}
                name={value}
                classesContainer={`column cell-25  p-0`}
                classeTh="justify-content one-line-text"
                positionInfo={{ width: '30rem', left: '4rem', right: 'auto' }}
                info={
                  value === 'permutation' ? (
                    <div className="message is-info">
                      <div className="message-body">
                        <Icon name="info" classes="mr-2" />
                        {t('rebalancing.planification.permutation_info')}
                      </div>
                    </div>
                  ) : undefined
                }
              ></TableHeader>
            );
          })}
        </tr>
      </thead>
      <tbody className="list-rows">
        {permutationByFeeder &&
          Object.entries(permutationByFeeder).map(([key, value]) => {
            return (
              <React.Fragment key={key}>
                <tr className="feeder-row">
                  <td>{key}</td>
                </tr>
                {value.map((smartmeterRow, index) => {
                  return (
                    <tr
                      key={`smartmeter-${smartmeterRow.smarmeterId}-${index}`}
                      className={`list-row columns  ${
                        hoveredSmartmeterId == smartmeterRow.smarmeterId && 'smartmeter-hovered'
                      } `}
                      onMouseLeave={() => setHoveredSmartmeterId('')}
                      onMouseEnter={() => setHoveredSmartmeterId(smartmeterRow.smarmeterId)}
                    >
                      {listHeaders.map((header, i) => renderCellPermut(smartmeterRow, header, i))}
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
      </tbody>
    </table>
  );
};

export default PermutationTable;
