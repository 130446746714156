import { ImpactCapacity } from './enums';

export const initalImpactModalState: ModalState = { open: false, rowIdentifier: '', rowName: '' };

export const initalImpactButtonState: ImpactButtons = {
  waitlist: false,
  reset: false,
};

export const emptyNormalizedCapacities: NormalizedCapacities = {
  added: 0,
  installed: 0,
  planned: 0,
  overflow: 0,
  residual: 0,
  residualMax: 0,
  maxBeforeSimulation: 0,
};

export const listImpactCapacityType = [
  ImpactCapacity.Installed,
  ImpactCapacity.Planned,
  ImpactCapacity.Added,
  ImpactCapacity.ResMax,
  ImpactCapacity.ResPhase,
];

export const impactTableHeaders: TableRowKey[] = [
  'smartmeter',
  'feeder',
  'phase1',
  'installedLoad',
  'plannedLoad',
  'addedLoad',
  'residualCapacity',
  'delta',
  'capacitiesBar',
];

export const listPhases: Phase[] = ['1', '2', '3', '4'];
